import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import hoverEffect from 'hover-effect';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

const StyledResidential = styled.div`
  /* padding: 10vw 6vw; */
  /* padding: clamp(5px, 10vw, 10rem) 0; */
  --imageShadowOpacity: 0;
  position: relative;
  height: 100vh;
  overflow: hidden;
  .service-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    /* max-height: 800px; */
    /* padding: 2rem 0; */
    /* &:nth-last-of-type(1) {
      padding-top: 5rem;
    } */

    /* animation for subheading slide in effect */
    .animation-box {
      overflow: hidden;
      span {
        position: relative;
        /* display block is necessary for skew transforms */
        display: inline-block;
        left: 0.8rem;
      }
    }
    .col-1 {
      width: 40%;
      margin-left: auto;
    }

    .col-1-heading {
      /* margin: 3rem 0 1.8rem; */
      /* gets bigger as vh gets smaller  */
      margin-block: clamp(3rem, 745px - 100vw, 6.5rem) 1.2rem;
      /* margin-block: clamp(3rem, 40%, 7.5rem) 1.2rem; */
      position: relative;
      h3 {
        line-height: 103%;
      }
      #complexities {
        /* font-size: 26px; */
        /* 23-26 */
        font-size: clamp(1.44rem, calc(1.05rem + 0.81vw), 1.63rem);
      }
      .vertical-line {
        position: absolute;
        height: 100%;
        width: 3.5px;
        background-color: var(--secondary);
        left: 0;
        top: 0;
        z-index: 4;
      }
    }

    .resi-col-1-description {
      p {
        margin: 0;
      }
    }

    .col-3 {
      /* cannot align-self since align is cross-axis
      align-self: 'flex-end'; */
      /* flex-basis: 45%; */
      width: 48%;
      padding-left: min(4rem, 4vw);
      margin-right: auto;
      opacity: 0;
      display: inline-block;
      .col-3-title {
        /* font-size: 24px; */
        /* 20px-24 */
        font-size: clamp(1.25rem, calc(0.73rem + 1.08vw), 1.5rem);
        font-weight: 800;
        line-height: 1.5;
      }
      .col-3-content {
        display: flex;
        gap: min(3vmin, 2rem);
        /* margin-top: min(3rem, 3vw); */
        margin-top: min(3rem, 5vmin);
      }
      .col-3-subheading {
        line-height: 1.5;
        font-family: Calluna;
        letter-spacing: -1px;
        /* 23-26px */
        font-size: clamp(1.44rem, calc(1.05rem + 0.81vw), 1.63rem);
        flex-basis: 80%;
      }
      .col-3-description {
        flex-basis: 90%;
        p {
          margin: 0;
        }
      }
    }
  }

  .image-wrapper {
    /* position is absolute to where the wrapper content begins */
    position: absolute;
    left: 50%;
    will-change: transform;
    display: inline-block;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
      opacity: var(--imageShadowOpacity);
    }
    /* moved image up and shrunk/decreased content from bottom 
       this will overflow top and that is why there is extra space above the image*/
    /* top: 0; */
    /* width: 70%; */
    .mask {
      position: absolute;
      height: 100%;
      width: 100%;
      background: var(--white);
      top: 0;
      right: 0;
      z-index: 5;
      transform-origin: right;
    }
    .residential-heading {
      position: absolute;
      z-index: 4;
      /* biggest font position, ideal, lowest font position */
      left: clamp(-265px, -24vw, -190px);
      top: 0;
      /* mix-blend-mode: luminosity; */
      mix-blend-mode: hard-light;
      /* width: fit-content; */
      /* ***designate a starting position so gsap knows how to animate when we scroll back up**** */
      /* transform: translate(0px, 0px); */
      /* will-change: transform; */
      h1 {
        /* 45-72 */
        font-size: clamp(2.81rem, calc(1.83rem + 3.75vw), 4.5rem);
        color: var(--black);
        margin: 0;
        font-weight: 600;
      }
    }
    .residential-subheading {
      position: absolute;
      z-index: 2;
      left: -3px;
      opacity: 0;
      /* top: -39px; */
      /* top: calc(1% - ((0.75rem + 2.74vw))); */
      top: 0;
      /* transform: translate(0, -80%); */
      /* will-change: transform; */
      /* mix-blend-mode: color-burn; */
      h3 {
        /* font-size: 2.7rem; */
        /* 420 min viewport */
        /* 30-43 */
        font-size: clamp(1.88rem, calc(1.4rem + 1.81vw), 2.69rem);
        letter-spacing: 0.3px;
        line-height: 73%;
      }
    }
    .residential-location {
      position: absolute;
      right: -2.5px;
      top: calc(100% - 100px);
      transform: translate3d(54%, 0, 0) rotate(90deg);
      color: var(--darkBlack);
      font-family: Calluna, sans-serif;
      text-transform: capitalize;
      letter-spacing: 1px;
      font-size: 1rem;
      text-rendering: optimizeLegibility;
      span {
        color: var(--buttonBlue);
      }
    }
    .image-container {
      width: 100%;
      overflow: hidden;
      position: relative;
      z-index: 3;
      .residential-image {
        width: clamp(540px, 65vw, 720px);
        height: clamp(360px, 65vh, 480px);
        /* max-width: 720px;
          max-height: 480px; */
        filter: brightness(0.95);
        canvas {
          max-height: 480px !important;
        }
      }
    }
  }

  @media screen and (max-width: 837px), screen and (max-height: 628px) {
    .col-3 {
      .col-3-content {
        display: block !important;
      }
      .col-3-subheading {
        float: left;
        display: inline-block;
        width: 43%;
        margin-right: 10px;
      }
    }
  }

  /* handle image on mobile */
  @media screen and (max-width: 599px) {
    .col-1,
    .col-3 {
      width: 80% !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .col-1 {
      padding-top: min(10%, 4rem);
      .col-1-heading {
        margin-block: auto;
        margin-bottom: 1.2rem;
      }
    }
    .image-wrapper {
      left: 100%;
      .mask {
        visibility: hidden;
      }
      .residential-heading {
        /* left: clamp(-400px,237px - 130vw,-264px); */
        left: clamp(-400px, 210px - 124vw, -264px);
      }
      .residential-subheading {
        left: -67%;
        /* transform: translateX(-284px); */
      }
      .image-container {
        .residential-image {
          /* could do 70vmax would be long on iphone*/
          /* width: clamp(302px, 80vw, 540px); */
          width: clamp(332px, 90vw, 540px);
          height: clamp(202px, 75vh, 360px);
        }
      }
    }
    .col-3 {
      padding-left: 0 !important;
      margin-right: 0 !important;
      .col-3-content {
        display: flex !important;
      }
    }
  }

  @media screen and (max-width: 449px) and (max-height: 500px),
    screen and (max-height: 383px) {
    .col-3 {
      .col-3-content {
        display: block !important;
      }
    }
  }

  @media screen and (max-width: 449px) and (min-height: 501px) {
    .col-3 {
      .col-3-content {
        display: flex !important;
      }
    }
  }

  @media screen and (min-width: 1024px) and (min-height: 394px) {
    .col-3 {
      .col-3-content {
        display: flex !important;
      }
    }
  }

  @media screen and (max-height: 450px), screen and (max-width: 410px) {
    .col-3-title {
      /* 15px-20 280-410vw */
      font-size: clamp(0.94rem, calc(0.26rem + 3.85vmin), 1.25rem) !important;
    }
    #complexities,
    .col-3-subheading {
      /* 19px 23  set to vmin instead of vw so that it works in landscape orientation */
      font-size: clamp(1.19rem, calc(0.65rem + 3.08vmin), 1.44rem) !important;
    }
    .resi-col-1-description,
    .col-3-description {
      p {
        /* 12px-15 280-410 */
        font-size: clamp(0.75rem, calc(0.35rem + 2.31vmin), 0.94rem);
        line-height: 1.6 !important;
        /* font-size: 51%; */
      }
    }
    .residential-heading {
      h1 {
        /* 37px-44.96 min */
        font-size: clamp(2.31rem, calc(0.92rem + 7.96vmin), 2.81rem) !important;
      }
    }
    .residential-subheading {
      h3 {
        /* 26px-30 280-380vw */
        font-size: clamp(1.63rem, calc(0.93rem + 4vmin), 1.88rem) !important;
      }
    }
  }

  /* landscape moobile device  i.e. 575px */
  /* font size in this section is handled by previous sectioon vmin 280-410 viewport units */
  @media only screen and (max-height: 450px) and (min-width: 600px) and (orientation: landscape) {
    .col-1 {
      .col-1-heading {
        margin-block: clamp(3rem, 516px - 100vh, 4.2rem) 0.8rem;
      }
    }
    .col-3 {
      .col-3-subheading {
        line-height: 1.43 !important;
      }
      .col-3-content {
        margin-top: 3.5% !important;
      }
    }
    .image-wrapper {
      .residential-heading {
        left: clamp(-220px, -16vw, -145px);
      }
      .image-container {
        .residential-image {
          width: clamp(410px, 64vw, 475px);
          height: clamp(274px, 82vh, 317px);
        }
      }
    }
  }
  /* handles galaxy fold landscape at 280px height  */
  @media only screen and (max-height: 359px) and (min-width: 600px) and (orientation: landscape) {
    .col-3 {
      .col-3-subheading {
        line-height: 1.4 !important;
      }
    }
    .image-wrapper {
      .image-container {
        .residential-image {
          height: clamp(211px, 79vh, 374px);
          width: clamp(317px, 61vw, 450px);
        }
      }
    }
  }
`;

const query = graphql`
  {
    images: allContentfulUiAssets {
      nodes {
        residential {
          # gatsbyImageData
          gatsbyImageData(quality: 85)
          title
        }
      }
    }
  }
`;

const Residential = () => {
  let outerLayer = useRef(null);
  let column1 = useRef(null);
  let column3 = useRef(null);
  let imageWrapper = useRef(null);
  let residentialTitle = useRef(null);
  let restorations = useRef(null);
  let verticalLine = useRef(null);
  let heading = useRef(null);
  let description = useRef(null);
  let mask = useRef(null);
  let distortionImage = useRef(null);
  const {
    images: {
      nodes: [{ residential }]
    }
  } = useStaticQuery(query);
  const house = getSrc(residential[0]);
  const livingRoom = getSrc(residential[1]);
  const distortion = getSrc(residential[2]);
  // const {
  //   image: { nodes }
  // } = useStaticQuery(query);
  // const distortion = getSrc(nodes[0]);
  // const livingRoom = getSrc(nodes[1]);
  // const house = getSrc(nodes[2]);

  useEffect(() => {
    distortionImage.current = new hoverEffect({
      parent: distortionImage,
      intensity: 0.2,
      image1: house,
      image2: livingRoom,
      hover: false,
      displacementImage: distortion,
      // imagesRatio: 360 / 480
      imagesRatio: 512 / 768
    });
  }, [distortion, livingRoom, house]);

  useEffect(() => {
    if (typeof window !== undefined) {
      gsap.registerPlugin(ScrollTrigger);
      // may have to access to retrieve offset width
      // console.log('restorations');

      // needed to revert styles when matchmedia is invoked
      ScrollTrigger.saveStyles(
        `.resi-col-1-description,
        .vertical-line,
        .residential-heading,
        .residential-image,
        .residential-subheading,
        #complexities`
      );

      // scrolltrigger matchmedia --> for responsive animation.
      // for now we are just animating on desktop and above.
      ScrollTrigger.matchMedia({
        '(min-width: 600px)': function () {
          // reveal image sliding to right while scaling down
          const contentReveal = gsap.timeline({
            scrollTrigger: {
              trigger: imageWrapper.current,
              start: 'top-=17% bottom',
              once: true,
              // markers: true,
              // force animation to complete if scroll velocity is over 3000
              // used to offset scrollTo; can only be used for non scrubbing STs
              fastScrollEnd: true,
              // prevents residential title from misaligned and animation from playing when page is loaded past the scrollTrigger
              toggleActions: 'play complete none none'
              // preventOverlaps: 'group1'
            }
          });
          // reveal image
          contentReveal
            .to(mask, {
              duration: 1.4,
              ease: 'expo.inOut',
              scaleX: 0
              // instead of animating width we use transform
              // width: '0%'
            })
            .from(
              '.residential-image',
              {
                scale: 1.4,
                duration: 1.4,
                ease: 'power4.inOut'
              },
              '<.1'
            )
            // slide in and reveal text
            .from(
              residentialTitle,
              {
                // slide in from left
                autoAlpha: 0, // autoAlpha sets opacity to zero and visibility to hidden
                // xPercent: -40,
                x: -250,
                ease: 'power3.inOut',
                duration: 1.3
              },
              '<.5'
            )
            .from(
              verticalLine,
              {
                scaleY: 0,
                duration: 0.6,
                ease: 'expo.inOut'
              },
              // '<'
              '<'
            )
            .from(
              // slide in from left
              heading,
              {
                // ease: Power4.easeOut,
                // bottom: '-60px',
                // skewY: 7,
                x: -265,
                ease: 'expo.out',
                duration: 0.8
              },
              // before the end of the previous tween
              '>-.1'
            )
            .to(
              restorations.current,
              {
                // slide up
                opacity: 1,
                yPercent: -97,
                duration: 0.45,
                ease: 'circ.out'
              },
              // '-=.1.9' //overlap last .3 seconds of previous
              '-=.2' //overlap last .3 seconds of previous
            )
            .from(
              description,
              {
                autoAlpha: 0,
                duration: 0.6,
                ease: 'circ.out'
              },
              // after the start
              '<'
            )
            .to(
              imageWrapper.current,
              {
                '--imageShadowOpacity': 1,
                duration: 0.6,
                ease: 'var(--customEase)'
              },
              '<'
            );

          // main tl
          const timeline1 = gsap.timeline({
            // prevents prev tl from overlapping with current if scrolled too fast or page is refreshed/loaded mid timeline1
            // causing the title x position to be out of sync
            // moved to after opacity of col1 fades/before image starts to move in timeline

            // onStart: () => {
            //   // if timeline is in progress when we reach this section, automatically force it to stop
            //   if (contentReveal.isActive()) {
            //     contentReveal.totalProgress(1);
            //   }
            // },

            scrollTrigger: {
              trigger: outerLayer.current,
              id: 'residential-st',
              start: 'center center',
              // end animation 125% from where the start is; slows scrolling
              end: () => `=+${outerLayer.current.offsetHeight}`,
              // end: () => '+=200%', can also fire a function for dynamic values
              // animation will progress as you scroll instead all at once. 0.5 seconds for animation to catch up to scroll
              scrub: 0.5,
              // similar to position: fixed
              pin: true,
              // resets any saved values on scrolltrigger during screen resize and refresh (i.e x value at imageWrapper.current)
              invalidateOnRefresh: true
              // preventOverlaps: 'group1'
            }
          });
          timeline1
            .to(column1, {
              // fade out
              autoAlpha: 0,
              duration: 0.3
              // delay add an offset scroll time before element starts to fade
              // delay: 0.3
            })
            .to(
              // move image to the left
              imageWrapper.current,
              {
                onStart: () => {
                  // if timeline is in progress when we reach this section, automatically force it to stop
                  if (contentReveal.isActive()) {
                    contentReveal.totalProgress(1);
                  }
                },
                // length of image same as 100% translate
                x: () => Math.ceil(imageWrapper.current.offsetWidth + 70) * -1,
                // xPercent: -100,
                duration: 1.4
              },
              '-=.04' //start .0.8 second before last tween ends
            )
            .fromTo(
              // if we do not slide right, then slide left so that it cannot be seen
              residentialTitle,
              {
                // ***designate a starting position to prevent x from scrolling too far left if page is refreshed during pin****
                x: 0
              },
              {
                x: -120,
                duration: 0.6
              },
              '>-1' // start 1.47 seconds before the end of the previous tween
            )
            .to(
              restorations.current,
              // slide to right
              {
                // slide to left 100% - 233px the width of the restoration text in order to make dynamic
                // x: 'calc(100% - 233px)',
                // use pixels for better performance
                x: () =>
                  Math.ceil(imageWrapper.current.offsetWidth) -
                  Math.ceil(restorations.current.offsetWidth) +
                  4,
                // additional 4 pixels is to offset gap at end
                duration: 0.8
              },
              '<.1' // start .1 after the previous tween starts
            )
            .call(
              (tl, dissImage) => {
                // calling a function to trigger image transition distortion effect
                // check scrolltrigger to see which direction we are scrolling
                const direction = tl.scrollTrigger.direction;
                if (direction > 0) {
                  dissImage.current.next();
                } else {
                  dissImage.current.previous();
                }
              },
              // arguments array
              [timeline1, distortionImage],
              '=-1.19'
            )
            .to(
              column3,
              {
                autoAlpha: 1,
                duration: 0.2
              },
              '-=.35' // fire .8 seconds before prevous tween ends
            );
        },
        '(max-width: 599px)': function () {
          // small screens
          // call 2 seconds after render
          gsap.delayedCall(2, () => {
            gsap.set(imageWrapper.current, {
              // set box shadow for image right away since we are not animating it in
              '--imageShadowOpacity': 1
            });
          });

          // reveal image sliding to right while scaling down
          const contentReveal = gsap.timeline({
            scrollTrigger: {
              trigger: imageWrapper.current,
              start: 'top-=60 bottom',
              once: true,
              // // force animation to complete if scroll velocity is over 3000
              // // used to offset scrollTo; can only be used for non scrubbing STs
              fastScrollEnd: true,
              toggleActions: 'play complete none none'
            }
          });
          // reveal image
          contentReveal
            // slide in and reveal text
            .from(
              verticalLine,
              {
                scaleY: 0,
                duration: 0.8,
                ease: 'expo.inOut'
              },
              '<'
            )
            .from(
              residentialTitle,
              {
                // slide in from left
                autoAlpha: 0, // autoAlpha sets opacity to zero and visibility to hidden
                x: -360,
                ease: 'power3.inOut',
                duration: 1.3
              },
              '<.25'
            )
            .from(
              heading,
              {
                // slide in from left
                autoAlpha: 0, // autoAlpha sets opacity to zero and visibility to hidden
                // xPercent: -40,
                x: -235,
                ease: 'power3.inOut',
                duration: 1.3
              },
              '<'
            )
            .to(
              restorations.current,
              {
                // slide up
                keyframes: {
                  '40%': { opacity: 0 },
                  '100%': { opacity: 1 }
                },
                yPercent: -97,
                duration: 0.45,
                ease: 'circ.out'
              },
              '-=.2' //overlap last .2 seconds of previous
            )
            .from(
              description,
              {
                autoAlpha: 0,
                duration: 0.55,
                ease: 'circ.out'
              },
              // after the start
              '<.1'
            );

          // main tl
          const timeline1 = gsap.timeline({
            // prevents prev tl from overlapping with current if scrolled too fast or page is refreshed/loaded mid timeline1
            // causing the title x position to be out of sync
            onStart: () => {
              // if timeline is in progress when we reach this section, automatically force it to stop
              if (contentReveal.isActive()) {
                contentReveal.totalProgress(1);
              }
            },
            scrollTrigger: {
              trigger: outerLayer.current,
              start: 'center center',
              // end animation 125% from where the start is; slows scrolling
              end: () => {
                return `+=${outerLayer.current.offsetHeight}`;
              },
              // end: () => '+=200%', can also fire a function for dynamic values
              // animation will progress as you scroll instead all at once. 0.5 seconds for animation to catch up to scroll
              scrub: 0.5,
              // similar to position: fixed
              pin: true,
              // pinSpacing: false,
              // resets any saved values on scrolltrigger during screen resize and refresh
              invalidateOnRefresh: true
            }
          });
          timeline1
            .to(column1, {
              // fade out
              autoAlpha: 0,
              duration: 0.17
            })

            .fromTo(
              // if we do not slide right, then slide left so that it cannot be seen
              residentialTitle,
              {
                // ***designate a starting position to prevent x from scrolling too far left if page is refreshed during mid pin****
                x: 0
              },
              {
                x: () => Math.floor(imageWrapper.current.offsetWidth / 1.8),
                duration: 0.8,
                ease: 'power1.in'
              },
              '<-.1' // start before previous
            )
            .to(
              restorations.current,
              // slide to right
              {
                // since left is -67% of image, we slide back in pixels with offset -3
                x: () =>
                  Math.floor(imageWrapper.current.offsetWidth * 0.67) - 3,
                // duration: 1.32,
                duration: 0.8,
                ease: 'power1.in'
              },
              '<' // start at the same time as previous
            )
            .to(
              // move image to the left
              imageWrapper.current,
              {
                // length of image same as 100% translate
                x: () => Math.ceil(imageWrapper.current.offsetWidth * -2.16),
                duration: 1.5
              },
              '<.59'
            )
            .call(
              (tl, dissImage) => {
                // calling a function to trigger image transition distortion effect
                // check scrolltrigger to see which direction we are scrolling
                const direction = tl.scrollTrigger.direction;
                if (direction > 0) {
                  dissImage.current.next();
                } else {
                  dissImage.current.previous();
                }
              },
              // arguments array
              [timeline1, distortionImage],
              '<.42'
            )
            .to(
              column3,
              {
                autoAlpha: 1,
                duration: 0.15,
                ease: 'circ.inOut'
              },
              '>.75' // fire .75 after prevous tween ends
            );
        }
      });
    }
  }, []);

  return (
    <StyledResidential ref={outerLayer} id="residentialSection">
      <div className="service-wrapper">
        <div
          className="col-1"
          id="residential-col-1"
          ref={el => (column1 = el)}
        >
          <div className="col-1-heading">
            <div
              className="vertical-line"
              ref={el => (verticalLine = el)}
            ></div>
            <h3>
              <div className="animation-box">
                <span ref={el => (heading = el)} id="complexities">
                  Complexities, Simplified.
                </span>
              </div>
            </h3>
          </div>
          <div
            className="resi-col-1-description"
            ref={el => (description = el)}
          >
            <p>
              At Khan Capital Group, we take pride in every Residential
              Restoration or new construction we are involved with. Our diverse
              team is well equipped to handle the endless challenges that arise
              from structuring the deal through the full construction of the
              property.
            </p>
          </div>
        </div>
        <div
          className="row-1-col-2 order-1 order-lg-2 image-wrapper"
          ref={imageWrapper}
        >
          <div
            className="residential-heading"
            ref={el => (residentialTitle = el)}
          >
            <h1>Residential</h1>
          </div>
          <div
            className="residential-subheading"
            // ref={el => (restorations = el)}
            ref={restorations}
          >
            <h3 className="text-gradient-2">Restorations</h3>
          </div>
          <div className="residential-location">
            Glen Cove / <span>Nassau Cty</span>
          </div>
          <div className="image-container">
            <div className="mask" ref={el => (mask = el)}></div>
            <div
              ref={el => (distortionImage = el)}
              className="residential-image"
            ></div>
          </div>
        </div>
        <div className="col-3" ref={el => (column3 = el)}>
          <div className="col-3-title">Modern Problems, Modern Solutions.</div>
          <div className="col-3-content">
            <div className="col-3-subheading">
              Don’t settle for standard. Being different is the key for
              innovation.
            </div>
            <div className="col-3-description">
              <p>
                We have the ability to transact quicker than our competitors due
                to having all key members of the team under one roof. We standby
                our finished product as we only deliver the highest quality
                products for our customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledResidential>
  );
};

export default Residential;
