import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import 'normalize.css';
import GlobalStyles from '../../styles/GlobalStyles';
import Typography from '../../styles/Typography';
import { Footer, Header, LogoReveal } from '../index';
import { InitialRenderContext } from '../../utils/InitialRenderContext';
import favicon from '../../../static/favicon.ico';

const Layout = ({ children, path }) => {
  //Remove LogoReveal from DOM after initial load
  const { initialLoad } = useContext(InitialRenderContext);

  // relocated to gatsby-browser.js
  // const key = process.env.GATSBY_CAPTCHA_KEY;
  // const testKey = process.env.GATSBY_LOCALHOST;

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon}></link>
      </Helmet>
      <GlobalStyles />
      <Typography />
      {initialLoad && <LogoReveal />}
      <Header path={path} />
      <main style={{ height: '100%' }}>{children}</main>
      <Footer path={path} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
