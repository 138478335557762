import styled from 'styled-components';
import React, { useState, useEffect, useRef, createContext } from 'react';
import PropTypes from 'prop-types';

const BaseModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
`;

// create context and provide defaults for better autocomplete
export const { Provider, Consumer } = createContext({
  modalNode: () => {
    console.log('this is the modal node for portal');
  },
  BackgroundComponent: {}
});

const ModalProvider = ({
  backgroundComponent: propsBackgroundComponent,
  children
}) => {
  const modalNode = useRef(null);
  // const [stateModalNode, setStateModalNode] = useState(null);
  const [BackgroundComponent, setBackgroundComponent] =
    useState(BaseModalBackground);

  useEffect(() => {
    if (propsBackgroundComponent) {
      setBackgroundComponent(propsBackgroundComponent);
    }
  }, [setBackgroundComponent, propsBackgroundComponent]);

  useEffect(() => {
    if (typeof document !== undefined) {
      // get portal div created in gatsby ssr and assign it to a ref
      const portal = document.getElementById('modal-portal');
      modalNode.current = portal;
    }
  });

  // useEffect(() => {
  //   if (typeof document !== undefined) {
  //     const portal = document.getElementById('modal-portal');
  //     console.log('this is the portal', portal);
  //     modalNode.current = portal;
  //     setStateModalNode(modalNode.current);
  //   }
  // }, [setStateModalNode, modalNode]);

  return (
    <Provider
      value={{
        modalNode: modalNode.current,
        BackgroundComponent
      }}
    >
      {children}
    </Provider>
  );
};

ModalProvider.propTypes = {
  backgroundComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object
  ])
};

export default ModalProvider;
