import React from 'react';
import styled from 'styled-components';

import BathIcon from '../../assets/svgs/bath-icon.min.svg';
import BedIcon from '../../assets/svgs/bed-icon.min.svg';
import SizeIcon from '../../assets/svgs/size-icon.min.svg';
import KitchenIcon from '../../assets/svgs/kitchen.svg';
import RetailIcon from '../../assets/svgs/retail-icon.svg';
import OfficeIcon from '../../assets/svgs/office-icon.svg';
import IndustrialIcon from '../../assets/svgs/industrial-icon.svg';
import CommSizeIcon from '../../assets/svgs/comm-size-icon.svg';
import MixedUseIcon from '../../assets/svgs/mixed-use.svg';
import RoomIcon from '../../assets/svgs/handle-2-icon.svg';

const StyledIconsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(104px, 1fr));
  width: ${({ type }) =>
    type === 'Commercial' ? `min(350px, 75%)` : `min(660px, 75%)`};
  gap: 1.3rem 0;
  justify-items: center;
  align-items: ${({ type }) => (type === 'Commercial' && 'flex-end') || ''};
  /* background: rgb(46 46 46 / 40%); */
  background: rgb(25 25 25 / 75%);
  border-radius: 8px;
  padding: ${({ type }) => (type === 'Commercial' ? `12px 0` : `14px 0`)};
  /* box-shadow: inset 1px 1px 1px rgb(150 150 150 / 50%),
    inset -1px -1px 1px rgb(0 0 0 / 50%), 1px 1px 1px rgb(0 0 0 / 70%); */
  /* box-shadow: 0px 0px 10px 0px rgb(98 119 235 / 90%); */
  /* box-shadow: 0px 0px 10px 0px rgb(253 211 58 / 80%); */
  /* box-shadow: 0px 0px 10px 0px rgb(253 186 26 / 80%); */
  box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 80%);
  position: relative;
  /* Glowing LED shadow */
  /* &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 50%;
    -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
    box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
    -webkit-box-shadow: 0 0 17px 3px #ffb101, 0 0 4px 2px #ffff01;
    box-shadow: 0 0 17px 3px #ffb101, 0 0 4px 2px #ffff01;
    z-index: -1;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    -webkit-box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
    box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
    -webkit-box-shadow: 0 0 17px 3px #004eff, 0 0 4px 2px #06f;
    box-shadow: 0 0 17px 3px #004eff, 0 0 4px 2px #06f;
    z-index: -1;
  } */
  .amenity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    /* padding: 0 2rem; */
    gap: 6px;
    width: 100%;
    &:not(:last-of-type) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: ${({ type }) => (type === 'Commercial' ? '50%' : '25%')};
        width: 2px;
        background-color: var(--deepBlue);
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    .icon {
      width: 41%;
      /* max-height: 44.11px; */
      display: flex;
      justify-content: center;
      svg {
        width: 100%;
        fill: var(--ghostWhite);
      }
      .bath-icon,
      .bed-icon,
      .kitchen-icon,
      .room-icon > path {
        stroke: var(--ghostWhite);
      }
      .bath-icon > path {
        stroke-width: 2px;
      }
      .kitchen-icon,
      .room-icon > path {
        stroke-width: 5px;
      }
      .bed-icon {
        path {
          stroke-width: 3px;
        }
      }
    }
    .icon-details {
      padding: 0.2rem;
      /* padding-bottom: var(--commercial-padding-btm); */
      /* 320-767 14px-16 */
      font-size: clamp(0.88rem, calc(0.79rem + 0.45vw), 1rem);

      letter-spacing: 1.3px;
      text-align: center;
      /* line-height: 1.5rem; */
      font-family: Poppins, sans-serif;
      color: var(--secondary);
    }
  }
  .amenity-commercial {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    /* flex-direction: row; */
    .icon {
      width: 31%;
    }
  }

  @media screen and (min-width: 400px) and (max-width: 693px) {
    grid-template-columns: ${({ type }) =>
      type !== 'Commercial' && `repeat(3, minmax(91px, 110px))`};
    justify-content: center;
    /* designate a max width */
    width: ${({ type }) =>
      type !== 'Commercial' ? `min(336px, 69%)` : `min(310px, 70%)`};
    .amenity:nth-child(3) {
      &::after {
        content: unset;
      }
    }
    .resi-amenity-sqft {
      grid-column-end: span 2;
      .icon {
        /* setting to span 2 doubles the width, so we cut in half */
        width: 20.5%;
      }
    }
  }

  @media screen and (max-width: 399px) {
    grid-template-columns: ${({ type }) => type !== 'Commercial' && `1fr 1fr`};
    /* set a minimum width */
    width: ${({ type }) =>
      type !== 'Commercial' ? `max(180px, 60%)` : `max(220px, 70%)`};
    .resi-amenity:nth-child(even) {
      &::after {
        content: unset;
      }
    }
    .resi-amenity-sqft {
      grid-column-end: span 2;
      .icon {
        /* setting to span 2 doubles the width, so we cut in half */
        width: 20.5%;
      }
    }
  }
  @media screen and (max-width: 277px) {
    width: ${({ type }) => type === 'Commercial' && `140px`};
    .amenity-commercial:first-child {
      &::after {
        content: unset;
      }
    }
  }
`;

const getCommercialIcon = name => {
  switch (name) {
    case 'Office':
      return <OfficeIcon />;
    case 'Retail':
      return <RetailIcon />;
    case 'Industrial':
      return <IndustrialIcon />;
    case 'Mixed-Use':
      return <MixedUseIcon />;
    default:
      return;
  }
};

const IconsList = ({
  type,
  commercial: { iconName, commSquareFt },
  residential: { bedrooms, bathrooms, kitchens, squareFt, rooms }
}) => {
  const residentialIcons = [
    { name: 'RM', value: rooms, resIcon: <RoomIcon /> },
    { name: 'BD', value: bedrooms, resIcon: <BedIcon /> },
    { name: 'BA', value: bathrooms, resIcon: <BathIcon /> },
    { name: 'KIT', value: kitchens, resIcon: <KitchenIcon /> },
    { name: 'FT', value: squareFt, resIcon: <SizeIcon /> }
  ];

  return (
    <StyledIconsList type={type} iconName={iconName}>
      {type === 'Commercial' ? (
        <>
          <div className="amenity amenity-commercial">
            <div className="icon">{getCommercialIcon(iconName)}</div>
            <h6 className="icon-details">
              {iconName}
              <br />
            </h6>
          </div>
          <div className="amenity amenity-commercial">
            <div className="icon">
              <CommSizeIcon />
            </div>
            <h6 className="icon-details">
              <span>{commSquareFt} FT&sup2;</span>
            </h6>
          </div>
        </>
      ) : (
        <>
          {residentialIcons.map(({ name, value, resIcon }, i) => (
            <div
              className={[
                'amenity',
                'resi-amenity',
                i === 4 ? 'resi-amenity-sqft' : ''
              ].join(' ')}
              key={value + i}
              // style={{ paddingLeft: name === 'Rooms' && '0' }}
            >
              <div className="icon">{resIcon}</div>
              <h6 className="icon-details">
                <span>{value}</span> {name}
                {name === 'FT' && <span>&sup2;</span>}
              </h6>
            </div>
          ))}
        </>
      )}
    </StyledIconsList>
  );
};

export default IconsList;
