import React, { useState } from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '../UI/Button';
import ModalContactForm from '../ContactForm/ModalContactForm';

const StyledImageContainer = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: linear-gradient(
      to left,
      rgb(31 41 56 / 21%) 0%,
      rgb(14 25 48 / 12%) 1%,
      #0d223270 100%
    );
    /* 0d223280 */
  }
  .content {
    position: absolute;
    z-index: 5;
  }
  .gatsby-image-wrapper {
    height: 80vh;
    max-height: 628px;
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
`;

const ContentWrapper = styled.div`
  width: 90%;
  .title {
    text-transform: uppercase;
    letter-spacing: 1.3px;
    text-align: center;
    line-height: 2;
    color: var(--ghostWhite);
    font-family: Poppins, sans-serif;
    font-weight: 600;
  }
  .button-wrapper {
    text-align: center;
    filter: hue-rotate(-36deg);
    margin-top: 1.75rem;
  }
`;

const modalBackground = 'linear-gradient(-192deg, #0d1e39 27%, #132e60 100%);';

const query = graphql`
  {
    asset: contentfulUiAssets {
      inquiries {
        description
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
  }
`;

const Inquiries = () => {
  const {
    asset: { inquiries }
  } = useStaticQuery(query);
  const image = getImage(inquiries);

  const [showContact, setContactState] = useState(false);
  const [disabledContactButton, setDisabledContactButton] = useState(false);

  const disableContactButton = () => {
    setDisabledContactButton(true);
    setTimeout(() => {
      setDisabledContactButton(false);
    }, 1500);
  };

  const toggleContactForm = () => {
    disableContactButton();
    setContactState(prevState => !prevState);
  };

  return (
    <div
      style={{
        filter: 'hue-rotate(36deg)',
        position: 'relative'
      }}
    >
      <StyledImageContainer>
        <GatsbyImage
          image={image}
          alt={inquiries?.description}
          // objectPosition="0 calc(100% - 50px)"
        />
      </StyledImageContainer>
      <ContentContainer>
        <ContentWrapper>
          <h6 className="title">
            We'd love to make an offer on your property.
          </h6>
          <div className="button-wrapper">
            <Button
              handleClick={toggleContactForm}
              disabled={disabledContactButton}
              expanded={showContact}
            >
              Get In Touch
            </Button>
            <ModalContactForm
              background={modalBackground}
              isOpen={showContact}
              onEscapeKeydown={toggleContactForm}
              onBackgroundClick={toggleContactForm}
              onClose={toggleContactForm}
            />
          </div>
        </ContentWrapper>
      </ContentContainer>
    </div>
  );
};

export default Inquiries;
