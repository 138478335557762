import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import Button from '../UI/Button';
import { PortfolioList } from '..';

const StyledWrapper = styled.div`
  background: var(--primary);
  background: linear-gradient(180deg, #0d1e39 27%, #132e60 100%),
    -webkit-linear-gradient(180deg, #0d1e39 27%, #132e60 100%);
  width: 100%;
  /* padding: 3vw 6.5vw 9vw; */
  padding: clamp(3rem, 7.5vw, 10rem) clamp(10px, 2.5vw, 10rem)
    clamp(10px, 9vw, 8rem);

  .title {
    font-family: Poppins, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 600;
    text-align: center;
    color: white;
    /* 14.5-16 300-425 */
    font-size: clamp(0.91rem, calc(0.68rem + 1.2vw), 1rem);
  }
`;

const Heading = styled.div`
  margin-block: 2rem 1rem;
  .circle {
    color: var(--lightBlue);
    display: block;
    text-align: center;
    &:first-of-type {
      margin-top: 0.3rem;
      margin-bottom: -0.5rem;
      color: var(--secondary);
    }
  }
`;

const ContentContainer = styled.div`
  /* display: flex; */
  /* flex-wrap: wrap; */
  width: 100%;
  /* color: white; */
  /* justify-content: space-between; */
  padding: 1rem 0;
  /* select all divs that are children of this element */
  /* & > div {
    width: 31%;
  } */
  .showcase-container {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    justify-items: center;
    gap: 3rem 0; */

    width: min(960px, 96%);
    margin: 3rem auto 5rem;

    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 3rem 1.5rem;

    @media screen and (max-width: 728px) {
      > div {
        width: clamp(320px, 75vw, 400px);
      }
    }

    @media screen and (min-width: 1024px),
      screen and (max-width: 728px) and (min-width: 490px) {
      .item-2-content-container {
        .desc-subheading {
          .card-subheading-divider {
            margin-inline: min(1.15vw, 0.5rem);
          }
          .zone {
            display: inline;
          }
        }
      }
    }
  }
`;

const Description = styled.div`
  width: min(80%, 800px);
  text-align: center;
  margin: auto;
  .item-1-description {
    color: #f8f8ff;
    padding-bottom: 1rem;
    /* 14px-15 280-410 */
    font-size: clamp(0.88rem, calc(0.74rem + 0.77vw), 0.94rem);
  }
  .item-1-heading {
    /* 26-32 280-450 */
    font-size: clamp(1.63rem, calc(1.01rem + 3.53vw), 2rem);
    line-height: 1.5;
    letter-spacing: 0.7px;
    color: var(--altSecondary);
    font-family: Poppins, sans-serif;
  }
`;

const query = graphql`
  {
    properties: allContentfulProperty(
      filter: { featured: { eq: true } }
      limit: 2
    ) {
      nodes {
        id
        address
        bathrooms
        bedrooms
        city
        price
        squareFt
        status
        icon
        thumbnail {
          gatsbyImageData(placeholder: BLURRED)
          description
        }
        type
        slug
        zone
      }
    }
  }
`;

const Portfolio = () => {
  const { properties } = useStaticQuery(query);
  // console.log(properties);
  return (
    <StyledWrapper>
      <Heading>
        <h6 className="title">Our Portfolio</h6>
        <span className="circle">&#8226;</span>
        <span className="circle">&#8226;</span>
      </Heading>
      <ContentContainer>
        <Description>
          <article>
            <h3 className="item-1-heading">
              Extraordinary Properties, Stimulated for Growth.
            </h3>
            <p className="item-1-description">
              Our capabilities cover the property investment and development
              journey. We invest a significant amount of time towards Project
              Feasibility and Management before each acquisition in order to
              ensure satisfactory results at the conclusion of each project.
            </p>
          </article>
          <Button
            handleClick={() => {
              navigate('/portfolio');
            }}
            role="link"
          >
            Explore the Portfolio
          </Button>
        </Description>
        <h6
          className="title"
          style={{ color: 'var(--lightOrange)', marginTop: '5rem' }}
        >
          Featured
        </h6>
        <section className="showcase-container">
          <PortfolioList
            properties={properties}
            width={`clamp(320px, 38vw, 400px)`}
          />
        </section>
      </ContentContainer>
    </StyledWrapper>
  );
};

export default Portfolio;
