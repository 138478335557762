import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`

  html, body {
    font-family: Poppins, sans-serif;
    color: var(--black);
    ${'' /* overflow-x: hidden; */}
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: Calluna, auto;
    font-weight: normal;
    ${'' /* set at -1px letter spacing to closer resemble Canela font */}
    letter-spacing: -1px;
    margin: 0;
  }
  p{
    font-size: 15px;
    line-height: 28px;
    letter-spacing: .3px;
    color: rgba(46,46,46,.8);
  }
`;

export default Typography;
