import React from 'react';
import styled from 'styled-components';
import SuccessSVG from '../../assets/svgs/success.svg';
import FailureSVG from '../../assets/svgs/failure.svg';

const StyledSubmissionMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  button {
    margin: 1rem;
    font-family: Poppins, sans-serif;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-in-text {
    opacity: 0;
    animation: fadeIn 0.8s ease-in-out 0.6s 1 forwards;
    -webkit-animation: fadeIn forwards 0.8s ease-in-out 0.6s forwards;
    -moz-animation: fadeIn forwards 0.8s ease-in-out 0.6s forwards;
    -o-animation: fadeIn forwards 0.8s ease-in-out 0.6s forwards;
    -ms-animation: fadeIn forwards 0.8s ease-in-out 0.6s forwards;
    h2 {
      color: ${({ textTheme }) =>
        textTheme === 'dark' ? 'var(--darkBlack)' : 'var(--ghostWhite)'};
      text-align: center;
      margin-top: 0.75rem;
    }
    p {
      color: ${({ textTheme }) =>
        (textTheme === 'dark' && 'var(--black)') || 'var(--grey)'};
      width: 75%;
      margin: 1rem auto;
      text-align: center;
    }
  }

  .btn-close {
    position: relative;
    font-size: 0.8rem;
    letter-spacing: 2.5px;
    outline: none;
    border-radius: 3px;
    color: #5571ff;
    text-decoration: none;
    background: transparent;
    border: 1px solid #5571ff;
    padding: 0.8rem 2.2rem;
    /* overflow: hidden; */
    cursor: pointer;
    transition: color 0.3s var(--colorEase);
    -webkit-transition: 0.3s color var(--colorEase);
    &:before {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      box-sizing: border-box;
      background: #5571ff;
      content: '';
      position: absolute;
      /* 
      two different methods of centering 
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); 
      height: 100%;
      width: 0; */
      top: 0;
      left: 50%;
      right: 50%;
      bottom: 0;
      z-index: -1;
      transition: all 0.5s ease;
      -webkit-transition: 0.5s all ease;
      opacity: 0;
    }
    &:hover:before {
      /* width: 100%; */
      left: 0;
      right: 0;
      opacity: 1;
    }
    &:hover {
      color: var(--grey);
    }
  }
`;

const StyledSubmissionMarkerWrapper = styled.div`
  --circle-length: 300px;
  --check-length: 35px;

  width: 70px;

  @keyframes scaleAnimation {
    0% {
      opacity: 0;
      transform: scale3d(1.5, 1.5, 1);
    }
    100% {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes drawCircle {
    0% {
      stroke-dashoffset: var(--circle-length);
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes drawCheck {
    0% {
      stroke-dashoffset: var(--check-length);
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes drawLine {
    from {
      stroke-dashoffset: 100;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  #failureAnimationCircle {
    stroke-dasharray: var(--circle-length) var(--circle-length);
    stroke: #eb3d26;
  }
  #successAnimationCircle {
    stroke-dasharray: var(--circle-length) var(--circle-length);
    stroke: #53e13f;
  }

  #failureAnimationCheck {
    stroke-dasharray: var(--check-length) var(--check-length);
    stroke: #eb3d26;
  }

  #successAnimationCheck {
    stroke-dasharray: var(--check-length) var(--check-length);
    stroke: #53e13f;
  }

  #successAnimation.animated,
  #failureAnimation.animated {
    animation: 1s ease-out 0s 1 both scaleAnimation;

    #successAnimationCircle,
    #failureAnimationCircle {
      animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle;
    }

    #successAnimationCheck {
      animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck;
    }

    .failureAnimationCheckLine {
      stroke-dasharray: 100;
      stroke-dashoffset: 100;
      animation: drawLine 1s cubic-bezier(1, 0.2, 0.1, 1) forwards;
    }
  }
`;

const SubmissionMessage = ({ type, onClose, textTheme }) => (
  <StyledSubmissionMessageContainer textTheme={textTheme}>
    {type === 'success' ? (
      <>
        <StyledSubmissionMarkerWrapper>
          <SuccessSVG />
        </StyledSubmissionMarkerWrapper>
        <div className="fade-in-text">
          <h2>Success!</h2>
          <p>
            Thank you for your interest in Khan Capital Group. We will be in
            touch with you shortly.
          </p>
        </div>
      </>
    ) : (
      <>
        <StyledSubmissionMarkerWrapper>
          <FailureSVG />
        </StyledSubmissionMarkerWrapper>
        <div className="fade-in-text">
          <h2>Oh no!</h2>
          <p>Oops! Looks like something went wrong. </p>
          <p>
            Please try again or contact us directly at{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href="mailto:pkhan@khancapitalgroup.com"
            >
              pkhan@khancapitalgroup.com
            </a>
            .
          </p>
        </div>
      </>
    )}
    <button
      className="fade-in-text btn-close"
      // type="button"
      onClick={() => {
        onClose();
      }}
    >
      <span>CLOSE</span>
    </button>
  </StyledSubmissionMessageContainer>
);

export default SubmissionMessage;
