import React, { useContext, useRef, useState, useEffect } from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { InitialRenderContext } from '../../utils/InitialRenderContext';
import Button from '../UI/Button';
import poster from '../../assets/images/hero-poster.webp';

const HeroContainer = styled.div`
  display: flex;
  height: max(800px, 100vh);
  /* margin-top: -130px; */
  position: relative;
  align-items: center;
  justify-content: center;
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  filter: brightness(0.48);
`;

const VideoBackground = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  object-position: 44%;

  @media screen and (max-width: 425px) {
    object-position: left;
  }
`;

const HeroContent = styled.div`
  z-index: 11;
  /* height: calc(100vh - 100px); */
  /* max-height: 100%; */
  margin-top: 10vw;
  margin-top: max(6rem, 10vw);
`;

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;
  padding: 0 clamp(2rem, 14vw, 25rem);

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 2.8rem;
    /* 36-46px 320-1140 */
    font-size: clamp(1.88rem, calc(1.48rem + 1.95vw), 2.88rem);
    /* color: #f9f7f7; */
    color: var(--ghostWhite);
    line-height: 135%;
    .slogan-line {
      /* margin-bottom: 0.6rem; */
      margin-bottom: 8px;
      height: 56px;
      overflow: hidden;
      /* position: relative; */
      /* span {
        position: absolute;
      } */
    }
    .sm-break {
      display: none;
    }
  }
  p {
    font-size: clamp(0.8rem, 2vw, 14px);
    margin: 0;
    margin-top: -1rem;
    width: 65%;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    color: var(--ghostWhite);
    font-family: Poppins, sans-serif;
  }

  @media screen and (max-width: 600px) {
    text-align: center;
    p {
      width: 100%;
    }
    /* splits 'real estate development group' into two separate lines */
    .sm-break {
      display: block !important;
    }
  }
  @media screen and (max-width: 424px) {
    margin-inline: auto;
    padding: 0 1rem;
  }
`;

// const getVideoElement = async video => {
//   try {
//     await video.current.oncanplaythrough(event => {
//       console.log('play ready', event);
//     });
//   } catch (error) {
//     console.log('error');
//   }
// };

const heroBgVideoQuery = graphql`
  {
    uiAsset: contentfulUiAssets {
      hero {
        file {
          url
        }
      }
    }
  }
`;

const Hero = () => {
  const {
    uiAsset: {
      hero: {
        file: { url }
      }
    }
  } = useStaticQuery(heroBgVideoQuery);

  // used to set autoplay to true and preload to auto on mobile so that video can work for ios/mobile devices
  const [isDesktop, setIsDesktop] = useState(false);

  const videoRef = useRef(null);
  //create context to see if its initial load
  const { initialLoad } = useContext(InitialRenderContext);
  //if intial load then settimeout for video playback so it starts after logoReveal. if not initial load, then play automatically
  const toggleVideoPlayback = initial => {
    if (initial) {
      setTimeout(() => {
        // console.log('timed out');
        // videoRef.current?.pause();
        videoRef.current?.play();
        // videoRef.current.pause();
      }, 3200);
    } else {
      // console.log('load status:', initial);
      videoRef.current.pause();
      // videoRef.current.play();
    }
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      const device = window.matchMedia('(min-width: 769px)');
      if (device.matches) {
        setIsDesktop(true);
      }
    }
  }, []);

  return (
    <>
      <HeroContainer>
        <HeroBackground>
          <VideoBackground
            // src={url}
            // type="video/mp4"
            loop
            autoPlay={isDesktop ? false : true}
            preload={isDesktop ? 'metadata' : 'auto'}
            ref={videoRef}
            muted
            playsInline
            poster={poster}
            onCanPlayThrough={() => {
              if (isDesktop) {
                toggleVideoPlayback(initialLoad);
              }
              // console.log('video playthrough ready');
            }}
          >
            <source src={url} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos or the HTML5
            video tag, but don't worry, you can{' '}
            <a href="https://www.pexels.com/video/city-new-york-bridge-manhattan-5838621/">
              download it
            </a>
            and watch it with your favorite video player!
          </VideoBackground>
        </HeroBackground>
        <HeroContent>
          <HeroItems>
            <div className="title text-gradient-3">
              <h1>
                New York's
                <br />
                Premier All-Inclusive
                <br />
                Real Estate <br className="sm-break" /> Development Group
              </h1>
            </div>
            <p>
              Our goal is to provide a seamless transaction for our clients and
              see every deal from start to finish, no matter the complexity. We
              take the impossible and make it possible.
            </p>
            <div className="btn-container" id="nav-trigger">
              <Button
                fontColor={`var(--secondary)`}
                fontSize={`.85rem`}
                role="link"
                handleClick={() => {
                  navigate('/portfolio');
                }}
              >
                Explore the Portfolio
              </Button>
              {/* <Button fontColor={`var(--secondary)`} handleClick={() => {}}>
                Inquiries
              </Button> */}
            </div>
          </HeroItems>
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default Hero;
