import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import HeaderNameLogo from '../../assets/svgs/header-logo-name.svg';
import HeaderTowersLogo from '../../assets/svgs/header-logo-towers.svg';

const StyledLogoButton = styled.div`
  position: relative;
  /* left: calc(50% - 1px);
  transform: translate(-50%); */

  #logoButtonWrapper {
    /* max-width: 104px; */
    /* width: 7.3vw; */
    /* width: 8vw; */
    display: flex;
    /* transition: all 0.12s ease; */
    flex-direction: column;
    cursor: pointer;
    /* filter: brightness(0.9); */
    .logo-towers-container {
      display: flex;
      justify-content: center;
      margin-bottom: 0.01rem;
      svg {
        width: 105px;
        width: clamp(80px, 13vw, 108px);
        /* transition: width 0.11s ease; */
      }
    }
    #navLogoTowers.fixed-active {
      width: 90px;
      width: clamp(76px, 10vmin, 90px);
      /* transition: width 0.11s ease; */
    }
    #navLogoName.fixed-active {
      width: 106px;
      width: clamp(86px, 28.5vmin, 106px);
    }
    #logoNameContainer {
      display: flex;
      justify-content: center;
      margin-top: -1px;
      /* -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0) scale(1, 1); */
      /* padding-top: 8px; for fixed nav active only */
      svg {
        width: 98px;
        width: clamp(74px, 12vw, 98px);
      }
    }
    #logoNameContainer.fixed-active {
      padding-top: 7px;
    }
  }
  /* make towers and name svg appear side by side when header is fixed */
  #logoButtonWrapper.fixed-active {
    /* width: auto; */
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    #logoButtonWrapper.fixed-active {
      .logo-towers-container {
        display: none !important;
      }
    }
  }
`;

const LogoButton = () => (
  <StyledLogoButton>
    <Link to="/" className="logo-button-wrapper" id="logoButtonWrapper">
      <div className="logo-towers-container">
        <HeaderTowersLogo />
      </div>
      <div className="logo-name-container" id="logoNameContainer">
        <HeaderNameLogo />
      </div>
    </Link>
  </StyledLogoButton>
);

export default LogoButton;
