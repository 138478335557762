import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import SubmissionMessage from '../../ContactForm/SubmissionMessage';
import Spinner from '../../UI/Spinner';

import {
  validationSchema,
  initialValues,
  TextInput,
  DropdownSelect
} from '../../ContactForm/ContactForm';

const StyledSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledContactForm = styled.div`
  color: var(--secondary);
`;

const FormContainer = styled.div`
  .contact-form {
    display: flex;
    justify-content: center;
    gap: 2rem;
    .form-section {
      font-size: 15px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      text-align: left;
      width: min(300px, 43%);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      .input-container {
        display: flex;
        flex-direction: column;
      }
      .error {
        position: absolute;
        right: 0;
        font-size: 12px;
        bottom: -16px;
        letter-spacing: 0.2px;
        color: var(--coral);
      }

      .input-box {
        position: relative;
        z-index: 0;
      }
      :focus {
        outline: none;
        -webkit-appearance: none;
      }

      /* To remove button from IE11 */
      select::-ms-expand {
        display: none;
      }

      input[type='text'],
      input[type='email'],
      textarea,
      select {
        font: 15px/24px Poppins, sans-serif;
        color: var(--black);
        width: 100%;
        height: 2.5rem;
        letter-spacing: 0.2px;
        box-sizing: border-box;
      }

      .select-box {
        position: relative;
        z-index: 0;
      }

      .select-box:after {
        content: '>';
        position: absolute;
        color: var(--deepBlue);
        font-size: 20px;
        transform: translate(0, -50%) rotate(90deg);
        right: 15px;
        top: 50%;
        pointer-events: none;
      }

      .select-box select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -ms-word-break: normal;
        word-break: normal;
        padding-right: 38px;
        text-overflow: ellipsis;
      }

      /* get rid of autofill styles */
      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
      }

      textarea {
        /* 2.5rem + 2.5 + 2(gap) */
        height: 112px;
      }

      .effect-20 {
        border: 0.7px solid rgb(94 174 245 / 50%);
        padding: 6px 10px;
        transition: 0.4s;
        background: transparent;
      }
      .effect-20 ~ .focus-border:before,
      .effect-20 ~ .focus-border:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 2px;
        /* background-color: #2994fd6e; */
        background-color: #baddff;
        transition: 0.3s;
      }
      .effect-20 ~ .focus-border:after {
        top: auto;
        bottom: 0;
        left: auto;
        right: 0;
      }
      .effect-20 ~ .focus-border i:before,
      .effect-20 ~ .focus-border i:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 0;
        /* background-color: #2994fd6e; */
        background-color: #baddff;
        transition: 0.4s;
      }
      .effect-20 ~ .focus-border i:after {
        left: auto;
        right: 0;
        top: auto;
        bottom: 0;
      }
      .effect-20:focus ~ .focus-border:before,
      .effect-20:focus ~ .focus-border:after,
      .has-content.effect-20 ~ .focus-border:before,
      .has-content.effect-20 ~ .focus-border:after {
        width: 100%;
        transition: 0.3s;
      }
      .effect-20:focus ~ .focus-border i:before,
      .effect-20:focus ~ .focus-border i:after,
      .has-content.effect-20 ~ .focus-border i:before,
      .has-content.effect-20 ~ .focus-border i:after {
        height: 100%;
        transition: 0.4s;
      }
      .effect-20 ~ label {
        position: absolute;
        left: 14px;
        top: 14px;
        color: var(--deepBlue);
        transition: 0.3s;
        letter-spacing: 1.2px;
        z-index: -1;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
      .effect-20:focus ~ label,
      .has-content.effect-20 ~ label {
        top: -18px;
        left: 0;
        font-size: 12px;
        color: var(--deepBlue);
        transition: 0.3s;
        letter-spacing: 1.4px;
      }
      .submit-contact-form-btn {
        position: relative;
        z-index: 0;
        font-size: 13.5px;
        outline: none;
        width: 100%;
        height: 2.5rem;
        letter-spacing: 2.5px;
        font-weight: 600;
        border-radius: 3px;
        color: #5571ff;
        text-decoration: none;
        background: transparent;
        border: 1px solid #5571ff;
        text-transform: uppercase;
        overflow: hidden;
        cursor: pointer;
        transition: color 0.3s var(--colorEase);
        -webkit-transition: 0.3s color var(--colorEase);
        &:before {
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          background: var(--buttonBlue);
          content: '';
          position: absolute;
          /* 
      two different methods of centering 
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); 
      height: 100%;
      width: 0; */
          top: 0;
          left: 50%;
          right: 50%;
          bottom: 0;
          z-index: -1;
          transition: all 0.5s ease;
          -webkit-transition: 0.5s all ease;
          opacity: 0;
        }
        &:hover:before {
          /* width: 100%; */
          left: 0;
          right: 0;
          opacity: 1;
        }
        &:hover {
          color: var(--ghostWhite);
        }
        span {
          pointer-events: none;
        }
      }

      /* .squishy span {
         This helps IE 
        pointer-events: none;
        display: inline-flex;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      } */

      @-webkit-keyframes pulseBox {
        0% {
          -webkit-box-shadow: 0px 0px 10px 0px rgba(98, 119, 235, 0.6);
        }
        100% {
          -webkit-box-shadow: 0px 0px 18px 0px rgba(98, 119, 235, 0.9);
        }
      }
      @-moz-keyframes pulseBox {
        0% {
          -moz-box-shadow: 0px 0px 10px 0px rgba(98, 119, 235, 0.6);
        }
        100% {
          -moz-box-shadow: 0px 0px 18px 0px rgba(98, 119, 235, 0.9);
        }
      }
      @-o-keyframes pulseBox {
        0% {
          -o-box-shadow: 0px 0px 10px 0px rgba(98, 119, 235, 0.6);
        }
        100% {
          -o-box-shadow: 0px 0px 18px 0px rgba(98, 119, 235, 0.9);
        }
      }
      @keyframes pulseBox {
        0% {
          box-shadow: 0px 0px 10px 0px rgba(98, 119, 235, 0.6);
        }
        100% {
          box-shadow: 0px 0px 18px 0px rgba(98, 119, 235, 0.9);
        }
      }

      .pulseBox {
        -webkit-animation-name: pulseBox;
        -moz-animation-name: pulseBox;
        -o-animation-name: pulseBox;
        animation-name: pulseBox;
        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -o-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-direction: alternate;
        -moz-animation-direction: alternate;
        -o-animation-direction: alternate;
        animation-direction: alternate;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        border: 0px;
      }
    }
    .submit-contact-form-btn:active,
    .pulseBox:active {
      box-shadow: inset 1px 1px 1px rgba(150, 150, 150, 0.5),
        inset -1px -1px 1px rgba(0, 0, 0, 0.5), 1px 1px 1px rgba(0, 0, 0, 0.7);
    }
    .submit-contact-form-btn.pulseBox {
      color: var(--deepBlue);
      transition: color 0.3s var(--colorEase);
    }
  }

  @media screen and (max-width: 550px) {
    .contact-form {
      flex-direction: column;
      align-items: center;
      .form-section {
        width: min(75%, 250px);
      }
    }
  }
`;

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  // handle spinner state
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  // handle success or error messages after submission
  const [isSubmitted, setSubmitted] = useState({
    isSubmitted: false,
    type: ''
  });

  const submitData = useCallback(async (values, _, recaptchaToken) => {
    // setSubmitting(true);
    const {
      botField,
      firstName,
      lastName,
      email,
      phoneNumber,
      subject,
      message
    } = values;
    try {
      await axios.post('/api/submit-inquiry', {
        botField,
        firstName,
        lastName,
        email,
        phoneNumber,
        subject,
        message,
        recaptchaToken
      });
      // no longer necessary
      // onClose();
      // setSubmitting(false);
      setFormSubmitting(false);
      setSubmitted({ isSubmitted: true, type: 'success' });
    } catch (error) {
      if (error.response) {
        console.log(
          'Server responded with non 2xx code: ',
          error.response.data
        );
      } else if (error.request) {
        console.log('No response received: ', error.request);
      } else {
        console.log('Error setting up response: ', error.message);
      }
      setFormSubmitting(false);
      setSubmitted({ isSubmitted: true, type: 'failure' });
    }
  }, []);

  // Create an event handler so you call the verification on button click event or form submit
  const handleRecaptchaVerify = useCallback(
    async (values, actions) => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha no yet available');
        return;
      }
      setFormSubmitting(true);
      const token = await executeRecaptcha('submit');
      submitData(values, actions, token);
    },
    [executeRecaptcha, submitData]
  );

  return (
    <>
      {isFormSubmitting ? (
        <StyledSpinnerContainer>
          <Spinner />
        </StyledSpinnerContainer>
      ) : isSubmitted.isSubmitted ? (
        <SubmissionMessage
          type={isSubmitted.type}
          onClose={() => setSubmitted({ isSubmitted: false, type: '' })}
          textTheme="dark"
        />
      ) : (
        <StyledContactForm>
          <FormContainer>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleRecaptchaVerify}
              validateOnMount
            >
              {({ isSubmitting, isValid, handleBlur, handleChange }) => (
                <Form className="contact-form">
                  <div className="form-section">
                    <Field
                      as="input"
                      name="botField"
                      label="Bot Field"
                      placeholder="Bot Field"
                      type="hidden"
                      aria-hidden="true"
                    />
                    <div className="input-container">
                      <TextInput
                        name="firstName"
                        label="First Name"
                        type="text"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="input-container">
                      <TextInput
                        name="lastName"
                        label="Last Name"
                        type="text"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="input-container">
                      <TextInput
                        name="email"
                        label="Email Address"
                        type="email"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="input-container">
                      <TextInput
                        name="phoneNumber"
                        label="Phone Number"
                        type="text"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="input-container">
                      <DropdownSelect
                        label="How can we help?"
                        name="subject"
                        handleBlur={handleBlur}
                      >
                        <option
                          value=""
                          disabled
                          aria-label="select an option"
                        ></option>
                        <option value="Im interested in a free property evaluation">
                          I'm interested in a free property evaluation
                        </option>
                        <option value="I'd like to explore investment opportunities">
                          I'd like to explore investment opportunities
                        </option>
                        <option value="I have a general real estate question">
                          I have a general real estate question
                        </option>
                        <option value="Other">Other</option>
                      </DropdownSelect>
                    </div>
                    <div className="input-container">
                      <TextInput
                        name="message"
                        label="Message"
                        isTextArea={true}
                        rows="4"
                        type="textarea"
                        handleBlur={handleBlur}
                      />
                    </div>
                    <div>
                      <button
                        className={[
                          'submit-contact-form-btn',
                          isValid && 'pulseBox'
                        ].join(' ')}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </StyledContactForm>
      )}
    </>
  );
};

export default ContactForm;
