import { throttle } from './throttle';

export const scrollToIDs = [
  { to: '#aboutSection', name: 'About', menu_name: 'About' },
  {
    to: '#residentialSection',
    name: 'Residential',
    menu_name: 'Residential'
  },
  {
    to: '#commercialSection',
    name: 'Commercial',
    menu_name: 'Commercial'
  },
  {
    to: '#investmentSection',
    name: 'Investing',
    offset: -35,
    menu_name: 'Investing'
  },
  {
    to: '#portfolioSection',
    name: 'Portfolio',
    offset: -10,
    menu_name: 'Portfolio'
  },
  {
    to: '#inquiriesSection',
    name: 'Inquiries',
    offset: 100,
    menu_name: 'Inquiries'
  }
];

export const navigateToIDs = (path, funcRef, id, offset, navigate) => {
  if (path === '/') {
    funcRef.current(id, offset);
  } else {
    navigate(`/`);
    // settimeout prevents race condition
    setTimeout(() => {
      funcRef.current(id, offset);
    }, 200);
  }
};
// no memoization is needed because no re-renders occur here and function is outside a component scope
export const throttleNavClick = throttle(navigateToIDs, 4000);
