import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { navigate } from 'gatsby';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import StaticTowers from '../../assets/svgs/kcg-static-towers.svg';
import StaticName from '../../assets/svgs/kcg-static-name.svg';
import { scrollToIDs, navigateToIDs } from '../../utils/scrollToIDs';

const StyledFooter = styled.footer`
  padding-block: clamp(3rem, 8vw, 5rem) clamp(2rem, 7vw, 3rem);
  background: var(--primary);
  background: linear-gradient(0deg, #0d1e39 27%, #132e60 100%),
    -webkit-linear-gradient(0deg, #0d1e39 27%, #132e60 100%);
  display: grid;
  place-items: center;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 92%;
  gap: 5rem;
  .footer-nav-item {
    color: var(--ghostWhite);
    cursor: pointer;
    position: relative;
    display: inline;
    /* opacity: 0.8; */
    /* fixes flashes/flickering caused by pseudo elements  */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--ghostWhite);
      transform: scaleX(0);
      visibility: hidden;
      transition: all 0.3s ease-in-out 0s;
    }
    &:hover::after {
      transform: scaleX(1);
      visibility: visible;
    }
    /* transition: opacity 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95); */
    transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover {
      opacity: 1;
    }
    span {
      letter-spacing: 0.3px;
    }
  }

  @media screen and (min-width: 840px) {
    width: min(1250px, 85%);
  }
  @media screen and (max-width: 434px) {
    gap: 2rem;
    .footer-nav-item {
      &::after {
        /* disables the underline hover effect on sm screen size */
        content: unset;
      }
    }
  }
`;

const Section1 = styled.div`
  width: 100%;
  /* height since flex-direction is column */
  /* display: flex; */
  /* justify-content: space-around; */

  display: grid;
  grid-gap: 3rem 0rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  h5 {
    color: var(--white);
    font-family: Poppins;
    margin-bottom: 1em;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.7px;
  }
  .footer-logo-wrapper {
    align-items: center;
    gap: 3px;
    display: flex;
    flex-direction: column;
    .footer-tower-container {
      display: flex;
      justify-content: center;
      svg {
        /* width: clamp(105px, 16vw, 130px); */
        width: 116px;
      }
    }
    .footer-name-container {
      display: flex;
      justify-content: center;
      svg {
        /* width: clamp(100px, 15vw, 124px); */
        width: 110px;
      }
    }
  }
  .navigation {
    /* padding-left: 3rem; */
    text-align: center;
    ul {
      list-style: none;
      font-size: 15px;
      padding: 0;
      margin: 0;
      letter-spacing: 0.2px;
    }
    li {
      line-height: 1.866;
      &:first-of-type {
        padding-top: 0;
      }
    }
  }
  .info {
    color: var(--white);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: center;
    p {
      margin: 0 auto;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: var(--ghostWhite);
      a {
        transition: color 0.3s var(--colorEase);
        &:hover {
          color: var(--ghostWhite);
        }
      }
      &:last-of-type {
        color: var(--secondary);
        overflow-wrap: break-word;
      }
    }
    .break {
      width: 34%;
      height: 1.2px;
      border: none;
      background-color: rgb(253 186 26 / 75%);
    }
  }
  .socials {
    text-align: center;
    .footer-icon-container {
      display: grid;
      font-size: 24px;
      grid-template-columns: 40px 40px;
      gap: 1rem 0.5rem;
      justify-items: center;
      justify-content: center;
      /* font-size: 23px;
      display: flex;
      flex-direction: column;
      gap: 0.75rem; */
    }
    .icon {
      text-decoration: none;
      /* opacity: 0.8; */
      /* padding-right: 1.1rem; */
      /* transition: opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1); */
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      &:last-of-type {
        padding-right: 0;
      }
      /* &:hover {
        opacity: 1;
      } */
      .fa-icon {
        color: var(--secondary);
        transition: color 0.3s var(--colorEase);
        &:hover {
          color: var(--ghostWhite);
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-around;
  }

  @media screen and (min-width: 435px) and (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    .socials {
      .footer-icon-container {
        grid-template-columns: 40px;
      }
    }
  }

  @media screen and (max-width: 434px) {
    .socials {
      margin-bottom: 1rem;
    }
    .footer-logo-wrapper {
      .footer-tower-container {
        svg {
          /* width: clamp(105px, 16vw, 130px); */
          width: 105px;
        }
      }
      .footer-name-container {
        svg {
          /* width: clamp(100px, 15vw, 124px); */
          width: 99px;
        }
      }
    }
  }
`;

const Section2 = styled.div`
  display: flex;
  font-size: 0.7rem;
  margin-top: auto;
  justify-content: space-between;
  letter-spacing: 0.2px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-top: 1px solid rgb(183 200 227 / 35%);
  padding-top: 1.5rem;
  .copyright {
    color: var(--white);
    line-height: 1.866;
    span,
    a {
      opacity: 0.4;
    }
  }
  .legal {
    color: rgb(255, 255, 255, 0.2);
    line-height: 1.866;
    span {
      opacity: 0.4;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const icons = [
  {
    icon: faFacebook,
    link: 'https://www.facebook.com/Pervezkhanrealestate',
    name: 'fb'
  },
  {
    icon: faInstagram,
    link: 'https://www.instagram.com/khan_capital_group/',
    name: 'ig'
  },
  {
    icon: faTwitter,
    link: 'https://twitter.com/khancapitalg',
    name: 'twitter'
  },
  // { icon: faLinkedin, link: '/', name: 'in' }
  {
    icon: faYoutube,
    link: 'https://www.youtube.com/channel/UC283jhrVfvole0RV_Ypwm5Q',
    name: 'youtube'
  }
];

const Footer = ({ path }) => {
  const scrollToSection = useRef(null);

  useEffect(() => {
    if (typeof window !== undefined) {
      gsap.registerPlugin(ScrollToPlugin);

      scrollToSection.current = (sectionID, offset) => {
        // access window obj and store function inside a ref
        gsap.to(window, {
          duration: 1.3,
          ease: 'expo.out',
          scrollTo: {
            y: sectionID,
            // will scroll an additional y pixels down from top
            offsetY: offset || 0
          }
        });
      };
    }
  }, []);

  return (
    <StyledFooter>
      <FooterContainer>
        <Section1>
          <div className="footer-logo-wrapper">
            <div className="footer-tower-container">
              <StaticTowers />
            </div>
            <div className="footer-name-container">
              <StaticName />
            </div>
          </div>
          <div className="navigation">
            <h5>Explore</h5>
            <ul>
              {scrollToIDs.map((id, i) => (
                <li key={`${i}-${id}`}>
                  <div
                    role="button"
                    className="footer-nav-item"
                    tabIndex="0"
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        navigateToIDs(
                          path,
                          scrollToSection,
                          id.to,
                          id.offset,
                          navigate
                        );
                      }
                    }}
                    onClick={() => {
                      navigateToIDs(
                        path,
                        scrollToSection,
                        id.to,
                        id.offset,
                        navigate
                      );
                    }}
                  >
                    <span>{id.name}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="info">
            <h5>Visit Us</h5>
            <p>
              3000 Marcus Avenue
              <br />
              Suite 3W6
              <br />
              Lake Success, NY 11042
            </p>
            <hr className="break" />
            <p>
              +1 (516) 382-6109 <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:pkhan@khancapitalgroup.com"
              >
                pkhan@khancapitalgroup.com
              </a>
            </p>
          </div>
          <div className="socials">
            <h5>Follow Us</h5>
            <div className="footer-icon-container">
              {icons.map(({ icon, name, link }) => (
                <a
                  href={link}
                  key={name}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon"
                >
                  <FontAwesomeIcon icon={icon} className="fa-icon" />
                </a>
              ))}
            </div>
          </div>
        </Section1>
        <Section2>
          <div className="copyright">
            <span>
              &copy; {new Date().getFullYear()} Khan Capital Group. All Rights
              Reserved. Site by{' '}
            </span>
            <a
              className="footer-nav-item"
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:jawang12@gmail.com"
            >
              James
            </a>
            <span>.</span>
          </div>
          <div className="legal">
            <span className="footer-nav-item">Terms of Use</span> |{' '}
            <span className="footer-nav-item">Privacy Policy</span> |{' '}
            <span className="footer-nav-item">Equal Housing Statement</span>
          </div>
        </Section2>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
