import React, { createContext, useState } from 'react';

// set default values for better autocomplete
export const InitialRenderContext = createContext({
  initialLoad: true,
  loadComplete: () => {
    console.log(' this gonna work');
  }
});

const InitialRenderProvider = ({ children }) => {
  const [initialLoad, setInitialLoad] = useState(true);

  // set state to false after initial load is completed
  const loadComplete = () => {
    setInitialLoad(false);
  };

  return (
    <InitialRenderContext.Provider
      value={{
        initialLoad,
        loadComplete
      }}
    >
      {children}
    </InitialRenderContext.Provider>
  );
};

export default InitialRenderProvider;
