import React from 'react';
import styled from 'styled-components';

const BubbleButton = styled.button`
  --fontColor: ${({ fontColor }) => fontColor || `var(--altLightBlue)`};
  --borderColor: ${({ borderColor }) => borderColor || `var(--buttonBlue)`};
  --fillColor: ${({ fillColor }) => fillColor || `var(--buttonBlue)`};
  --fontSize: ${({ fontSize }) => fontSize || `.825rem`};
  font-size: var(--fontSize);
  text-transform: uppercase;
  letter-spacing: 1.2px;
  position: relative;
  transition: color 0.5s, transform 0.2s, background-color 0.2s,
    border 0.35s ease-in-out;
  outline: none;
  border-radius: 3px;
  padding: 1rem 2rem;
  background-color: transparent;
  color: var(--fontColor);
  border: 1px solid var(--borderColor);
  box-shadow: none;
  overflow: hidden;
  z-index: 1;
  &:hover {
    color: rgba(255, 255, 255);
    cursor: pointer;
    border: 1px solid transparent;
  }
  &:active {
    transform: translateY(3px);
  }

  &::after {
    border-radius: 3px;
  }

  &::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    /* top: -100%;
    left: -25%;
    width: 150%;
    height: 300%; */
    top: -126px;
    /* half the element - half of the width of the circle pseudo element */
    left: calc(50% - 150px);
    width: 300px;
    height: 300px;
    background-color: var(--fillColor);
    border-color: transparent;
    border-radius: 50%;
    transform: scale(0);
    /* opacity: 0; */
    z-index: -1;
    /* transition: transform 0.4s, opacity 0.4s, background-color 0.4s ease-in-out; */
    transition: all 0.35s ease-in-out;
  }
  &:hover::after {
    /* opacity: 1; */
    /* transform-origin: center center; */
    transform: scale(1);
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.8rem;
    padding: 0.8rem 1.3rem;
    line-height: 1.4;
  }
  /* @media only screen and (max-width: 424px) {
    font-size: 0.67rem;
    padding: 0.7rem 0.9rem;
  } */
`;

const Button = ({
  children,
  handleClick,
  disabled = false,
  expanded,
  ...props
}) => {
  return (
    <BubbleButton
      onClick={e => {
        e.stopPropagation();
        handleClick();
      }}
      disabled={disabled}
      aria-expanded={expanded}
      type="button"
      {...props}
    >
      {children}
    </BubbleButton>
  );
};

export default Button;
