import React, { useMemo } from 'react';
import styled from 'styled-components';
import HamburgerMenu from '../../assets/svgs/hamburger.svg';
import { throttle } from '../../utils/throttle';

const StyledMenuButton = styled.div`
  /* margin-left: 8px; */
  /* width: 84px; */
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  z-index: 100;
  .menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;

    svg {
      width: 2.2rem;
      width: clamp(1.6rem, 3.8vw, 2.2rem);
      overflow: visible;
    }
    /* opacity: 0.8; */
    /* transition: opacity 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95); */
    /* transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1); */
    /* &:hover {
      opacity: 1;
    } */
  }
  /* fixes issue where line stroke on the X would shorten while on hover in the opened state */
  .closed {
    &:hover {
      .line3 {
        stroke-dasharray: 60 207;
      }
    }
  }
  .line {
    fill: none;
    stroke: var(--secondary);
    stroke-linecap: round;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
  }
  .line2 {
    stroke-dasharray: 60 60;
    transition: stroke-dasharray 200ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line3 {
    stroke-dasharray: 30 207;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
  }

  @media screen and (max-width: 767px) {
    /* centers button to acct for additional added width to margin-bottom to inner paddinng */
    top: calc(50% - 3px);
    transform: translate(0px, calc(-50% + 3px));
    .menu.opened .line {
      stroke: var(--ghostWhite);
      transition: stroke 500ms var(--colorEase) 300ms,
        stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .menu.closed .line {
      stroke: var(--secondary);
      transition: stroke 500ms var(--colorEase) 600ms,
        stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
`;

const MenuButton = ({ onClickHandleMenu, isOpen }) => {
  // useCallback demands an inline function and cannnot simply hand it the
  // returned function from throttle (throttle returns function)

  // useMemo is needed to prevent higher order function from reinitializing on re-renders
  // useMemo is used to store a memoized value that should only be recalculated when the dependencies change. **A function is a value.
  // useMemo invokes the inline function & the value stored is the return function from throttle. only update whenever they change
  // now thottle will be called only during initial rendering of component
  const throttleClick = useMemo(
    () => throttle(onClickHandleMenu, 2200),
    [onClickHandleMenu]
  );

  // use callback to prevent function from changing/reinstantiating everytime theres a re-render;
  // when called will invoke/fire the function returned from throttle
  // however really necessary here since the content of the function is being memoized and will be the same instance
  // const handleClick = useCallback(() => throttleClick(), [throttleClick]);

  // **i.e. cannnot use IIFE to mitigate inline function; will throw warning
  // const throttleClick = useCallback(
  //   (function () {
  //     //a new instance of throttle is also being re-called everytime component re-renders; memoization is needed
  //     return throttle(onClickHandleMenu, 2200);
  //   })(),
  //   [throttle, onClickHandleMenu]
  // );

  return (
    <StyledMenuButton>
      <button
        // user can keep clicking but nothing will fire due to throttle
        onClick={throttleClick}
        className={['menu', isOpen ? 'opened' : 'closed'].join(' ')}
        aria-label="Main Menu"
        aria-expanded={isOpen}
      >
        <HamburgerMenu />
      </button>
    </StyledMenuButton>
  );
};
export default MenuButton;
