import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --darkBlack: #191919; 
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
    --primary: #0d1e39;
    --altBlue: #091D3A;
    --secondary: #fdba1a;
    --altSecondary: rgb(249, 191, 38);
    --coral: rgba(255,111,97); 
    --lightBlue: rgb(94, 174, 245);
    --altLightBlue: rgb(183,200,227);
    --deepBlue: #2994fd;
    --buttonBlue: #5571ff;
    --ghostWhite: #F8F8FF;
    --antiqueWhite: #FAEBD7;
    --lightOrange: rgb(255, 170, 63);
    --customEase: cubic-bezier(0.165, 0.84, 0.44, 1);
     /* transition: color 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95); */
    --colorEase: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  * {
    outline: none;
    box-sizing: border-box;
  }

  body {
  ${'' /* removes white flash */}
    
    visibility: hidden; 
    background-color: var(--white);
    
    font-size: 1.5rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  #gatsby-focus-wrapper {
    overflow-x: hidden;
  }
.grecaptcha-badge {
    visibility: hidden;
}

.text-gradient-1 {
  -webkit-text-fill-color: transparent;
      background: linear-gradient(90deg, #1b31a7 0%, #ffaf39 100%);
      background-clip: text;
      -webkit-background-clip: text;
}

.text-gradient-2 {
  -webkit-text-fill-color: transparent;
      background: linear-gradient(90deg, #fdba1a 0%, #f09819 100%);
      background-clip: text;
      -webkit-background-clip: text;
}

.text-gradient-3 {
  -webkit-text-fill-color: transparent;
  
  background-image: linear-gradient( 68.3deg,  rgba(245,177,97,1) 0.4%, #ffeb3b 100.2% ); 
  ${
    '' /* background-image: linear-gradient( 68.3deg,  rgb(238, 165, 81,1) 0.4%, #ffeb3b 100.2% );  slightly lighter */
  }

    background-clip: text;
    -webkit-background-clip: text;
}


.text-gradient-investing {
  -webkit-text-fill-color: transparent;
      background: linear-gradient(to top, #ffb400 0%,hsl(30deg 100% 95%) 100%);
      background-clip: text;
      -webkit-background-clip: text;
}

  
  ${
    '' /* fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  } */
  }
  ${
    '' /* button {
    background: var(--red);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  } */
  }

  /* Scrollbar Styles 
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }
  */

  .narrow-container {
    max-width: 1000px;
    margin: 10rem auto 4rem auto;
    margin-top: clamp(
      2rem,
      10vw,
      12rem
    ); /*min, preferred, max; not supported by all browsers, will scale with width of browser */
    padding: 5px;
    /* clmap will fall back to above value if not supported */
    padding: clamp(5px, 1vw, 25px);
    border: 5px solid black;
    @media (max-width: 1100px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
    /* *** adjust for smaller screens */
    @media (max-width: 400px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export default GlobalStyles;

// .gatsby-image-wrapper img[src*=base64\\,] {
//   image-rendering: -moz-crisp-edges;
//   image-rendering: pixelated;
// }
