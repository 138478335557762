import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BathIcon from '../../assets/svgs/bath-icon.min.svg';
import BedIcon from '../../assets/svgs/bed-icon.min.svg';
import SizeIcon from '../../assets/svgs/size-icon.min.svg';
import RetailIcon from '../../assets/svgs/retail-icon.svg';
import OfficeIcon from '../../assets/svgs/office-icon.svg';
import IndustrialIcon from '../../assets/svgs/industrial-icon.svg';
import CommSizeIcon from '../../assets/svgs/comm-size-icon.svg';
import MixedUseIcon from '../../assets/svgs/mixed-use.svg';

const ShowcaseItem = styled.div`
  --residential-padding-top: ${({ isResidential }) =>
    isResidential ? '10.385px' : '5px'};
  width: ${({ width }) => width};
  background: var(--altBlue);
  margin-bottom: ${({ marginBottom }) => marginBottom || ''};
  color: var(--white);
  border-radius: 3px;
  box-shadow: 0px 0px 10px 2px rgb(98 119 235 / 70%);
  /* 0 0 17px 3px #19294e, 0 0 4px 2px #0b3e8a */
  /* 0 0 17px 3px #1f3b7d, 0 0 4px 2px #14325f */
  /* box-shadow: 0px 0px 10px 2px rgb(70 90 203 / 70%); */
  filter: drop-shadow(2px 4px 6px black);
  height: auto;
  display: flex;
  flex-direction: column;
  .image-container {
    /* transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    &:hover {
      transform: scale(0.92);
    } */
    .portfolio-image {
      transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      &:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    .gatsby-image-wrapper {
      height: clamp(192px, 34vmax, 266px);
    }
  }
  .item-2-content-container {
    text-align: center;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* fills up remaining space inside a flexbox (parent is flex)
    only needed if a word is too long and gets wrapped i.e. development | long town name */
    flex-grow: 1;
    .heading-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    h4 {
      letter-spacing: 0.3px;
      /* 22-26 280- */
      font-size: clamp(1.38rem, calc(-0.22rem + 9.09vw), 1.63rem);
      text-transform: capitalize;
    }

    h6 {
      /* 12.5-14.5 280-425 */
      font-size: clamp(0.78rem, calc(0.54rem + 1.38vw), 0.91rem);
      letter-spacing: 1.2px;
      line-height: 1.5rem;
      font-family: Poppins, sans-serif;
    }
    .icon-desc {
      /* 12.5-14px */
      font-size: clamp(0.78rem, calc(0.18rem + 3.41vw), 0.88rem);
    }
    .desc-subheading {
      opacity: 0.93;
      /* color: var(--altLightBlue); */
      text-transform: uppercase;
      color: var(--secondary);
      font-size: 0.77rem;
      height: 25px;
      letter-spacing: 1.3px;
      flex: 1;

      .card-subheading-divider {
        margin-inline: 0.5rem;
      }

      .zone {
        display: none;
      }
    }
    .price {
      letter-spacing: 1.2px;
      padding: 4px 0 10px;
      /* 20px-22 */
      font-size: clamp(1.25rem, calc(0.45rem + 4.55vw), 1.38rem);
    }
    .item-2-details {
      padding: 5px;
      padding-top: var(--residential-padding-top);
      font-size: 0.8rem;
      letter-spacing: 1px;
      line-height: 1.5rem;
      .amenities {
        display: flex;
        justify-content: space-around;
        align-items: baseline;
        /* gap: 1.3rem; */
        .amenity {
          width: 33%;
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: center;
          gap: 4px;
          position: relative;
          &:not(:last-of-type) {
            &:after {
              content: '';
              display: block;
              position: absolute;
              height: 50%;
              width: 1px;
              background-color: var(--lightBlue);
              top: 50%;
              right: 0;
              transform: translateY(-50%);
            }
          }
          .icon {
            width: 41%;
            display: flex;
            justify-content: center;
            svg {
              width: 100%;
              fill: var(--ghostWhite);
            }
            .bath-icon > path {
              stroke: var(--ghostWhite);
              stroke-width: 2px;
            }
            .bed-icon {
              path {
                stroke: var(--ghostWhite);
                stroke-width: 3px;
              }
            }
            /* .size-icon > path {
              fill: var(--ghostWhite);
            } */
          }
        }
        .amenity .desc-subheading {
          letter-spacing: 1.3px;
        }

        .amenity.amenity-commercial:not(:last-of-type) {
          &:after {
            right: -1rem;
          }
        }
      }
    }
    .card-footer {
      display: flex;
      /* padding-bottom: 0.3rem; */
      margin-top: 12px;
      justify-content: space-around;
      /* .status,
      .view-more {
        padding: 0.7rem 0;
      } */
      .status {
        .to-purchase {
          -webkit-text-fill-color: transparent;
          background: #aaffa9; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #11ffbd,
            #aaffa9
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #11ffbd,
            #aaffa9
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

          background-clip: text;
          -webkit-background-clip: text;
        }
        .to-sell {
          -webkit-text-fill-color: transparent;
          background: #c21500; /* fallback for old browsers */
          background: -webkit-linear-gradient(to right, #c21500, #ffa730);
          background: linear-gradient(to right, #f12711, #ffa730);
          background-clip: text;
          -webkit-background-clip: text;
        }
        .active-listing {
          color: springgreen;
        }
        .closed-listing {
          color: var(--coral);
        }
      }
      .view-more {
        .view-link a {
          cursor: pointer;
          /* color: var(--secondary); */
          color: #4fefe9;
          /* opacity: 0.8; */
          /* fixes flashes/flickering caused by pseudo elements  */
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          transition: color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
          &:hover {
            color: var(--white);
            /* opacity: 1; */
          }
        }
      }
    }
  }
`;

const getStatusClassName = status => {
  switch (status.toLowerCase()) {
    case 'active':
      return 'active-listing';
    case 'in contract to be sold':
      return 'to-sell';
    case 'in contract to purchase':
      return 'to-purchase';
    case 'closed':
      return 'closed-listing';
    default:
      return;
  }
};

const PortfolioList = ({ properties, ...props }) => {
  return (
    <>
      {properties?.nodes?.map(property => {
        const {
          id,
          address,
          bathrooms,
          bedrooms,
          city,
          price,
          squareFt,
          status,
          thumbnail,
          icon,
          slug,
          type,
          zone
        } = property;
        const gImage = getImage(thumbnail);
        return (
          <ShowcaseItem
            key={id}
            isResidential={type !== 'Commercial'}
            {...props}
          >
            <div className="image-container">
              <Link to={`/portfolio/${slug}`}>
                <GatsbyImage
                  image={gImage}
                  imgClassName="portfolio-image"
                  // className="porfolio-wrapper-1"
                  alt={thumbnail.description}
                />
              </Link>
            </div>
            <div className="item-2-content-container">
              <div className="heading-wrapper">
                <Link to={`/portfolio/${slug}`}>
                  <h4 style={{ display: 'inline' }}>{address}</h4>
                </Link>
                <h6 className="desc-subheading" style={{ margin: '8px 0' }}>
                  {type}
                  <span className="card-subheading-divider">/</span>
                  <span style={{ display: 'inline-block' }}> {city} </span>
                  <span className="zone">
                    <span className="card-subheading-divider">/</span>
                    {zone}
                  </span>
                </h6>
              </div>
              <div>
                <h5 className="price">{price}</h5>
                <div className="item-2-details">
                  {/* handle commercial properties format and icons */}
                  {type === 'Commercial' ? (
                    <div
                      className="amenities"
                      style={{ justifyContent: 'center', gap: '2rem' }}
                    >
                      <div className="amenity amenity-commercial">
                        <div className="icon">
                          {icon === 'Office' ? (
                            <OfficeIcon />
                          ) : icon === 'Retail' ? (
                            <RetailIcon />
                          ) : icon === 'Industrial' ? (
                            <IndustrialIcon />
                          ) : (
                            <MixedUseIcon />
                          )}
                        </div>
                        <h6
                          className="desc-subheading"
                          style={{ textTransform: 'none' }}
                        >
                          <span className="icon-desc">{icon}</span>
                        </h6>
                      </div>
                      <div className="amenity">
                        <div className="icon">
                          <CommSizeIcon />
                        </div>
                        <h6 className="desc-subheading">
                          <span className="icon-desc">{squareFt} FT&sup2;</span>
                        </h6>
                      </div>
                    </div>
                  ) : (
                    <div className="amenities">
                      {/* if not commercial handle restorations */}
                      <div className="amenity">
                        <div className="icon">
                          <BedIcon />
                        </div>
                        <h6 className="desc-subheading">
                          <span className="icon-desc">{bedrooms} BD</span>
                        </h6>
                      </div>
                      <div className="amenity">
                        <div className="icon">
                          <BathIcon />
                        </div>
                        <h6 className="desc-subheading">
                          <span className="icon-desc">{bathrooms} BA</span>
                        </h6>
                      </div>
                      <div className="amenity">
                        <div className="icon">
                          <SizeIcon />
                        </div>
                        <h6 className="desc-subheading">
                          <span className="icon-desc">{squareFt} FT&sup2;</span>
                        </h6>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-footer">
                <div className="status">
                  <h6 className={getStatusClassName(status)}>{status}</h6>
                </div>
                <div className="view-more">
                  <h6 className="view-link">
                    <Link to={`/portfolio/${slug}`}>
                      View <span>&#10132;</span>
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
          </ShowcaseItem>
        );
      })}
    </>
  );
};

export default PortfolioList;
