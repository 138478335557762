import { useLayoutEffect, useRef } from 'react';

export const useLockBodyScroll = isActive => {
  const originalOverflowValue = useRef(null);
  useLayoutEffect(() => {
    if (typeof window !== undefined) {
      //get original value of body overflow
      // originalOverflowValue.current = window.getComputedStyle(
      //   document.body
      // ).overflow;
      originalOverflowValue.current = document.body.style.overflow;
      //prevent scrolling on mount
      if (isActive) {
        document.body.style.overflow = 'hidden';
      }
    }
    //re-enable scrolling when component unmounts
    return () =>
      (document.body.style.overflow = originalOverflowValue.current || '');
  }, [isActive]);
};
