export const throttle = (func, wait) => {
  // console.log('throttle init');
  // closure
  let isDisabled;
  return (...args) => {
    // console.log('inside');
    // only invoke function after wait time
    if (!isDisabled) {
      isDisabled = true;
      func(...args);
      setTimeout(() => {
        isDisabled = false;
      }, wait);
    }
  };
};
