import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import AnimatedTowers from '../../assets/svgs/kcg_animated-towers.svg';
import AnimatedName from '../../assets/svgs/kcg_animated-name.svg';
import { gsap } from 'gsap';
import { InitialRenderContext } from '../../utils/InitialRenderContext';

// import svg as a react component

const StyledLogoReveal = styled.div`
  height: 100%;
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  z-index: 200;
  .overlay-top {
    position: absolute;
    height: 100%;
    width: 33.333vw;
    background: var(--primary);
    /* the transition below makes sure background color fades from primary to black */
    transition: background-color 500ms ease-out;
    /* if set to top: 0 and we animate height to 0 animation will start from bottom and end at 
    top. notice how bottom is not written*/
    top: 0;
    left: 0;
    right: 0;
    &:nth-child(2) {
      left: 33.333%;
    }
    &:nth-child(3) {
      left: 66.66%;
    }
  }
  /* .towers-container { */
  .logo-towers {
    width: 15.5%;
    z-index: inherit;
    max-width: 161px;
    min-width: 124px;
    /* handles FOUC */
    /* visibility: hidden; */
    /* mix-blend-mode: difference; */
  }

  .logo-name {
    width: 14%;
    max-width: 147px;
    min-width: 114px;
    z-index: inherit;
    margin-top: -1px;
    /* mix-blend-mode makes it look blurry */
    /* mix-blend-mode: difference; */
  }
  .revealed-title,
  .animated-rooftop,
  .animated-window,
  .animated-tower,
  .revealed-subtitle {
    visibility: hidden;
    /* will-change: opacity; */
  }
`;

const LogoReveal = () => {
  // set up context to track initial load status throughout various components. will also be used to delay video playback
  const { initialLoad, loadComplete } = useContext(InitialRenderContext);

  useEffect(() => {
    //  prevents flashing upon reloads.
    gsap.set('body', {
      visibility: 'visible',
      /* ensures that page is not scrollable while hero reveal is in motion */
      overflowY: 'hidden'
    });

    // ensures that gsap timeline is only ran once
    if (initialLoad) {
      const tl = gsap.timeline({
        defaults: { duration: 1 },
        onComplete: () => {
          // this function is called when the timeline completes
          // remove logoReveal from DOM after initial load. Function sets initial load context state to false
          loadComplete();
          // console.log('initial load done', initialLoad);
        }
      });
      // fade in rooftops
      tl.from('.animated-rooftop', {
        duration: 0.5,
        // will automatically set visibility to inherit afterwards; handles FOUC
        autoAlpha: 0,
        delay: 0.1
        // scale: 0.1,
      })
        .from(
          ['.animated-window', '.animated-tower'],
          {
            // scales up windows and towers
            // delay: 0.1,
            duration: 1.2,
            scaleY: 0,
            ease: 'circ.inOut',
            autoAlpha: 0,
            transformOrigin: '50% 80%',
            // svgOrigin: '30 80',
            stagger: {
              amount: 0.5,
              from: 'edges'
            }
            // fill: 'none',
            // animationFillMode: 'forwards'
          }
          // '-=.02'
        )
        .from(
          // fade and scale it in from the left on the x axis
          ['.revealed-title', '.revealed-subtitle'],
          {
            scaleX: 0,
            // will automatically set visibility to inherit afterwards; handles FOUC
            autoAlpha: 0,
            duration: 0.8
          },
          '>-.1'
        )
        // .set is essentially a .to but with a more intuitive name and with a zero duration as default
        .to('.overlay-top', {
          background: 'var(--darkBlack)',
          duration: 0.3
        })
        .to(
          '.overlay-top',
          {
            // open curtains
            duration: 0.6,
            ease: 'expo.inOut',
            //.seconds between each animation
            stagger: 0.2,
            // height: 0
            y: '-100vh'
            // delay: 1
          },
          '+=.6'
        )
        .to(
          ['.logo-towers', '.logo-name'],
          {
            // fade out logo
            autoAlpha: 0,
            duration: 0.7
            // run xDelay amount of seconds before it is suppose to
            // delay: -1
          },
          '-=1'
        )
        .set('body', {
          // makes body scrollable
          // clears anything in the element's style property
          clearProps: 'overflowY',
          backgroundColor: 'none'
        });
    }
  }, [initialLoad, loadComplete]);
  return (
    <StyledLogoReveal className="logo-reveal-overlay">
      <div className="overlay-top"></div>
      <div className="overlay-top"></div>
      <div className="overlay-top"></div>
      <AnimatedTowers className="logo-towers" />
      <AnimatedName className="logo-name" />
    </StyledLogoReveal>
  );
};

export default LogoReveal;
