import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Wreath from '../../assets/svgs/wreath2.svg';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CountUp } from 'use-count-up';

const StyledAboutUs = styled.div`
  /* IF PIN IS JUMPY: setting height as a workaround so scrolltrigger can correctly detect a height on initial page load and FOUT (flash of unstyled text)
 doesnt delay font loading which offsets the scrolltrigger pin spacing calculation in the next section  */
  /* height: 100vh */

  /* padding: clamp(5rem, 10vw, 8rem) clamp(5px, 6.5vw, 6rem); */
  padding-top: clamp(5rem, 10vw, 8rem);
  padding-inline: clamp(5px, 1.5vw, 3rem);
  position: relative;
  background-color: #f9f7f7;
  /* creamy color */
  /* background-color: #FFFCFA; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: clamp(2.4rem, 8.5vw, 3.4rem); */
  .box1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: clamp(5rem, 10vw, 8rem);
    .heading {
      .title {
        font-family: Poppins, sans-serif;
        text-transform: uppercase;
        letter-spacing: 1.3px;
        font-weight: 600;
        text-align: center;
        /* 14.5-16 300-425 */
        font-size: clamp(0.91rem, calc(0.68rem + 1.2vw), 1rem);
      }
      .circle {
        color: var(--lightBlue);
        display: block;
        text-align: center;
        &:first-of-type {
          margin-top: 0.3rem;
          margin-bottom: -0.6rem;
          color: var(--secondary);
        }
        &:last-of-type {
          color: var(--lightBlue);
        }
      }
    }
    .subtitle {
      padding-top: 1rem;
      margin: 0;
      text-align: center;
      position: relative;
      font-family: Calluna, auto;
      line-height: 130%;
      font-size: 3.3rem;
      /* 42px-53 320-1140vw */
      font-size: clamp(2.63rem, calc(2.36rem + 1.34vw), 3.31rem);
      font-weight: 600;
      /* background: linear-gradient(to left, #d7b88f, #0e2c40);
      background-clip: text;
      -webkit-background-clip: text; */
    }
    .description {
      width: min(892px, 92%);
      text-align: center;
      article:nth-child(1) {
        margin: auto;
        width: min(758px, 93%);
      }
      article:nth-child(2) {
        margin: auto;
        width: 95%;
      }
      .initiative {
        /* same as rgb(46,46,46); */
        color: var(--black);
        /* 20px - 27.2px */
        font-size: clamp(1.25rem, calc(1.08rem + 0.85vw), 1.69rem);
        line-height: clamp(33px, 6.5vw, 40px);
        font-family: 'Calluna';
        margin-top: 0.75rem;
      }
      .info {
        color: rgba(46, 46, 46, 0.7);
        margin: 0;
        font-family: Poppins;
        /* 14px-15 280-410 */
        font-size: clamp(0.88rem, calc(0.74rem + 0.77vw), 0.94rem);
      }
    }
  }
  .box-2-wrapper {
    width: 100vw;
    padding-block: 7rem 9rem;
    /* background: var(--altBlue); */
    background: linear-gradient(0deg, #0d1e39 27%, #132e60 100%),
      -webkit-linear-gradient(0deg, #0d1e39 27%, #132e60 100%);
    display: flex;
    align-items: center;
  }

  .box2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    justify-items: center;
    gap: 6rem 1.5rem;
    width: min(1160px, 94%);
    margin: 0 auto;
    .category {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      gap: 3px;
      h2 {
        display: inline;
        font-size: 2.4rem;
        color: var(--white);
        font-weight: 600;
        letter-spacing: -0.2px;
        /* -webkit-text-stroke: 1.5px #1d437e;
        -webkit-text-fill-color: #1d437e; */
      }
      .description-title {
        /* color: var(--darkBlack); */
        color: var(--ghostWhite);
        font-size: 15px;
        margin: 0;
        font-family: Calluna, sans-serif;
        text-align: center;
        letter-spacing: 0.4px;
        line-height: 19px;
      }
      .wreath {
        width: 11.2rem;
        top: -2px;
        position: absolute;
        /* fill: lightslategray; */
        /* fill: #77889975;
        fill: #97a7b975; */
        /* goldenrod */
        fill: #daa520;
      }
    }
    @media screen and (min-width: 619px) and (max-width: 895px) {
      grid-template-columns: 2fr 2fr;
      width: 80%;
    }
  }
  .floating {
    position: relative;

    transform: translateY(0);
    transition: transform 1s;
  }
  .floating:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -30px;
    left: 50%;
    height: 12px;
    width: 52px;
    /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4); */
    box-shadow: 0px 0px 15px 0px rgb(218 165 32 / 50%);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    transform: translate(-50%, 0);
    transition: transform 1s;
  }

  /* Hover States */
  .floating:hover {
    transform: translateY(-25px);
    transition: transform 1s;
  }
  .floating:hover:after {
    transform: translate(-50%, 25px) scale(0.75);
    transition: transform 1s;
  }
`;

const AboutUs = () => {
  const [isCounting, setIsCounting] = useState(false);
  let accolades = useRef(null);
  useEffect(() => {
    if (typeof window !== undefined) {
      gsap.registerPlugin(ScrollTrigger);

      ScrollTrigger.create({
        trigger: accolades.current,
        start: 'top-=30 bottom',
        end: 'bottom bottom',
        once: true,
        fastScrollEnd: 3000,
        onEnter: () => {
          setIsCounting(true);
        }
      });
    }
  }, []);
  return (
    <StyledAboutUs>
      <div className="box1">
        <div className="heading">
          <h6 className="title">A Refined Vision</h6>
          <span className="circle">&#8226;</span>
          <span className="circle">&#8226;</span>
        </div>
        <h1 className="subtitle text-gradient-1">Pushing the boundaries.</h1>
        <div className="description">
          <article>
            <p className="initiative">
              Khan Capital Group is a privately-held and fully integrated real
              estate development firm that focuses on acquiring value-add
              properties in both the residential and commercial space.
            </p>
          </article>
          <article>
            <p className="info">
              Our primary focus is to target 1-4 family residential homes in the
              Greater New York Area. We seek homes that have been neglected,
              abandoned, or require full demolition. Our target strategy is to
              rebuild these homes in accordance with the latest building codes
              set by local ordinances.
            </p>
            <br />
            <p className="info">
              We have the ability to transact and close quicker than most of our
              competitors due to our infrastructure that has been carefully
              assembled from the time of the company's inception. If you feel
              you have a property in mind that suits our criteria, contact us
              today!
            </p>
          </article>
          {/* <p>
            At Khan Capital Group, our focus isn't to settle with standard, we
            strive to set it. We believe being different is the key for
            innovation. We are unparalleled in our commitment to excellence and
            integrity. We procure a vast variety of properties including access
            to the most exclusive New York and Long Island communities. 'Going
            with the flow' isn't our jam. We constantly question the industry
            norms and find unique ways for innovation. Join us on our mission to
            set a higher standard, to raisee the bar and redefine the New York
            real estate landscape.
          </p> */}
        </div>
      </div>
      <div className="box-2-wrapper">
        <div className="box2" ref={accolades}>
          <div className="category floating">
            <h2 className="text-gradient-2" style={{ paddingTop: '5px' }}>
              <CountUp isCounting={isCounting} end={128} duration={1.5} />
            </h2>
            <p className="description-title" style={{ marginTop: '-4px' }}>
              Properties <br /> Procured
            </p>
            <div className="wreath">
              <Wreath />
            </div>
          </div>
          <div className="category floating">
            <h2 className="text-gradient-2">
              <CountUp isCounting={isCounting} end={4.5} duration={1.5} />
              <span>x</span>
            </h2>
            <p className="description-title">
              Value <br /> Added
            </p>
            <div className="wreath">
              <Wreath />
            </div>
          </div>
          <div className="category floating">
            <h2 className="text-gradient-2" style={{ paddingTop: '5px' }}>
              <span style={{ paddingRight: '2px' }}>&#62;</span>
              <CountUp isCounting={isCounting} end={68} duration={1.5} />
            </h2>
            <p className="description-title" style={{ marginTop: '-4px' }}>
              Communities <br /> Served
            </p>
            <div className="wreath">
              <Wreath />
            </div>
          </div>
          <div className="category floating">
            <h2 className="text-gradient-2" style={{ paddingLeft: '2%' }}>
              <CountUp isCounting={isCounting} end={300} duration={1.4} />
              <span>+</span>
            </h2>
            <p className="description-title" style={{ paddingLeft: '2%' }}>
              Meaningful <br /> Relationships
            </p>
            <div className="wreath">
              <Wreath />
            </div>
          </div>
        </div>
      </div>
    </StyledAboutUs>
  );
};

export default AboutUs;
