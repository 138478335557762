import React, { useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLockBodyScroll } from '../../utils/useLockBodyScroll';
import { scrollToIDs, throttleNavClick } from '../../utils/scrollToIDs';
import Wheel from '../../assets/svgs/wheel.svg';
import StaticTowers from '../../assets/svgs/kcg-static-towers.svg';
import StaticName from '../../assets/svgs/kcg-static-name.svg';

const StyledHamburger = styled.div`
  .animation-box {
    overflow: hidden;
    span {
      display: block;
      transform: translate(0, 100%);
      opacity: 0;
    }
    .infoX {
      transform: translate(100%, 0);
    }
  }
  .info-group {
    width: 66vw;
    height: 100%;
    background: var(--primary);
    padding-left: clamp(3rem, 12vw, 10rem);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 16;
    transform: translate(-66vw, 0);

    /* @media screen and (max-height: 599px) {
      padding-top: max(7rem, 35vh);
      display: block;
    } */

    .info-logo {
      opacity: 0;
      /* visibility: hidden; */
      opacity: 0;
      /* max-width: 107px;
      min-width: 82px; */
      width: clamp(82px, 8.3vw, 107px);
      position: absolute;
      right: 74px;
      top: 3%;
      border-left: 2.5px dashed rgb(253 186 26 / 70%);
      border-bottom: 2.5px dashed rgb(253 186 26 / 80%);
      padding-left: 3.5rem;
      padding-bottom: 2.5rem;
      box-sizing: content-box;
      border-radius: 50%;
      /* left: 80%; */
      /* transform: translate(-50%); */
      /* mix-blend-mode: difference; */
      .info-name-container {
        margin-top: -2px;
        margin-left: auto;
        display: flex;
        justify-content: center;
        svg {
          width: 96%;
        }
      }
    }
    .info-content {
      color: var(--ghostWhite);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);

      h3 {
        padding-bottom: 1rem;
        letter-spacing: 0.1px;
        font-family: 'Poppins';
        font-size: 2rem;
        /* 21px - 32px */
        font-size: clamp(1.31rem, calc(-0.11rem + 2.96vw), 2rem);
      }
      p {
        margin: 0 auto;
        font-size: 18px;
        line-height: 160%;
        /* 14px - 18px at 768 min width */
        font-size: clamp(0.88rem, calc(0.36rem + 1.08vw), 1.13rem);
        a {
          transition: color 0.3s var(--colorEase);
          &:hover {
            color: var(--lightBlue);
          }
        }
      }
      .info-subtitle {
        margin-bottom: min(1.3vw, 1rem);
        text-transform: uppercase;
        font-family: 'Poppins';
        letter-spacing: 1px;
        font-size: 14px;
        /* 12.5 - 14px */
        font-size: clamp(0.78rem, calc(0.59rem + 0.4vw), 0.88rem);
        color: var(--lightOrange);
        font-weight: 600;
      }
      .info-address {
        padding: min(50px, 5vh) 0;
        margin-top: -16px;
        color: var(--ghostWhite);
      }
      .info-contact {
        color: var(--secondary);
      }
      .break {
        width: 48%;
        height: 1.5px;
        border: none;
        background-color: var(--secondary);
      }
    }

    .circle-wrapper {
      position: absolute;
      /* 290px is half of the circle size */
      top: calc(50% + 290px);
      right: calc(14% - 324px);
      transform: translate(0, -50%);
      overflow: hidden;
      display: none;
      .circle-container {
        width: calc(530px + (600 - 530) * ((100vw — 500px) / (1920 – 500)));
        height: calc(530px + (630 - 530) * ((100vh — 500px) / (1920 – 500)));
        width: min(580px, 100vh);
        height: min(580px, 100vh);
        /* transform: scale(0.7); */
        .wheel {
          width: 100%;
          animation: rotate-wheel 30s linear 0s infinite forwards;
          animation-play-state: paused;
          will-change: transform;
          opacity: 0;
          /* visibility: hidden; */
          .wheel-text {
            /* opacity: 0.3; */
            opacity: 0.65;
            /* fill: var(--deepBlue); */
            fill: var(--secondary);
            letter-spacing: 4.5px;
            font-size: 22.8px;
            font-family: Poppins, sans-serif;
          }
        }

        @keyframes rotate-wheel {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .follow-us {
      position: absolute;
      color: white;
      right: 0;
      /* 70px represents the length of socials-title */
      top: calc(50% - 70px);
      max-width: 45px;
      z-index: 10;
      .follow-us-title {
        /* display should be set too none foor small screens  */
        text-transform: uppercase;
        font-family: Poppins, sans-serif;
        letter-spacing: 3px;
        font-weight: 600;
        font-size: 16px;
        /* 14-16px */
        font-size: clamp(0.88rem, calc(0.49rem + 0.81vw), 1.06rem);
        color: var(--lightBlue);
        transform: rotate(90deg);
        transform-origin: 0 0;
        margin-left: 17px;
        margin-bottom: 8rem;
        opacity: 0.9;
        width: 140px;
      }
    }

    .socials-content {
      position: absolute;
      top: calc(50% + 140px);
      right: 1.5rem;
      display: block;
      .socials-list {
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
        gap: 0.8rem;
        /* margin-top: clamp(2rem, 10vh, 7rem); */
        display: inline-flex;
        flex-direction: column;
        .icon {
          text-decoration: none;
          /* padding-right: 1.6rem; */
          /* &:last-of-type {
            padding-right: 0;
          } */
          .fa-icon {
            color: var(--lightBlue);
            opacity: 0.9;
            font-size: 22px;
            /* 18-22px */
            /* font-size: clamp(1.13rem, calc(0.61rem + 1.08vw), 1.38rem); */
            /* 19-22px */
            font-size: clamp(1.19rem, calc(1.11rem + 0.37vw), 1.38rem);
            transition: color 0.3s var(--colorEase);
            &:hover {
              color: var(--ghostWhite);
            }
          }
        }
      }
    }
  }

  .info-group-wrapper {
    height: max(590px, 100vh);
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .menu-group {
    height: 100%;
    width: 34%;
    background: var(--secondary);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 14;
    padding: 4rem 0;
    overflow: scroll;
    transform: translate(0, -100vh);
    .menu-content {
      position: relative;
      z-index: 2;
      nav {
        display: block;
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: start;
          justify-content: center;
          flex-direction: column;
          gap: 1.2rem;
          li {
            list-style: none;
            /* font-size: calc(12px + 24 * (100vw - 650px) / 950); */
            cursor: pointer;
            overflow: hidden;
            /* padding-bottom: 3px; */
            .scroll-to-section {
              letter-spacing: 0.4px;
              text-decoration: none;
              font-family: 'Poppins';
              font-size: 40px;
              /* dynamic font min width 768 at 24px to 40px max  */
              font-size: clamp(1.5rem, calc(-0.56rem + 4.3vw), 2.5rem);
              font-weight: 700;
              position: relative;
              color: var(--primary);
              position: relative;
              padding-bottom: 3px;
              transform: translate(0, 49px);
              opacity: 0;
              overflow: hidden;
              /* fill color from left on hover */
              span {
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;
                padding-bottom: 3px;
                transform: translateX(-100%);
                transition: transform 0.4s var(--customEase) 0s;
                &::before {
                  content: attr(data-text);
                  color: var(--ghostWhite);
                  display: inline-block;
                  transform: translateX(100%);
                  transition: transform 0.4s var(--customEase) 0s;
                  /* border-right: 2px solid var(--ghostWhite); */
                }
              }
              &:hover span {
                transform: translateX(0);
                &::before {
                  transform: translateX(0);
                }
              }
              /* &:any-link {
                color: var(--ghostWhite);
              } */
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .circle-wrapper {
      display: none !important;
    }
    .menu-group {
      width: 100%;
      overflow: scroll;
      .menu-content ul {
        align-items: center !important;
        gap: 1.2rem !important;
      }
    }
    .info-group {
      .info-group-wrapper {
        overflow: unset;
        .socials-content {
          top: calc(50% + 140px);
          right: calc(-50vw);
          transform: translate(calc(50%), 0px);
          display: none;
          .socials-list {
            flex-direction: row;
            gap: 1.5rem;
            margin-top: min(5rem, 8vh);
            margin-bottom: 1rem;
            .icon-box {
              transform: translate(0px, 49px);
            }
            .fa-icon {
              color: var(--primary);
            }
          }
        }
      }
    }
  }

  @media screen and (max-height: 600px) and (min-width: 768px) {
    .info-group {
      overflow-y: scroll;
      overflow-x: hidden;
      .circle-wrapper {
        top: calc(50% + 245px);
        right: calc(14% - 285px);
        .circle-container {
          width: 480px;
          height: 480px;
        }
      }
    }
  }
`;

const icons = [
  {
    icon: faFacebook,
    link: 'https://www.facebook.com/Pervezkhanrealestate',
    name: 'fb'
  },
  {
    icon: faInstagram,
    link: 'https://www.instagram.com/khan_capital_group/',
    name: 'ig'
  },
  {
    icon: faTwitter,
    link: 'https://twitter.com/khancapitalg',
    name: 'twitter'
  },
  // { icon: faLinkedin, link: '/', name: 'in' }
  {
    icon: faYoutube,
    link: 'https://www.youtube.com/channel/UC283jhrVfvole0RV_Ypwm5Q',
    name: 'youtube'
  }
];

// currying function used for addEventListener handler. needed so we can pass arguments to the handler
// handles responsive edge case that resets info section when resizing menu above and below 768px
const resetInfoGroup = (
  infoWrapper,
  subtitle,
  logo,
  title,
  address,
  contact,
  socials
) => {
  return function (event) {
    // console.log('fire', event, infoWrapper);
    // if matchMedia event is below 768px and we resize from above that vp, reset the info-group section
    if (event.matches) {
      gsap.set([...address, ...contact], {
        y: 28
      });

      gsap.set(title, {
        y: 15
      });

      gsap.set(socials[0], {
        y: 18
      });

      gsap.set(subtitle, {
        x: 320
      });

      gsap.set(infoWrapper, {
        x: '-66vw'
      });

      gsap.set([subtitle, title, ...address, socials[0], ...contact], {
        autoAlpha: 0
      });

      gsap.set([logo, '.wheel'], {
        opacity: 0
      });

      gsap.set(['.wheel'], {
        animationPlayState: 'paused'
      });
    } else {
      // if in menu, and scrolling from narrow to wide, we auto expand the info-group section
      gsap.set([infoWrapper, subtitle], {
        x: 0
      });

      gsap.set([title, ...address, ...contact, ...socials], {
        y: 0
      });

      gsap.set([subtitle, title, ...address, ...contact, ...socials], {
        autoAlpha: 1
      });

      gsap.set([logo, '.wheel'], {
        opacity: 1
      });

      gsap.set(['.wheel'], {
        animationPlayState: 'running'
      });
    }
  };
};

// approach using function.bind()
// const handleChange = (infoWrapper, subtitle, setYGroup, event) => {
//   console.log('fire', event, subtitle, infoWrapper);
//   if (event.matches) {
//   } else {
//   }
// };

const Hamburger = ({ isOpen, initialRender, closeMenu, path, isFixed }) => {
  // vars for animated dom nodes
  let infoSection = useRef(null);
  let menuSection = useRef(null);
  let logo = useRef(null);
  let linkRefs = useRef([]);
  let titleSection = useRef([]);
  let addressSection = useRef([]);
  let contactSection = useRef([]);
  let socialsSection = useRef([]);
  const scrollToSection = useRef(null);

  useLockBodyScroll(isOpen);

  useEffect(() => {
    if (typeof window === undefined) return;

    const device = window.matchMedia('(max-width: 767px)');
    let curriedHandler;

    // keep closed
    // this will move links out of position. We reset upon opening the menu (see if else case).
    if (!isOpen && !initialRender.current) {
      const tlClose = gsap.timeline();
      // alt closing animation for smaller screens
      if (device.matches) {
        tlClose
          // translate downn links
          .to([...linkRefs.current, ...socialsSection.current], {
            autoAlpha: 0,
            ease: 'cubic-bezier(.895,.03,.685,.22)',
            // scale: 0,
            y: 49,
            duration: 0.8,
            stagger: {
              amount: 0.3
            }
          })
          // close right sectionn by sliding up
          .to(
            menuSection,
            {
              y: '-100vh',
              duration: 1,
              ease: 'power3.inOut'
            },
            // start closing at same time as infoSection
            '<.24'
          )
          .to(
            '#dynamicInnerHeader',
            {
              autoAlpha: 1,
              duration: 0.3,
              ease: 'cubic-bezier(.895,.03,.685,.22)'
            },
            '>-.23'
          );
        // set outside of timeline so timing doesnt get interrupted
        gsap.set('.socials-content', {
          display: 'none',
          delay: 2
        });
      } else {
        // close hamburger menu animation for large screens
        tlClose
          // fade out text wheel
          .to('.wheel', {
            opacity: 0,
            duration: 0.3,
            // fading ease
            ease: 'cubic-bezier(.895,.03,.685,.22)',
            animationPlayState: 'paused'
          })
          .to(
            logo,
            {
              opacity: 0,
              duration: 0.3,
              // fading ease
              ease: 'cubic-bezier(.895,.03,.685,.22)'
            },
            '<'
          )
          // translate downn links
          .to(
            linkRefs.current,
            {
              autoAlpha: 0,
              ease: 'cubic-bezier(.895,.03,.685,.22)',
              // scale: 0,
              y: 49,
              duration: 0.7,
              stagger: {
                amount: 0.27
              }
            },
            '<.23'
          )
          .to(
            titleSection.current[0],
            {
              autoAlpha: 0,
              ease: 'cubic-bezier(.895,.03,.685,.22)',
              y: 15,
              duration: 0.6
            },
            '<'
          )
          .to(
            [...addressSection.current, ...contactSection.current],
            {
              autoAlpha: 0,
              ease: 'cubic-bezier(.895,.03,.685,.22)',
              y: 28,
              duration: 0.6
            },
            '<'
          )
          .to(
            // follow us section
            socialsSection.current[0],
            {
              autoAlpha: 0,
              ease: 'cubic-bezier(.895,.03,.685,.22)',
              y: 18,
              duration: 0.5
            },
            '<.1'
          )
          .to(
            // icons
            socialsSection.current.slice(1),
            {
              autoAlpha: 0,
              ease: 'cubic-bezier(.895,.03,.685,.22)',
              y: 28,
              duration: 0.4,
              stagger: {
                amount: 0.23
              }
            },
            '<.08'
          )
          // slide lets build together to right
          .to(
            titleSection.current[1],
            {
              autoAlpha: 0,
              ease: 'power3.inOut',
              x: 320,
              duration: 0.6
            },
            '>-.42'
          )
          // close left section by sliding left
          .to(
            infoSection,
            {
              // keyframes: {
              //   '0%': { x: 0 },
              //   '100%': { display: 'none', x: '-100%' }
              // },
              x: '-66vw',
              duration: 1,
              ease: 'power4.inOut'
            },
            // before previous ends
            '-=.6'
          )
          // close right sectionn by sliding up
          .to(
            menuSection,
            {
              y: '-100vh',
              duration: 1,
              ease: 'power4.inOut'
            },
            // start closing at same time as infoSection
            '<'
          )
          .to(
            '#dynamicInnerHeader',
            {
              autoAlpha: 1,
              duration: 0.3,
              ease: 'cubic-bezier(.895,.03,.685,.22)'
            },
            '>-.23'
          );
        // set outside of timeline so timing doesnt get interrupted
        gsap.set('.circle-wrapper', {
          display: 'none',
          delay: 0.5
        });
      }
      // only fire if header is in its fixed/scrolled state
      if (isFixed) {
        tlClose.set(
          '#dynamicHeader',
          {
            // removes specified inline styled element by gsap
            clearProps: 'background',
            '--shadowOpacity': 1
          },
          '<'
        );
      }
    } else if (isOpen) {
      // open menu. the following animations will be triggered upon opening the menu
      const tlOpen = gsap.timeline();

      // i.e. using function.bind
      // curriedHandler = handleChange.bind(null, ...arguments);

      // assign returned curried function to a named variable in order to remove listener on unmount
      curriedHandler = resetInfoGroup(
        infoSection,
        titleSection.current[1],
        logo,
        titleSection.current[0],
        addressSection.current,
        contactSection.current,
        socialsSection.current
      );
      // only way to pass function with arguments. listen to when width passes threshold
      device.addEventListener('change', curriedHandler);

      if (isFixed) {
        // hide the fixed wrapper/header
        tlOpen.to('#dynamicHeader', {
          background: 'transparent',
          '--shadowOpacity': 0,
          ease: 'var(--customEase)',
          duration: 0.27
        });
      }

      if (device.matches) {
        // set outside of timeline so timing doesnt get interrupted
        gsap.set('.socials-content', {
          display: 'block'
        });
        tlOpen
          // fade out header logo and header links while keeping menu button
          .to(
            '#dynamicInnerHeader',
            {
              autoAlpha: 0,
              duration: 0.3,
              ease: 'cubic-bezier(.895,.03,.685,.22)'
            },
            '<'
          )
          // reveal downward
          .to(
            menuSection,
            {
              y: 0,
              duration: 1.1,
              ease: 'power4.inOut'
            },
            '<.1'
          )
          // reveal up and stagger links and info at the same time
          .to(
            [...linkRefs.current, ...socialsSection.current],
            {
              autoAlpha: 1,
              y: 0,
              duration: 0.7,
              ease: 'cubic-bezier(.895,.03,.685,.22)',
              // scale: 1,
              stagger: {
                amount: 0.27
                // amount: 0.35
                // each: 0.08
              }
            },
            '>-.43'
          );
      } else {
        gsap.set(['.circle-wrapper', '.socials-content'], {
          display: 'block'
        });
        // slide to right
        tlOpen
          // fade out header logo and header links while keeping menu button
          .to(
            '#dynamicInnerHeader',
            {
              autoAlpha: 0,
              duration: 0.3,
              ease: 'cubic-bezier(.895,.03,.685,.22)'
            },
            '<'
          )
          .to(
            infoSection,
            {
              // keyframes: {
              //   '0%': { display: 'flex', x: '-100%' },
              //   '100%': { x: 0 }
              // },
              x: 0,
              duration: 1.3,
              ease: 'power4.inOut'
            },
            '>-.25'
          )
          // reveal downward
          .to(
            menuSection,
            {
              y: 0,
              duration: 1.3,
              ease: 'power4.inOut'
            },
            '<'
          )
          .to(
            titleSection.current[1],
            {
              autoAlpha: 1,
              ease: 'expo.out',
              x: 0,
              duration: 0.8
            },
            '>-.53'
          )
          .to(
            [
              titleSection.current[0],
              ...addressSection.current,
              ...contactSection.current,
              ...socialsSection.current
            ],
            {
              autoAlpha: 1,
              y: 0,
              duration: 0.6,
              ease: 'cubic-bezier(.895,.03,.685,.22)'
            },
            '>-.35'
          )
          // reveal up and stagger links and info at the same time
          .to(
            linkRefs.current,
            {
              autoAlpha: 1,
              y: 0,
              duration: 0.6,
              ease: 'cubic-bezier(.895,.03,.685,.22)',
              stagger: {
                amount: 0.27
              }
            },
            '<-.09'
          )
          // fade in wheal
          .to(
            '.wheel',
            {
              opacity: 1,
              animationPlayState: 'running',
              duration: 0.8,
              // fading ease
              ease: 'cubic-bezier(.895,.03,.685,.22)'
            },
            '>-.29'
          )
          .to(
            logo,
            {
              opacity: 1,
              duration: 0.8,
              // fading ease
              ease: 'cubic-bezier(.895,.03,.685,.22)'
            },
            '<'
          );
      }
    }
    return () => {
      device.removeEventListener('change', curriedHandler);
    };
  }, [isOpen, initialRender, isFixed]);

  // animation functions
  // const staggerLinks = (...nodes) => {
  //   gsap.from(nodes, {
  //     autoAlpha: 0,
  //     scale: 0.2,
  //     delay: 0.3,
  //     y: 100,
  //     ease: 'cubic-bezier(.895,.03,.685,.22)',
  //     stagger: {
  //       amount: 0.2
  //     }
  //   });
  // };

  useEffect(() => {
    if (typeof window !== undefined) {
      gsap.registerPlugin(ScrollToPlugin);
      // store scrollTo onCLick function in a useRef
      scrollToSection.current = (sectionID, offset) => {
        gsap.to(window, {
          duration: 0.3,
          ease: 'expo.out',
          scrollTo: {
            y: sectionID,
            // will scroll an additional y pixels down from top
            offsetY: offset || 0
          },
          onComplete: () => {
            closeMenu();
          }
        });
      };
    }
  }, [closeMenu]);

  return (
    <StyledHamburger>
      <section className="info-group" ref={el => (infoSection = el)}>
        <div className="info-group-wrapper">
          <div className="info-logo" ref={el => (logo = el)}>
            <div className="info-logo-wrapper">
              <div className="info-tower-container">
                <StaticTowers />
              </div>
              <div className="info-name-container">
                <StaticName />
              </div>
            </div>
          </div>
          <div className="info-content">
            <h6 className="info-subtitle animation-box">
              <span ref={el => (titleSection.current[0] = el)}>
                Real Estate Redefined
              </span>
            </h6>
            <h3 className="animation-box">
              <span
                ref={el => (titleSection.current[1] = el)}
                className="infoX"
              >
                Let's build{' '}
                <span
                  style={{ color: '#fdba1a', display: 'inline', opacity: 1 }}
                >
                  <em>
                    <b>together</b>
                  </em>
                  .
                </span>
              </span>
            </h3>
            <p className="info-address">
              <span className="animation-box" style={{ display: 'block' }}>
                <span ref={el => (addressSection.current[0] = el)}>
                  3000 Marcus Avenue <br />
                </span>
              </span>
              <span className="animation-box" style={{ display: 'block' }}>
                <span ref={el => (addressSection.current[1] = el)}>
                  Suite 3W6 <br />
                </span>
              </span>
              <span className="animation-box" style={{ display: 'block' }}>
                <span ref={el => (addressSection.current[2] = el)}>
                  Lake Success, NY 11042
                </span>
              </span>
            </p>
            <p className="info-contact">
              <span className="animation-box" style={{ display: 'block' }}>
                <span ref={el => (contactSection.current[0] = el)}>
                  +1 (516) 382-6109 <br />
                </span>
              </span>
              <span className="animation-box" style={{ display: 'block' }}>
                <span ref={el => (contactSection.current[1] = el)}>
                  <a href="mailto:pkhan@khancapitalgroup.com">
                    pkhan@khancapitalgroup.com
                  </a>
                </span>
              </span>
            </p>
          </div>
          <div className="circle-wrapper">
            <div className="circle-container">
              <Wheel />
            </div>
          </div>
          <div className="follow-us">
            <h6 className="follow-us-title animation-box">
              <span ref={el => (socialsSection.current[0] = el)}>
                Follow Us
              </span>
            </h6>
          </div>
          <div className="socials-content">
            <ul className="socials-list">
              {icons.map(({ icon, name, link }, i) => (
                <li key={name} className="animation-box">
                  <span
                    className="icon-box"
                    ref={el => (socialsSection.current[i + 1] = el)}
                  >
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon"
                    >
                      <FontAwesomeIcon className="fa-icon" icon={icon} />
                    </a>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section ref={el => (menuSection = el)} className="menu-group">
        <div className="menu-content">
          <nav>
            <ul>
              {scrollToIDs.map((id, i) => (
                <li key={`${i}-${id}`}>
                  <div
                    role="button"
                    className="scroll-to-section"
                    ref={el => (linkRefs.current[i] = el)}
                    tabIndex="0"
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        throttleNavClick(
                          path,
                          scrollToSection,
                          id.to,
                          id.offset,
                          navigate
                        );
                      }
                    }}
                    onClick={() => {
                      // user can click but nothing will fire due to throttle
                      throttleNavClick(
                        path,
                        scrollToSection,
                        id.to,
                        id.offset,
                        navigate
                      );
                    }}
                  >
                    <span aria-hidden="true" data-text={id.menu_name}></span>
                    {id.menu_name}
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </section>
    </StyledHamburger>
  );
};

// onClick to open was triggering an extra re-render when clicking to open the burger menu and modal
const propsAreEqual = (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
  /*
      return true if passing nextProps to render would return
      the same result as passing prevProps to render,
      otherwise return false

      here we are basically checking when not to re-render
      if return true, then do not re-render
    */
};

export default React.memo(Hamburger, propsAreEqual);
