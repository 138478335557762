import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

const StyledHeaderLinks = styled.div`
  display: flex;
  gap: min(2rem, 2.3vw);
  padding-top: 4px;
  position: absolute;
  right: 0;

  * {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-font-smoothing: subpixel-antialiased;
    -o-font-smoothing: subpixel-antialiased;
    font-smoothing: subpixel-antialiased;
    /* box-sizing: border-box; */
    /* -webkit-touch-callout: none; */
    /* -webkit-tap-highlight-color: transparent; */
    /* -webkit-overflow-scrolling: touch; */
    text-rendering: optimizeLegibility;
    -moz-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
  }
  /* fixes text blur after header is being transform: translated
  text blur was also causing svgs on header to become blurry as well */
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-backface-visibility: hidden;

  .header-link {
    font-size: initial;
  }
  .header-links-button-wrapper {
    --highlight: var(--ghostWhite);
    color: var(--secondary);
    /* font-size: 1rem; */
    /* font-size: 19px; */
    /* 320-1140px 14-19 */
    font-size: clamp(0.88rem, calc(0.75rem + 0.61vw), 1.19rem);
    letter-spacing: 0.7px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    position: relative;
    font-family: Calluna;
    font-weight: 600;
    /* margin-right: 8px; */
    /* text-rendering: optimizeLegibility; */
    /* masks white text transform */
    span {
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform 0.3s var(--customEase) 0s;
      &::before {
        content: attr(data-text);
        display: inline-block;
        color: var(--highlight);
        transform: translateX(100%);
        /* clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%); */
        transition: transform 0.3s var(--customEase) 0s;
      }
    }
    &:hover span {
      transform: translateX(0);
    }
    &:hover span::before {
      /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); */
      transform: translateX(0);
    }
    /* transition: color 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1); */
  }
  @media screen and (max-width: 699px) {
    .portfolio-link {
      display: none;
    }
  }
`;

const HeaderLinks = ({ onToggleContactForm }) => (
  <StyledHeaderLinks>
    <button
      className="header-links-button-wrapper portfolio-link"
      onClick={() => {
        navigate('/portfolio');
        // if burger menu is open then debounce closing of menu
        // if (isOpen) {
        //   setTimeout(() => {
        //     closeMenu();
        //   }, 200);
        // }
      }}
    >
      {/* masking element aria-hidden is used for accessibility and will
      hide from screen readers so content wont be used twice */}
      <span data-text="Portfolio" aria-hidden="true"></span>
      Portfolio
    </button>
    <button
      className="header-links-button-wrapper"
      onClick={onToggleContactForm}
      aria-label="Contact Form"
      // aria-expanded={isVisible}
      // disabled={disabled}
    >
      <span data-text="Contact" aria-hidden="true"></span>
      Contact
    </button>
  </StyledHeaderLinks>
);

export default HeaderLinks;
