import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Flip } from 'gsap/Flip';
import {
  Hamburger,
  MenuButton,
  LogoButton,
  ModalContactForm,
  HeaderLinks
} from '../';

const StyledHeader = styled.header`
  /* height: 130px; */
  .wrapper {
    --shadowOpacity: 0;
    padding: 0 7vw;
    /* clmap will fall back to above value if not supported */
    padding: clamp(1rem, 7vw + 1rem, 9rem);
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    top: 0;
    width: 100%;
    /* max-width: 1560px; */
    /* left: 50%; */
    /* transform: translateX(-50%) translateZ(0) scale(1, 1); */
    margin: 0 auto;
    z-index: 99;
    background-color: transparent;
    /* box-shadow: rgb(40 67 116) 0px 2px 5px 0; */
    /* box-shadow: rgb(31 62 110 / 60%) 0px 0px 0px;
    box-shadow: none; */
    &::before {
      content: '';
      position: absolute;
      opacity: var(--shadowOpacity);
      width: 100%;
      height: 100%;
      left: 0;
      box-shadow: rgb(31 62 110 / 60%) 0px 3px 5px;
      /* transition: opacity: .3s cubic-bezier(0.445, 0.05, 0.55, 0.95); */
    }
  }
  .wrapper.fixed {
    position: fixed;
    top: -104px;
    /* transform: translate(0, 0); */
    background-color: var(--altBlue);
  }
  .hide {
    opacity: 0;
  }
  .inner-header {
    position: relative;
    z-index: 15;
    height: 100%;
    display: grid;
    place-items: center;
    border-bottom: 1px solid rgb(249 191 38 / 20%);
    padding: min(0.8rem, 4vw) 0;
  }
  #dynamicInnerHeader.fixed-active {
    border-bottom: none;
  }

  @media screen and (max-width: 500px) {
    .wrapper {
      /* padding: 0 1rem; */
      /* padding: 0 clamp(1rem, 2.2vw + 1rem, 3rem); */
      padding: 0 6.2vw;
    }
  }

  @media screen and (max-width: 768px) {
    #dynamicInnerHeader.fixed-active {
      padding: min(4.5vw, 16px) 0;
      margin-bottom: 3px;
    }
  }
`;

// moves header to top of viewport by adding fixed class
// function toggleHeader(action, duration = 0.3, ref) {
//   if (ref) {
//     const state = Flip.getState(ref.current);
//     ref.current.classList[action]('fixed');
//     Flip.from(state, {
//       scale: true,
//       ease: 'Power3.out',
//       duration
//     });
//   }
// }

const Header = ({ path }) => {
  const [showMenu, setMenuState] = useState(false);
  const [showContact, setContactState] = useState(false);
  // used to prevent close animation from triggering on initial load
  let initialRender = useRef(true);
  let headerWrapper = useRef(null);
  let fixedHeader = useRef(null);
  // used to prevent scrolltrigger onUpdate from firing when menu is open. can update this value without invoking a re-render
  const pauseUpdate = useRef(false);

  // makes sure scrollTrigger header position calculations are updated on each page load; fires when path changes
  useEffect(() => {
    if (fixedHeader.current) {
      ScrollTrigger.refresh();
      fixedHeader.current.refresh();
    }
  }, [path]);

  // useEffect for sticky dynamic header
  useEffect(() => {
    if (typeof window !== undefined) {
      gsap.registerPlugin(ScrollTrigger, Flip);

      // ScrollTrigger.normalizeScroll(true);

      function toggleHeader(action, duration = 0.3, ref) {
        if (ref) {
          const state = Flip.getState(ref.current);
          ref.current.classList[action]('fixed');
          Flip.from(state, {
            scale: true,
            absolute: true,
            ease: 'circ.out',
            toggleClass: 'hide',
            duration
          });
        }
      }

      // fixes bug where navigating to a new page while header is fixed causes flashing when scrolltrigger starts and header retriggers
      gsap.set(headerWrapper.current, {
        y: 0
      });

      // create a closure to track and ref from onUpdate function
      let triggered,
        lastScrolldownPosition,
        currentScrollupPosition,
        didScrollUp = false;

      fixedHeader.current = ScrollTrigger.create({
        id: 'dynamic-header',
        start: 'top -550',
        end: 'bottom bottom-=500%',
        fastScrollEnd: true,
        // invalidateOnRefresh: true,
        toggleClass: {
          targets: [
            // adds fixed-active class to the following ids which changes the look of the nav when scrolled
            '#dynamicInnerHeader',
            '#navLogoName',
            '#navLogoTowers',
            '#logoButtonWrapper',
            '#logoNameContainer'
          ],
          className: 'fixed-active'
        },
        onEnter: function () {
          // call flip function that moves header to top of viewport and adds .fixed class
          toggleHeader('add', 0, headerWrapper);
        },
        onLeaveBack: () => {
          // clear added transform style from yPercent animation and then reset to orginal position
          didScrollUp = false;
          gsap.to(headerWrapper.current, {
            // clearProps: 'transform',
            y: 0,
            duration: 0,
            '--shadowOpacity': 0,
            // prevents active (currently animating) tween of same target from overlapping (line 245)
            overwrite: 'auto',
            onComplete: () => {
              toggleHeader('remove', 0, headerWrapper);
              // removes .fixed class
            }
          });
        },
        onUpdate: self => {
          // prevents func from firing when menu is open. fixes issue where resizing screen while open would hide the header and close button
          if (pauseUpdate.current) return;

          // fires during scroll down
          if (self.direction === 1) {
            triggered = false;
            lastScrolldownPosition = self.scroll();
            // check if user scrolled up thus revealing the header
            if (didScrollUp) {
              // detects if header is displayed and hides it
              didScrollUp = false;

              gsap.to(headerWrapper.current, {
                // 0 is also the starting value of fixed header (starts hidden)
                y: 0,
                duration: 0.2,
                // ease: 'power2.in',
                onComplete: () => {
                  gsap.set(headerWrapper.current, {
                    '--shadowOpacity': 0
                  });
                }
              });
            }
            // if scrolling up and header isn't displayed
          } else if (self.direction === -1 && !triggered) {
            // console.log('inside');
            currentScrollupPosition = self.scroll();
            // console.log(self.scroll());
            didScrollUp = true;
            if (
              lastScrolldownPosition - currentScrollupPosition > 59 &&
              currentScrollupPosition > 640
            ) {
              // only show header when user scrolls up more than 59px from their last scroll down position
              // keep track of header revealed state
              triggered = true;
              // only gets called once
              gsap.set(headerWrapper.current, {
                '--shadowOpacity': 1,
                onComplete: () => {
                  gsap.to(headerWrapper.current, {
                    y: 103,
                    duration: 0.3,
                    ease: 'power2.out'
                    // '--shadowOpacity': 1,
                  });
                }
              });
            }
            // if scrolling up and header is visible. gets called everytime on scroll up and header is visible
          } else if (self.direction === -1) {
            const scrollPosition = self.scroll();
            didScrollUp = true;
            // if below 640 and header is visible, hide it before end of scrolltrigger and toggleClass triggers onLeaveBack event
            // triggered var ensures that gsap func only fires once
            if (scrollPosition < 640 && triggered) {
              // set false so scrolling down in this range doesnt trigger gsap animation ln 146
              didScrollUp = false;
              // update var triggered to hidden
              triggered = false;
              gsap.to(headerWrapper.current, {
                y: 0,
                duration: 0.3,
                onComplete: () => {
                  gsap.set(headerWrapper.current, {
                    '--shadowOpacity': 0
                  });
                }
              });
            }
          }
        }
      });
    }
    // return () => {
    // makes sure header is always on top of page on route update
    // headerWrapperRefCopy.current?.classList?.remove('fixed');
    // headerWrapperRefCopy.current?.removeAttribute('style');
    // headerWrapperRefCopy.current.style.removeProperty('transform');
    // Flip.killFlipsOf(headerWrapperRefCopy);

    // doesnt work here because updating on path change will run useEffect again calling a new scrollTrigger instance while still keeping old ones and will cause all to fire simultaneously
    // moved refresh to onRouteUpdate hook in gatsby-browser
    // ScrollTrigger.refresh();
    // };
  }, []);

  //toggle menu state
  const handleMenu = useCallback(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
    setMenuState(prevState => {
      pauseUpdate.current = !pauseUpdate.current;
      return !prevState;
    });
  }, []);

  const toggleContactForm = useCallback(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
    // disableContactButton();
    setContactState(prevState => !prevState);
  }, []);

  // needed hide fixed nav in hamburger menu reveal
  const isFixed = headerWrapper.current?.classList?.contains('fixed');

  return (
    <StyledHeader>
      {/* make sure element is stored in ref.current so it retains object-pass-by-ref functionality */}
      <div className="wrapper" id="dynamicHeader" ref={headerWrapper}>
        <MenuButton
          onClickHandleMenu={handleMenu}
          isOpen={showMenu}
          // disabled={disabled}
        />
        <div className="inner-header" id="dynamicInnerHeader">
          <LogoButton />
          <HeaderLinks
            onToggleContactForm={toggleContactForm}
            // isVisible={showContact}
            // disabled={disabledContactButton}
          />
        </div>
      </div>
      <Hamburger
        isOpen={showMenu}
        closeMenu={handleMenu}
        initialRender={initialRender}
        path={path}
        isFixed={isFixed}
      />
      <ModalContactForm
        isOpen={showContact}
        onBackgroundClick={toggleContactForm}
        onEscapeKeydown={toggleContactForm}
        onClose={toggleContactForm}
      />
    </StyledHeader>
  );
};

export default Header;
