import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';

import Modal from '../UI/ModalPortal';
import { LeftArrow, RightArrow } from '../UI/Chevron';

const StyledLightboxContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  position: relative;
  height: 100%;
  .close-form-button {
    position: absolute;
    top: 4%;
    right: 6.5%;
    z-index: 30;
    button {
      padding: 0;
      background: none;
      border: none;
      font-size: 2rem;
      opacity: 0.7;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
      transition: opacity 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      span {
        color: var(--ghostWhite);
      }
    }
  }
`;

const StyledSliderWrapper = styled.div`
  width: min(980px, 70%);
  /* prevents top part of modal from getting cut off when height is decreased */
  max-height: 100vh;
  padding-block: 3rem;
  position: relative;
  opacity: ${({ active }) => active};
  transition: opacity 400ms var(--customEase) 200ms;
  .gatsby-image-wrapper {
    max-height: 600px;
  }
  .slick-track {
    display: flex;
    align-items: center;
  }

  .navArrow {
    color: var(--ghostWhite);
    cursor: pointer;
    position: absolute;
    z-index: 10;
    transition: color 0.3s var(--colorEase);
    border: 0;
    background: none;
    &:hover {
      color: var(--secondary);
    }
    .fa-sm {
      font-size: 28px;
    }
    &.next {
      right: -50px;
      top: 50%;
      transform: translate(0px, -50%);
    }
    &.prev {
      left: -50px;
      top: 50%;
      transform: translate(0px, -50%);
    }
  }
  .slick-slide {
    position: relative;
    text-align: center;
    .draggable {
      cursor: move; /* fallback: no url() support or images disabled */
      cursor: url(images/grab.cur); /* fallback: Chrome 1-21, Firefox 1.5-26, Safari 4+, IE, Edge 12-14, Android 2.1-4.4.4 */
      cursor: grab; /* W3C standards syntax, all modern browser */
      &:active {
        cursor: url(images/grabbing.cur);
        cursor: grabbing;
      }
    }
    .content {
      padding: 1.5rem 0 0;
      position: relative;
      font-weight: 600;
      font-family: Poppins, sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      font-size: 15px;
      color: var(--secondary);
      .caption {
        width: 68%;
        margin: 0 auto;
        line-height: 1.86;
      }
    }
  }
  .slick-slide:focus {
    outline: none;
  }
  .slick-slide-title {
    text-transform: capitalize;
  }
  .slick-dots {
    position: inherit;
    bottom: auto;
    margin-top: -15px;
  }
  .slick-slide-image {
    width: 100%;
    border-radius: 8px;
    /* max-width: 1150px; */
    /* max-height: 574px; */
  }

  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }

  @media screen and (max-width: 475px) {
    width: 84%;

    .gatsby-image-wrapper {
      max-height: 460px;
    }

    .navArrow {
      .fa-sm {
        font-size: 22px;
      }
      &.next {
        right: 5px;
        bottom: 8px;
        top: unset;
        transform: none;
      }
      &.prev {
        left: 5px;
        bottom: 8px;
        top: unset;
        transform: none;
      }
    }
  }
`;

const backgroundGradient = `linear-gradient( 0deg,rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.9) 100% ),linear-gradient(0deg,rgba(0,0,0,0.9) 0%,transparent 100%);`;

const ModalLightbox = ({ images, imageIndex, closeLightbox, ...props }) => {
  // sets opacity
  const [active, setActive] = useState(0);
  // create new array to make sure slider starts at the image that was clicked on
  const imagesArr = images
    .slice(imageIndex)
    .concat(images.slice(0, imageIndex));

  const mainSliderProps = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    // lazyLoad: 'progressive',
    nextArrow: <RightArrow size="sm" />,
    prevArrow: <LeftArrow size="sm" />
    // onInit: () => {
    //   console.log('ready');
    // }
  };

  const activateAnimationsAfterOpen = useCallback(() => {
    setActive(1);
  }, []);

  const fadeOutAnimationsBeforeClose = useCallback(() => {
    return new Promise(resolve => {
      setActive(0);
      setTimeout(resolve, 500);
    });
  }, []);

  return (
    <Modal
      {...props}
      backgroundProps={{ backgroundGradient, active }}
      afterOpen={activateAnimationsAfterOpen}
      beforeClose={fadeOutAnimationsBeforeClose}
    >
      <StyledLightboxContainer aria-label="lightbox image carousel">
        <div className="close-form-button">
          <button
            type="button"
            onClick={e => {
              e.stopPropagation();
              closeLightbox();
            }}
          >
            <span>&times;</span>
          </button>
        </div>
        <StyledSliderWrapper active={active}>
          <Slider {...mainSliderProps}>
            {imagesArr.map((image, i) => {
              const gImage = getImage(image);
              return (
                <div
                  key={'swiper-main-' + i}
                  className="slick-slide draggable"
                  aria-label={`slide ${i + 1} of ${images.length}`}
                >
                  <GatsbyImage
                    imgClassName="slick-slide-image"
                    alt={image.description}
                    image={gImage}
                  />
                  <div className="content">
                    <div className="caption">{image.description}</div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </StyledSliderWrapper>
      </StyledLightboxContainer>
    </Modal>
  );
};

export default ModalLightbox;
