import React, { useRef, useEffect } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const StyledInvestment = styled.div`
  /* margin-top: 2vw;
  margin-top: clamp(5px, 2vw, 6rem); */
  padding: 7.5vw 6.5vw;
  padding: clamp(3rem, 7.5vw, 10rem) clamp(1rem, 6.5vw, 10rem);
  position: relative;
  background: linear-gradient(0deg, #0d1e39 27%, #132e6045 100%),
    -webkit-linear-gradient(0deg, #0d1e39 27%, #132e60a6 100%);
  /* min-height: 100vh; */
`;
const InvestmentWrapper = styled.div`
  padding: 2rem 0;
  display: flex;
  position: relative;
  gap: 3rem;
  margin: 0 auto;
  max-width: 1250px;

  @media screen and (max-width: 744px) {
    justify-content: center;
  }
`;
const ColumnOneInfoContainer = styled.div`
  flex-basis: 45%;
  color: var(--secondary);

  .heading {
    /* max-width: 460px;
    margin: auto; */
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading-bottom {
      align-self: flex-end;
      font-size: 1.1rem;
      width: 70%;
      margin: 0.3rem 1.5rem 0.8rem 0;
      color: var(--lightOrange);
    }
    h1 {
      /* font-size: 4.5rem; */
      /* 320-767 53-72 */
      font-size: clamp(3.31rem, calc(2.46rem + 4.25vw), 4.5rem);
      margin: 0;
    }
    h3 {
      text-align: end;
      /* 16-20 320-600*/
      font-size: clamp(1rem, calc(0.9rem + 0.49vw), 1.25rem);
      letter-spacing: 0.3px;
    }
    /* for animating slide in without opacity */
  }
  .divider-circles {
    /* padding: 0.4rem 0; */
    .circle {
      color: var(--lightBlue);
      display: block;
      text-align: center;
      &:first-of-type {
        margin-bottom: -0.5rem;
        color: var(--secondary);
      }
    }
  }
  .animation-box {
    overflow: hidden;
    text-align: center;
    width: 100%;
    span {
      position: relative;
      /* display block is necessary for skew transforms */
      display: block;
    }
  }
  .investment-subheading {
    /* color: var(--ghostWhite); */
    color: #f8f8ff;
    position: relative;
    /* .vertical-line {
      position: absolute;
      height: 100%;
      width: 4px;
      background-color: var(--secondary);
      left: 0;
      top: 0;
      z-index: 4;
    } */
    h3 {
      /* margin-bottom: 1.5rem; */
      text-align: center;
      letter-spacing: 0px;
      /* 17-20 320-767*/
      font-size: clamp(1.06rem, calc(0.93rem + 0.67vw), 1.25rem);
      font-weight: 800;
      font-family: Poppins, sans-serif;
    }
  }
  .investment-description {
    text-align: center;
    margin: 0;
    padding: 0.2rem 0;
    p {
      font-family: Calluna, sans-serif;
      line-height: 35px;
      line-height: 1.944;
      margin: 22px auto;
      width: min(100%, 462px);
      /* 15.5-18 320-600*/
      font-size: clamp(0.97rem, calc(0.79rem + 0.89vw), 1.13rem);
      letter-spacing: 0.3px;
      color: #f8f8ff;
    }
  }

  @media screen and (max-width: 744px) {
    flex-basis: min(90%, 420px);
  }
`;

const ColumnTwoImagesContainer = styled.div`
  flex-basis: 55%;
  position: relative;
  .image-container-1 {
    position: absolute;
    left: 0;
    width: clamp(210px, 33vw, 335px);
    /* starting point of translate */
    /* top: -45px; */

    /* max-height: 750px; */
  }
  .image-container-2 {
    /* height: 55vh; if specific height is necessary, uncomment and then make the image class height: 100%  */
    width: clamp(192px, 32vw, 390px);
    position: absolute;
    /* starting point of translate */
    bottom: 0;
    right: 18%;
  }
  .image-container-3 {
    position: absolute;
    width: clamp(336px, 54vw, 600px);
    left: 0;
    top: 32%;
    transform: translateX(400px);
  }

  @media screen and (max-width: 744px) {
    display: none;
  }
`;

const query = graphql`
  {
    assets: contentfulUiAssets {
      investments {
        title
        description
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;

const Investment = () => {
  const { assets } = useStaticQuery(query);
  let image1 = useRef(null);
  let image2 = useRef(null);
  let image3 = useRef(null);
  // useRef so we can update value without triggering a state update and component re-render
  let topImage = useRef({ name: null, value: null });

  let col1 = useRef(null);
  let investing = useRef(null);
  let withKhan = useRef(null);

  // update z-index so that image hovered becomes the top image
  const toggleZ = (name, imageRef) => {
    // only fire if the hovered image is different, so z-index doesnt change unnecessarily
    if (name !== topImage.current.name) {
      // track and update latest hovered image
      // save the latest z-index value
      let indexValue;
      if (topImage.current.value) {
        indexValue = topImage.current.value++;
      }
      if (!topImage.current.value) {
        topImage.current.value = 1;
        indexValue = 1;
      }
      // store name as a reference
      topImage.current.name = name;
      // number value, initializes as ''
      imageRef.current.style.zIndex = indexValue;
    }
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      gsap.registerPlugin(ScrollTrigger);

      const infoReveal = gsap.timeline({
        scrollTrigger: {
          trigger: col1.current,
          start: 'top 58%',
          once: true,
          // markers: true,
          fastScrollEnd: 3000
        }
      });
      infoReveal
        .from(investing, {
          x: 300,
          duration: 1.4,
          ease: 'expo.out',
          autoAlpha: 0
        })
        .from(
          withKhan,
          {
            x: -310,
            duration: 1,
            ease: 'circ.out',
            autoAlpha: 0
          },
          // after the start (positive number represents after, < represents the start)
          '<'
        )
        .from(
          '.animated-investment-subheading',
          {
            // yPercent: 160,
            y: 24,
            duration: 1.1,
            ease: 'power4.out'
            // skewY: 7
          },
          // before the end of the previous tween (negative represents before, > represents the end)
          '-=.6'
        )
        .from(
          '.animated-investment-description',
          {
            autoAlpha: 0,
            duration: 0.7,
            ease: 'circ.out'
          },
          '<.45'
        );

      ScrollTrigger.matchMedia({
        '(min-width: 745px)': function () {
          const imageParallax = gsap.timeline({
            scrollTrigger: {
              trigger: '#investmentSection',
              // start: 'top-=15 bottom',
              start: 'top bottom',
              end: '90% top',
              scrub: 1,
              invalidateOnRefresh: true
              // markers: true
            }
          });
          imageParallax
            .to(
              // lefthand image moving down
              image1.current,
              {
                y: () =>
                  Math.round(
                    col1.current.offsetHeight - image1.current.offsetHeight
                  ) + 40,
                // duration: 10,
                duration: 6,
                ease: 'sine.in'
              }
            )
            .to(
              // righthand image moving up
              image2.current,
              {
                // y: -400,
                y: () =>
                  Math.round(
                    col1.current.offsetHeight - image2.current.offsetHeight
                  ) * -1,
                duration: 3.2
              },
              // run 1s after the start of previous tween
              '<2'
            )
            .to(
              image3.current,
              {
                x: 0,
                ease: 'power2.out',
                // duration: 8.5,
                duration: 6
              },
              '<-1'
            );
        }
      });
    }
  }, []);

  return (
    <StyledInvestment id="investmentSection">
      <InvestmentWrapper>
        <ColumnOneInfoContainer ref={col1}>
          <div className="heading">
            <div ref={el => (investing = el)}>
              <h1>
                <span
                // lassName="text-gradient-1"
                >
                  Investing
                </span>
              </h1>
            </div>
            <div className="heading-bottom" ref={el => (withKhan = el)}>
              <h3>
                <span
                // className="text-gradient-1"
                >
                  w/ Khan Capital Group
                </span>
              </h3>
            </div>
          </div>
          {/* <div className="divider-circles">
            <span className="circle animated-investment-circle">&#8226;</span>
            <span className="circle animated-investment-circle">&#8226;</span>
          </div> */}
          <div className="investment-subheading">
            <div className="animation-box">
              <h3>
                <span className="animated-investment-subheading">
                  Institutional Expertise.
                </span>
              </h3>
            </div>
          </div>
          <div className="investment-description animated-investment-description">
            <p>
              At{' '}
              <span style={{ color: 'var(--secondary)' }}>
                Khan Capital Group
              </span>
              , we are committed to providing our clients with a higher standard
              of service and experience in New York Real Estate. By{' '}
              <span style={{ color: 'var(--coral)' }}>leveraging data</span> to
              redefine the paths in which buyers and sellers connect, we’re
              providing an elevated service experience that positions our
              clients, partners, and agents for{' '}
              <span style={{ color: '#00fa9a' }}>success</span>.
            </p>
          </div>
          <div className="investment-subheading" style={{ marginTop: '0' }}>
            <div className="animation-box">
              <h3>
                <span className="animated-investment-subheading">
                  Family Values.
                </span>
              </h3>
            </div>
          </div>
          <div className="investment-description animated-investment-description">
            <p>
              Our approach has been born from{' '}
              <span style={{ color: '#4FEFE9' }}>
                family valued real estate
              </span>
              . Working closely with institutional level investors we have the
              structure to satisfy sophisticated investor requirements yet as a
              group of families, we are never far from the values of our
              founding based upon{' '}
              <span style={{ color: 'var(--secondary)' }}>
                family, long-term value, hard work, discipline and integrity
              </span>
              .
            </p>
          </div>
        </ColumnOneInfoContainer>
        <ColumnTwoImagesContainer>
          {assets?.investments.map((image, i) => {
            const gImage = getImage(image);
            // reference to the element
            let reference;
            switch (image.title) {
              case 'construction-highrise':
                reference = image1;
                break;
              case 'house-scaffold':
                reference = image2;
                break;
              case 'roofdeck':
                reference = image3;
                break;
              default:
                console.log('image.title has no value');
            }
            return (
              <div
                className={`image-container-${i + 1}`}
                ref={reference}
                key={`investment-image-${i + 1}`}
                onMouseOver={e => {
                  e.stopPropagation();
                  toggleZ(image.title, reference);
                }}
                aria-hidden="true"
                onFocus={() => {
                  toggleZ(image.title, reference);
                }}
              >
                <GatsbyImage
                  image={gImage}
                  placeholder="blurred"
                  alt={image.description}
                />
              </div>
            );
          })}
        </ColumnTwoImagesContainer>
      </InvestmentWrapper>
    </StyledInvestment>
  );
};

export default Investment;
