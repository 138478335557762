import React from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';

import './static/fonts/font.css';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import InitialRenderProvider from './src/utils/InitialRenderContext';
import ModalProvider from './src/utils/ModalContext';
import { StyledBackdrop } from './src/components/UI/Backdrop';
import { Layout } from './src/components/';

// This is useful to set up any Provider components that will wrap your application.
// For setting persistent UI elements around pages use wrapPageElement.
const key = process.env.GATSBY_CAPTCHA_KEY;
// const testKey = process.env.GATSBY_LOCALHOST;

gsap.registerPlugin(ScrollTrigger);

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={key}
    scriptProps={{
      defer: true
    }}
  >
    <InitialRenderProvider>
      <ModalProvider backgroundComponent={StyledBackdrop}>
        {element}
      </ModalProvider>
    </InitialRenderProvider>
  </GoogleReCaptchaProvider>
);

// This is useful for setting wrapper components around pages so that the component that won’t get unmounted on page changes.
// props provide same data to Layout as Page element receives
// including location, data, etc
// provider for context goes here so that it can update consumer
export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

// runs after every page change
export const onRouteUpdate = ({ location, prevLocation }) => {
  // only fire on initial page load
  if (!prevLocation) {
    const isMobile = window.matchMedia('(max-width: 500px)');
    // console.log('is mobile?', isMobile.matches);

    if (isMobile.matches) {
      // ScrollTrigger.normalizeScroll(true);
      ScrollTrigger.config({ ignoreMobileResize: true });
    }
  }
  // excludes the initial page load and fire when pathnames are equal
  if (prevLocation && prevLocation.pathname !== location.pathname) {
    // alternative to having to loop through each trigger instance, destroying, and then reinitializing everytime component re-mounts
    // ScrollTrigger.refresh();
    const triggers = ScrollTrigger.getAll();
    if (triggers?.length) {
      triggers.forEach(trigger => {
        // console.log('this is a trigger', trigger);
        // kills every st instance when navigating to new page so that st doesnt persist while scrolling different page. cannot simply use refresh
        // should be conditional for every route specific st however all sts currently pertain to root page '/'
        if (trigger.vars.id !== 'dynamic-header') {
          // kills all scrolltrigger instances besides header on every route update so it can reinitialize and recalculate when component with st instance re-mounts
          trigger.kill();
        }
      });
    }
    // refresh dynamic-header instance so it can recalculate position
    ScrollTrigger.clearMatchMedia();
    // moved ScrollTrigger.refresh() to header useEffect because items fixed header was not refreshing in time
    // setTimeout(() => {
    //   ScrollTrigger.refresh();
    // }, 200);
  }
};
