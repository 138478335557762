import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

export const RightArrow = ({ onClick, size }) => (
  <button onClick={onClick} className="navArrow next">
    <FontAwesomeIcon icon={faChevronRight} size={size} />
  </button>
);

export const LeftArrow = ({ onClick, imgNumber, total, size }) => (
  <>
    {imgNumber && (
      <div className="sequencer">
        {imgNumber} / {total}
      </div>
    )}
    <button onClick={onClick} className="navArrow prev">
      <FontAwesomeIcon icon={faChevronLeft} size={size} />
    </button>
  </>
);
