import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';

import ModalLightbox from './ModalLightbox';
import { RightArrow, LeftArrow } from '../UI/Chevron';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledGallery = styled.div`
  text-align: center;
  width: min(1150px, 90%);
  margin: 0 auto;
  .gallery-title {
    padding: 0.5rem 3rem;
    text-align: center;
    margin: 1rem 0;
    /* letter-spacing: 0; */
    font-family: 'Poppins';
    letter-spacing: 1px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    z-index: 4;
    display: inline-block;
    border-left: 1px solid var(--secondary);
    background-color: #f9f7f7;
    border-right: 1px solid var(--secondary);
    position: relative;
  }
  .main-slider-wrap {
    position: relative;
    .gatsby-image-wrapper {
      /* max-height: 576px; */
      /* max-height: 576px; */
      max-height: 525px;
      height: 50vw;
    }
    &::before {
      content: '';
      position: absolute;
      width: 75%;
      height: 100%;
      border: 1px solid var(--secondary);
      /* border-radius: 8px; */
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, calc(-50% - 32px), 0px);
    }
    .slick-slide-image {
      transform: scale(0.5);
      transition: transform 500ms ease;
      cursor: pointer;
      /* max-width: 1150px; */
      /* max-height: 574px; */
    }
    .currently-active {
      transform: scale(1);
    }
    .slick-track {
      padding-block: 2rem 2rem;
    }
    .slick-list {
      padding-bottom: 15px;
    }
  }
  .slider-wrapper {
    /* width: 964px; */
    /* width: 864px; */
    width: min(874px, 94%);
    margin: auto;
    .sequencer {
      /* bottom: 23px; */
      position: relative;
      top: 8px;
      font-weight: 600;
      font-family: Poppins, sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      font-size: 0.875rem;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }
    .navArrow {
      cursor: pointer;
      position: absolute;
      z-index: 10;
      transition: color 0.3s var(--colorEase);
      border: 0;
      background: none;
      &:hover {
        color: var(--lightBlue);
      }
      .fa-xs {
        font-size: 17px;
      }
      &.next {
        right: 4%;
        bottom: 54px;
      }
      &.prev {
        bottom: 54px;
        left: 4%;
      }
    }
  }

  .slick-slide {
    position: relative;
    text-align: center;
    .content {
      padding: 1.5rem 0;
      position: relative;
      font-weight: 600;
      font-family: Poppins, sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      font-size: 0.875rem;
      .caption {
        width: 55%;
        margin: 0 auto;
        line-height: 1.86;
      }
    }
  }
  .slick-slide:focus {
    outline: none;
  }
  .slick-slide-title {
    text-transform: capitalize;
  }
  .slick-dots {
    position: inherit;
    bottom: auto;
    margin-top: -15px;
  }
  .slick-slide-image {
    width: 100%;
    /* max-width: 1150px; */
    /* max-height: 574px; */
  }

  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #777777;
  }
  .thumbnail-slider-wrap {
    /* height: 85px; */
    width: 86%;
    margin: 20px auto 0;
    .slick-track {
      justify-content: ${({ total }) => (total <= 3 ? 'space-between' : '')};
    }
    .slick-list {
      padding: 0 10px 30px;
    }
  }

  .thumbnail-slider-wrap.draggable .slick-track {
    cursor: move; /* fallback: no url() support or images disabled */
    cursor: url(images/grab.cur); /* fallback: Chrome 1-21, Firefox 1.5-26, Safari 4+, IE, Edge 12-14, Android 2.1-4.4.4 */
    cursor: grab; /* W3C standards syntax, all modern browser */
    &:active {
      cursor: url(images/grabbing.cur);
      cursor: grabbing;
    }
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    cursor: ${({ total }) => (total <= 3 ? 'pointer' : '')};
    width: ${({ total }) => (total <= 3 ? 'auto !important' : '')};
  }

  .thumbnail-slider-wrap .slick-slide-image {
    border-radius: 8px;
  }

  .thumbnail-slider-wrap .gatsby-image-wrapper {
    width: 140px;
    height: 100px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .slick-dots li.slick-active button:before {
    color: var(--lightBlue);
    opacity: 1;
  }

  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    color: var(--lightBlue);
  }

  @media screen and (max-width: 500px) {
    .gallery-title {
      padding: 0.5rem 3.2vw;
    }
    .main-slider-wrap {
      &::before {
        width: 65%;
      }
    }
  }

  @media screen and (max-width: 756px) {
    .thumbnail-slider-wrap {
      display: none;
    }
  }

  @media screen and (max-width: 450px) {
    .slider-wrapper {
      .navArrow.next {
        right: 3px;
      }
      .navArrow.prev {
        left: 3px;
      }
    }
  }
`;

const Gallery = ({ images }) => {
  const [imgNumber, setImgNumber] = useState(1);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  let navRef1 = useRef(null);
  let navRef2 = useRef(null);

  useEffect(() => {
    setNav1(navRef1);
    setNav2(navRef2);
  }, []);

  const mainSliderProps = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    // centerMode: true,
    draggable: false,
    // lazyLoad: 'anticipated',
    nextArrow: <RightArrow size="xs" />,
    prevArrow: (
      <LeftArrow imgNumber={imgNumber} total={images.length} size="xs" />
    ),
    beforeChange: (_, nextIndex) => {
      setImgNumber(nextIndex + 1);
    }
    // adaptiveHeight: true
    // asNavFor: '.slider-nav'
  };

  const thumbSliderProps = {
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    infinite: images.length > 3 ? true : false,
    // asNavFor: '.slider-for',
    dots: true,
    arrows: false,
    centerMode: true,
    swipeToSlide: images.length > 3 ? true : false,
    draggable: images.length > 3 ? true : false,
    focusOnSelect: true,
    // lazyLoad: 'anticipated',
    centerPadding: '0px 30px'
  };

  const toggleLightbox = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <StyledGallery total={images.length}>
      <h4 className="gallery-title">Gallery</h4>
      <section aria-label="image carousel" className="slider-wrapper">
        <div className="main-slider-wrap">
          <Slider
            {...mainSliderProps}
            asNavFor={nav2}
            ref={el => (navRef1 = el)}
            role="group"
          >
            {images.map((image, i) => {
              const gImage = getImage(image);
              return (
                <div
                  key={'swiper-main-' + i}
                  className="slick-slide"
                  aria-label={`slide ${i + 1} of ${images.length}`}
                >
                  <GatsbyImage
                    imgClassName={
                      i === imgNumber - 1
                        ? 'slick-slide-image currently-active'
                        : 'slick-slide-image'
                    }
                    alt={image.description}
                    image={gImage}
                    onClick={toggleLightbox}
                  />
                  <div className="content">
                    <div className="caption">{image.description}</div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div
          className={[
            'thumbnail-slider-wrap',
            images.length > 3 ? 'draggable' : ''
          ].join(' ')}
        >
          <Slider
            {...thumbSliderProps}
            asNavFor={nav1}
            ref={el => (navRef2 = el)}
            role="group"
          >
            {images.map((image, i) => {
              const gImage = getImage(image);
              return (
                <div
                  key={'swiper-thumbnail-' + i}
                  className="slick-slide"
                  aria-label={`slide ${i + 1} of ${images.length}`}
                >
                  {/* <figure className="slick-slide-image"> */}
                  <GatsbyImage
                    className="slick-slide-image"
                    alt={image.description}
                    image={gImage}
                  />
                  {/* </figure> */}
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
      <ModalLightbox
        isOpen={isOpen}
        onBackgroundClick={toggleLightbox}
        onEscapeKeydown={toggleLightbox}
        images={images}
        imageIndex={imgNumber - 1}
        closeLightbox={toggleLightbox}
      />
    </StyledGallery>
  );
};

export default Gallery;
