import React, { useCallback, useState } from 'react';
import ContactForm from './ContactForm';
import ModalPortal from '../UI/ModalPortal';
import { css, keyframes } from 'styled-components';

// transform: scale(.7) translateY(100%);
// transform: scale(1) translateY(0%);
// transform: scale(1) translateY(0%);
// transform: scale(0.7) translateY(100%);

const scaleAndFadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
const scaleAndFadeOut = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
`;

const StyledModalContactForm = ModalPortal.styled`
z-index: 501;
background: linear-gradient(-192deg, #0d1e39 27%, #132e60 100%);
text-align: center;
padding: 2rem min(1.2rem, 1.5vw);
border-radius: 3px;
box-shadow: 0.4rem 0.4rem #fdba1a6e, -0.4rem -0.4rem #5571ff69;
position: relative;
animation: ${({ active }) =>
  !active
    ? css`
        ${scaleAndFadeOut} .4s ease-out;
      `
    : css`
        ${scaleAndFadeIn} .4s var(--customEase);
      `};
@media (min-width: 600px) {
  width: min(86%, 840px);
}

@media (max-height: 570px) {
  height: 100%;
  width: 100%;
  overflow: scroll;
}

@media (max-width: 599px) {
  height: 100%;
  width: 100%;
  overflow: scroll;
}
`;

const ModalContactForm = ({ onClose, ...props }) => {
  const [active, setActive] = useState(0);

  // useCallback is called so that we keep a ref to the same function. prevents children being unnecessarily
  // re-rendered when setting state or receiving new props. this will happen when functions are passed to children where
  // the child has the same function in it's useEffect dependency. this will create the inifinite re-render/useEffect loop
  // useCallback will return a new verison of the function only when its dependencies change
  // ModalPortal has after open in a useEffect dep array
  const afterOpen = useCallback(() => {
    // setTimeout(() => {
    setActive(1);
    // }, 0);
  }, []);

  const beforeClose = useCallback(() => {
    return new Promise(resolve => {
      // runs setActive first, wait until closing animation is complete in setTimeout, once resolved then modal will close
      setActive(0);
      // ensure animation completes before modal closes
      // wait 5 seconods while animation is playing, then close
      setTimeout(resolve, 500);
    });
  }, []);

  return (
    <StyledModalContactForm
      {...props}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      backgroundProps={{ active }}
      active={active}
    >
      <ContactForm onClose={onClose} />
    </StyledModalContactForm>
  );
};

export default ModalContactForm;
