import React from 'react';
import styled from 'styled-components';

import Form from './Form';

const StyledPropertyContact = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .row-1 {
    width: min(1150px, 90%);
    .contact-form-container {
      height: 256px;
    }
    .recaptcha-branding {
      p {
        font-size: 0.7rem;
        line-height: 20px;
        margin: 3.8rem 0 0;
        color: var(--black);
        opacity: 0.85;
        text-align: center;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  .row-2 {
    width: 100%;
    margin-top: 6rem;
  }

  .gmap_canvas {
    height: 440px;
  }

  .gmap_canvas * {
    box-sizing: content-box;
  }

  @media screen and (max-width: 550px) {
    .row-1 {
      .contact-form-container {
        height: 544px;
      }
    }
  }
`;

const stringToURI = (zipCode, address) => {
  let addy = address;
  let zip = zipCode;
  if (!zip) {
    addy = '23-09 astoria blvd apt 4';
    zip = 11102;
  }
  const queryURI = encodeURI(`${addy} ${zip}`);
  return `https://maps.google.com/maps?q=${queryURI}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
};

const PropertyContact = ({ zipCode, address }) => {
  const src = stringToURI(zipCode, address);

  return (
    <StyledPropertyContact>
      <section className="row-1">
        <div className="contact-form-container">
          <Form />
        </div>
        <div className="recaptcha-branding">
          <p>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
            apply.
          </p>
        </div>
      </section>
      <section className="row-2">
        <div className="gmap_canvas">
          <iframe
            title="Google Map"
            width="100%"
            height="100%"
            id="gmap_canvas"
            src={src}
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </StyledPropertyContact>
  );
};

export default PropertyContact;
