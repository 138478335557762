import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik, Form, Field, useField } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import SubmissionMessage from './SubmissionMessage';
import Spinner from '../UI/Spinner';

const StyledSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledContactForm = styled.div`
  color: var(--secondary);
  .intro {
    padding: 15px 0 20px;
    h6 {
      font-family: 'Poppins';
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 1rem;
      /* 13-16px */
      font-size: clamp(0.81rem, calc(0.74rem + 0.37vw), 1rem);
    }
  }
  .heading {
    h2 {
      text-transform: capitalize;
      /* line-height: 38px; */
      line-height: 160%;
      font-size: 24px;
      /* 18-24px */
      font-size: clamp(1.13rem, calc(0.98rem + 0.73vw), 1.5rem);
      letter-spacing: 0.8px;
      color: var(--ghostWhite);
    }
  }

  .recaptcha-branding {
    p {
      font-size: 0.7rem;
      line-height: 20px;
      margin: 2rem 0 1rem;
      color: var(--grey);
      opacity: 0.85;
    }
    a {
      text-decoration: none;
      color: var(--altLightBlue);
    }
  }

  @media screen and (max-width: 599px) {
    .heading,
    .recaptcha-branding {
      width: min(90%, 325px);
      margin: auto;
    }
  }
`;

const FormContainer = styled.div`
  margin-top: 1.6rem;
  padding: 1rem 0;
  .contact-form {
    display: flex;
    justify-content: center;
    gap: 2rem;
    .close-form-button {
      position: absolute;
      top: 4%;
      right: 3.5%;
      button {
        padding: 0;
        background: none;
        border: none;
        opacity: 0.7;
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
        transition: opacity 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        span {
          color: var(--grey);
        }
      }
    }
    .form-section {
      font-size: 15px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      text-align: left;
      width: min(330px, 41%);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      .input-container {
        display: flex;
        flex-direction: column;
      }
      .error {
        position: absolute;
        right: 0;
        font-size: 12px;
        bottom: -16px;
        letter-spacing: 0.2px;
        color: var(--coral);
      }
      .input-box {
        position: relative;
      }
      :focus {
        outline: none;
        -webkit-appearance: none;
      }

      /* To remove button from IE11 */
      select::-ms-expand {
        display: none;
      }

      input[type='text'],
      input[type='email'],
      textarea,
      select {
        font: 15px/24px Poppins, sans-serif;
        color: var(--grey);
        width: 100%;
        height: 2.5rem;
        letter-spacing: 0.5px;
        box-sizing: border-box;
      }

      .select-box {
        position: relative;
        z-index: 0;
      }

      .select-box:after {
        content: '>';
        position: absolute;
        color: var(--secondary);
        font-size: 20px;
        transform: translate(0, -50%) rotate(90deg);
        right: 15px;
        top: 50%;
        pointer-events: none;
      }

      .select-box select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -ms-word-break: normal;
        word-break: normal;
        padding-right: 38px;
        text-overflow: ellipsis;
      }

      /* Change Autocomplete text color in Chrome*/
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--grey);
      }

      /* get rid of autofill styles */
      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
      }

      textarea {
        /* 2.5rem + 2.5 + 2(gap) */
        height: 7rem;
      }

      .effect-20 {
        /* border: 0.6px solid #2994fd54; */
        border: 0.7px solid #323d788c;
        padding: 6px 10px;
        transition: 0.4s;
        background: transparent;
      }
      .effect-20 ~ .focus-border:before,
      .effect-20 ~ .focus-border:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 2px;
        /* background-color: #2994fd6e; */
        background-color: #323d78;
        transition: 0.3s;
      }
      .effect-20 ~ .focus-border:after {
        top: auto;
        bottom: 0;
        left: auto;
        right: 0;
      }
      .effect-20 ~ .focus-border i:before,
      .effect-20 ~ .focus-border i:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 0;
        background-color: #2994fd6e;
        background-color: #323d78;
        transition: 0.4s;
      }
      .effect-20 ~ .focus-border i:after {
        left: auto;
        right: 0;
        top: auto;
        bottom: 0;
      }
      .effect-20:focus ~ .focus-border:before,
      .effect-20:focus ~ .focus-border:after,
      .has-content.effect-20 ~ .focus-border:before,
      .has-content.effect-20 ~ .focus-border:after {
        width: 100%;
        transition: 0.3s;
      }
      .effect-20:focus ~ .focus-border i:before,
      .effect-20:focus ~ .focus-border i:after,
      .has-content.effect-20 ~ .focus-border i:before,
      .has-content.effect-20 ~ .focus-border i:after {
        height: 100%;
        transition: 0.4s;
      }
      .effect-20 ~ label {
        position: absolute;
        left: 14px;
        top: 14px;
        color: var(--secondary);
        transition: 0.3s;
        z-index: -1;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        /* 12-14px */
        font-size: clamp(0.75rem, calc(0.7rem + 0.24vw), 0.88rem);
      }
      .effect-20:focus ~ label,
      .has-content.effect-20 ~ label {
        top: -18px;
        left: 0;
        font-size: clamp(0.67rem, calc(0.64rem + 0.15vw), 0.75rem);
        color: var(--secondary);
        transition: 0.3s;
        letter-spacing: 1.4px;
      }
      .submit-contact-form-btn {
        position: relative;
        font-size: 13.5px;
        outline: none;
        width: 100%;
        height: 2.5rem;
        letter-spacing: 2.5px;
        font-weight: 600;
        border-radius: 3px;
        color: #5571ff;
        text-decoration: none;
        background: transparent;
        border: 1px solid #5571ff;
        text-transform: uppercase;
        overflow: hidden;
        cursor: pointer;
        transition: color 0.3s var(--colorEase);
        -webkit-transition: 0.3s color var(--colorEase);
        &:before {
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          background: var(--buttonBlue);
          content: '';
          position: absolute;
          /* 
      two different methods of centering 
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); 
      height: 100%;
      width: 0; */
          top: 0;
          left: 50%;
          right: 50%;
          bottom: 0;
          z-index: -1;
          transition: all 0.5s ease;
          -webkit-transition: 0.5s all ease;
          opacity: 0;
        }
        &:hover:before {
          /* width: 100%; */
          left: 0;
          right: 0;
          opacity: 1;
        }
        &:hover {
          color: var(--ghostWhite);
        }
        span {
          pointer-events: none;
        }
      }

      /* .squishy span {
         This helps IE 
        pointer-events: none;
        display: inline-flex;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      } */

      @-webkit-keyframes pulseBox {
        0% {
          -webkit-box-shadow: 0px 0px 10px 0px rgba(98, 119, 235, 0.6);
        }
        100% {
          -webkit-box-shadow: 0px 0px 18px 0px rgba(98, 119, 235, 0.9);
        }
      }
      @-moz-keyframes pulseBox {
        0% {
          -moz-box-shadow: 0px 0px 10px 0px rgba(98, 119, 235, 0.6);
        }
        100% {
          -moz-box-shadow: 0px 0px 18px 0px rgba(98, 119, 235, 0.9);
        }
      }
      @-o-keyframes pulseBox {
        0% {
          -o-box-shadow: 0px 0px 10px 0px rgba(98, 119, 235, 0.6);
        }
        100% {
          -o-box-shadow: 0px 0px 18px 0px rgba(98, 119, 235, 0.9);
        }
      }
      @keyframes pulseBox {
        0% {
          box-shadow: 0px 0px 10px 0px rgba(98, 119, 235, 0.6);
        }
        100% {
          box-shadow: 0px 0px 18px 0px rgba(98, 119, 235, 0.9);
        }
      }

      .pulseBox {
        -webkit-animation-name: pulseBox;
        -moz-animation-name: pulseBox;
        -o-animation-name: pulseBox;
        animation-name: pulseBox;
        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -o-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-direction: alternate;
        -moz-animation-direction: alternate;
        -o-animation-direction: alternate;
        animation-direction: alternate;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        border: 0px;
      }
    }
    /* prevents submit from shrinking while pulseBox is active */
    /* .submit-contact-form-btn:active:not(.pulseBox) {
      span {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
      }
    } */
    /* .pulseBox:hover {
      animation: none;
    } */
    .submit-contact-form-btn:active,
    .pulseBox:active {
      box-shadow: inset 1px 1px 1px rgba(150, 150, 150, 0.5),
        inset -1px -1px 1px rgba(0, 0, 0, 0.5), 1px 1px 1px rgba(0, 0, 0, 0.7);
    }
    .submit-contact-form-btn.pulseBox {
      color: var(--deepBlue);
      transition: color 0.3s var(--colorEase);
    }
  }

  @media screen and (max-width: 599px) {
    .contact-form {
      flex-direction: column;
      align-items: center;
      .close-form-button {
        top: 2%;
        right: 4.5%;
      }
      .form-section {
        width: min(70%, 280px);
      }
    }
  }
`;

export const validationSchema = () => {
  const phoneRegEx =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  return Yup.object({
    firstName: Yup.string()
      .max(25, 'Must be 25 characters or less')
      .required('Required'),
    lastName: Yup.string()
      .max(35, 'Must be 35 characters or less')
      .required('Required'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Required'),
    phoneNumber: Yup.string()
      .matches(phoneRegEx, 'Please enter a valid phone number')
      .notRequired('Optional'),
    subject: Yup.string()
      .oneOf(
        [
          'Im interested in a free property evaluation',
          "I'd like to explore investment opportunities",
          'I have a general real estate question',
          'Other'
        ],
        'Required'
      )
      .required('Required'),
    message: Yup.string()
      .min(4, 'Message must be 4 characters or more')
      .max(100, 'Message must be less than 100 characters')
      .required('Required')
  });
};

export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  subject: '',
  message: ''
};

const handleFocus = e => {
  const inputRef = e.target;
  if (!inputRef.value.trim()) {
    inputRef.classList.remove('has-content');
  } else {
    inputRef.classList.add('has-content');
  }
};

export const TextInput = ({
  label,
  handleBlur,
  handleChange,
  isTextArea,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <>
      <div
        className="input-box input-effect"
        style={{ display: isTextArea ? 'inherit' : '' }}
      >
        {isTextArea ? (
          <textarea
            className="effect-20"
            {...field}
            {...props}
            onBlur={e => {
              handleBlur(e);
              handleFocus(e);
            }}
          />
        ) : (
          <input
            className="effect-20"
            {...field}
            {...props}
            onChange={e => {
              handleChange(e);
              handleFocus(e);
            }}
            onBlur={e => {
              handleBlur(e);
              handleFocus(e);
            }}
          />
        )}

        <label
          // style={{ lineHeight: isTextArea ? '20px' : null }}
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
        <span className="focus-border">
          <i></i>
        </span>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export const DropdownSelect = ({ label, handleBlur, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="select-box input-effect">
        <select
          {...field}
          {...props}
          onBlur={e => {
            handleBlur(e);
            handleFocus(e);
          }}
          className="effect-20"
        >
          {props.children}
        </select>
        <label htmlFor={props.id || props.name}>{label}</label>
        <span className="focus-border">
          <i></i>
        </span>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

const ContactForm = ({ onClose }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  // handle spinner state
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  // handle success or error messages after submission
  const [isSubmitted, setSubmitted] = useState({
    isSubmitted: false,
    type: ''
  });

  const submitData = useCallback(async (values, _, recaptchaToken) => {
    // setSubmitting(true);
    const {
      botField,
      firstName,
      lastName,
      email,
      phoneNumber,
      subject,
      message
    } = values;
    try {
      await axios.post('/api/submit-inquiry', {
        botField,
        firstName,
        lastName,
        email,
        phoneNumber,
        subject,
        message,
        recaptchaToken
      });
      // no longer necessary
      // onClose();
      // setSubmitting(false);
      setFormSubmitting(false);
      setSubmitted({ isSubmitted: true, type: 'success' });
    } catch (error) {
      if (error.response) {
        console.log(
          'Server responded with non 2xx code: ',
          error.response.data
        );
      } else if (error.request) {
        console.log('No response received: ', error.request);
      } else {
        console.log('Error setting up response: ', error.message);
      }
      setFormSubmitting(false);
      setSubmitted({ isSubmitted: true, type: 'failure' });
    }
  }, []);

  // Create an event handler so you call the verification on button click event or form submit
  const handleRecaptchaVerify = useCallback(
    async (values, actions) => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha no yet available');
        return;
      }
      setFormSubmitting(true);
      const token = await executeRecaptcha('submit');
      submitData(values, actions, token);
    },
    [executeRecaptcha, submitData]
  );

  // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  //   handleRecaptchaVerify();
  // }, [handleRecaptchaVerify])

  return (
    <>
      {isFormSubmitting ? (
        <StyledSpinnerContainer>
          <Spinner />
        </StyledSpinnerContainer>
      ) : isSubmitted.isSubmitted ? (
        <SubmissionMessage type={isSubmitted.type} onClose={onClose} />
      ) : (
        <StyledContactForm>
          <div className="intro">
            <h6>Let's get connected.</h6>
          </div>
          <div className="heading">
            <h2>
              Curious to know how much your property is worth? <br /> Allow us
              to make an introduction.
            </h2>
          </div>
          <FormContainer>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleRecaptchaVerify}
              validateOnMount
            >
              {({
                isSubmitting,
                isValid,
                handleBlur,
                handleChange,
                resetForm
              }) => (
                <Form className="contact-form">
                  <div className="close-form-button">
                    <button
                      type="button"
                      onClick={e => {
                        e.stopPropagation();
                        resetForm();
                        onClose();
                      }}
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="form-section">
                    <Field
                      as="input"
                      name="botField"
                      label="Bot Field"
                      placeholder="Bot Field"
                      type="hidden"
                      aria-hidden="true"
                    />
                    <div className="input-container">
                      <TextInput
                        name="firstName"
                        label="First Name"
                        type="text"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="input-container">
                      <TextInput
                        name="lastName"
                        label="Last Name"
                        type="text"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="input-container">
                      <TextInput
                        name="email"
                        label="Email Address"
                        type="email"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="input-container">
                      <TextInput
                        name="phoneNumber"
                        label="Phone Number"
                        type="text"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="input-container">
                      <DropdownSelect
                        label="How can we help?"
                        name="subject"
                        handleBlur={handleBlur}
                      >
                        <option
                          value=""
                          disabled
                          aria-label="select an option"
                        ></option>
                        <option value="Im interested in a free property evaluation">
                          I'm interested in a free property evaluation
                        </option>
                        <option value="I'd like to explore investment opportunities">
                          I'd like to explore investment opportunities
                        </option>
                        <option value="I have a general real estate question">
                          I have a general real estate question
                        </option>
                        <option value="Other">Other</option>
                      </DropdownSelect>
                    </div>
                    <div className="input-container">
                      <TextInput
                        name="message"
                        label="Message"
                        isTextArea={true}
                        rows="4"
                        type="textarea"
                        handleBlur={handleBlur}
                      />
                    </div>
                    <div>
                      <button
                        className={[
                          'submit-contact-form-btn',
                          isValid && 'pulseBox'
                        ].join(' ')}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </FormContainer>
          <div className="recaptcha-branding">
            <p>
              This site is protected by reCAPTCHA and the Google{' '}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>{' '}
              and{' '}
              <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
              apply.
            </p>
          </div>
        </StyledContactForm>
      )}
    </>
  );
};

export default ContactForm;
