import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import hoverEffect from 'hover-effect';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

const StyledCommercial = styled.div`
  /* padding: 10vw 6vw;
  padding: clamp(5px, 10vw, 10rem) 0; */
  --commImageShadowOpacity: 0;
  background-color: #f9f7f7;
  position: relative;
  height: 100vh;
  overflow: hidden;
  .service-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    /* padding: 2rem 0; */

    /* animation for subheading slide in effect */
    .animation-box {
      overflow: hidden;
      span {
        position: relative;
        /* display block is necessary for skew transforms */
        display: block;
        left: 0.8rem;
      }
    }
    .col-1 {
      width: 42%;
      margin-left: auto;
    }
    .col-1-heading {
      line-height: 130%;
      margin-block: clamp(3rem, 745px - 100vw, 6.2rem) 1.2rem;
      position: relative;
      #acquisition {
        /* font-size: 26px; */
        /* 23-26 */
        font-size: clamp(1.44rem, calc(1.05rem + 0.81vw), 1.63rem);
      }
      .vertical-line {
        position: absolute;
        height: 100%;
        width: 3.5px;
        background-color: var(--secondary);
        left: 0;
        top: 0;
        z-index: 4;
      }
    }

    .comm-col-1-description {
      p {
        margin: 0;
      }
    }
    /* .commercial-location.bg-change {
      color: var(--ghostWhite);
    } */

    .col-3 {
      width: 53%;
      opacity: 0;
      display: inline-block;
      .col-3-content {
        display: flex;
        gap: min(3vmin, 2rem);
      }
      .col-3-heading {
        line-height: 1.5;
        padding-left: min(2.5rem, 2.5vw);
        font-family: Poppins, sans-serif;
        /* 22-26 min 768 */
        font-size: clamp(1.38rem, calc(0.86rem + 1.08vw), 1.63rem);
        width: 55%;
        span {
          font-weight: 800;
          letter-spacing: 0.3px;
          color: var(--lightBlue);
        }
      }
      .col-3-description {
        width: 85%;
        padding-right: min(4rem, 4vw);
        p {
          margin: 0;
        }
        .col-3-description-top {
          transition: color 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
          margin-bottom: 1rem;
          color: rgb(46, 46, 46);
          /* 15-18px; */
          font-size: clamp(0.94rem, calc(0.55rem + 0.81vw), 1.13rem);
        }
        .col-3-description-bottom {
          transition: color 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
          font-size: 14px;
        }
      }
    }
  }

  .image-wrapper {
    /* position is absolute to where the wrapper content begins */
    position: absolute;
    left: 50%;
    will-change: transform;
    display: inline-block;
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: var(--commImageShadowOpacity);
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
    /* moved image up and shrunk/decreased content from bottom 
       this will overflow top and that is why there is extra space above the image*/
    /* top: 0; */
    /* width: 70%; */
    .mask {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #f9f7f7;
      /* background: #fffcfa; */
      top: 0;
      left: 0;
      z-index: 5;
      transform-origin: left;
    }
    .commercial-heading {
      position: absolute;
      z-index: 4;
      left: clamp(-243px, -22vw, -130px);
      top: calc(2vw - 15px);
      right: 0;
      mix-blend-mode: hard-light;
      width: fit-content;
      /* **designate a starting position so gsap knows how to animate when we scroll back up** */
      /* transform: translate(0px, 0px); */
      /* will-change: transform; */
      h1 {
        /* 45-72 */
        font-size: clamp(2.81rem, calc(1.83rem + 3.75vw), 4.5rem);
        margin: 0;
        font-weight: 600;
        color: var(--black);
      }
    }
    .commercial-subheading {
      position: absolute;
      z-index: 2;
      left: -2px;
      top: 0;
      opacity: 0;
      will-change: transform;
      /* mix-blend-mode: difference; */
      h3 {
        /* font-size: 2.7rem; */
        /* 420 min viewport */
        /* 30-43 */
        font-size: clamp(1.88rem, calc(1.4rem + 1.81vw), 2.69rem);
        letter-spacing: 0.3px;
        line-height: 108%;
      }
    }
    .commercial-location {
      position: absolute;
      right: 0px;
      top: calc(100% - 73px);
      transform: translate3d(57%, 0, 0) rotate(90deg);
      color: var(--darkBlack);
      font-family: Calluna, sans-serif;
      text-transform: capitalize;
      letter-spacing: 1px;
      font-size: 1rem;
      text-rendering: optimizeLegibility;
      span {
        color: var(--buttonBlue);
      }
    }
    .image-container {
      /* height: 60vh; */
      width: 100%;
      position: relative;
      overflow: hidden;
      z-index: 1;
      .commercial-image {
        width: clamp(540px, 65vw, 720px);
        height: clamp(360px, 70vh, 480px);
        filter: brightness(0.95);
        canvas {
          max-height: 480px !important;
        }
      }
    }
  }

  @media screen and (min-width: 600px) and (max-width: 940px) {
    .col-3 {
      .col-3-content {
        .col-3-heading {
          margin-right: 12px;
          padding-left: 1.1vw;
          /* margin-right: 14px; */
          line-height: 1.4;
          width: 45%;
        }
        .col-3-description {
          padding-right: min(1.75rem, 3vw);
          p {
            line-height: 25px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 600px) and (max-width: 760px),
    screen and (max-width: 449px),
    screen and (min-width: 600px) and (max-width: 882px) and (max-height: 400px) {
    .col-3 {
      .col-3-content {
        display: block !important;
        .col-3-heading {
          float: left;
          margin-right: 1rem;
          padding-left: 0;
          line-height: 1.5;
          text-align: justify;
        }
        .col-3-description {
          padding-right: 0;
          width: 92%;
        }
      }
    }
  }

  /* mobile UI */
  @media screen and (max-width: 599px) {
    .col-1,
    .col-3 {
      width: 80% !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .col-1 {
      /* padding-top: min(17%, 4.5rem); */
      padding-top: min(15%, 4.3rem);
      .col-1-heading {
        margin-block: auto;
        margin-bottom: 1.2rem;
      }
    }
    .col-3 {
      .col-3-heading {
        padding-left: 0 !important;
      }
      .col-3-description {
        padding-right: 0 !important;
        width: 100% !important;
      }
    }
    .image-wrapper {
      left: 100%;
      .mask {
        visibility: hidden;
      }
      .commercial-heading {
        /* left: clamp(-400px, 210px - 124vw, -265px); */
        left: clamp(-375px, 210px - 132vw, -265px);
        /* top: calc(3vw - 9px) !important; */
        top: calc(2.5vw - 8px) !important;
      }
      .commercial-subheading {
        left: -56%;
        h3 {
          line-height: 117% !important;
        }
      }
      .image-container {
        .commercial-image {
          /* could do 70vmax would be long on iphone*/
          width: clamp(332px, 90vw, 540px);
          height: clamp(202px, 75vh, 360px);
        }
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 599px) {
    .col-3-description {
      width: 93% !important;
      padding-left: 0.2rem;
    }
  }

  @media screen and (max-height: 450px), screen and (max-width: 410px) {
    .col-3-heading {
      /* 19-22 280-410vw*/
      font-size: clamp(1.19rem, calc(0.78rem + 2.31vmin), 1.38rem) !important;
    }
    .col-3-description {
      width: 100% !important;
      .col-3-description-top {
        /* 13.5-15 */
        font-size: clamp(0.84rem, calc(0.64rem + 1.15vmin), 0.94rem) !important;
        line-height: 1.67 !important;
        /* margin-bottom: 0.5em !important; */
      }
      .col-3-description-bottom {
        /* 12-14 */
        font-size: clamp(0.75rem, calc(0.48rem + 1.54vw), 0.88rem) !important;
        line-height: 1.7 !important;
      }
    }
    #acquisition {
      /* 19px 23  set to vmin instead of vw so that it works in landscape orientation */
      font-size: clamp(1.19rem, calc(0.65rem + 3.08vmin), 1.44rem) !important;
      line-height: 1.4 !important;
    }
    .col-1 {
      .col-1-heading {
        margin-bottom: 1rem !important;
      }
    }
    .comm-col-1-description {
      p {
        /* 12px-15 280-410 */
        font-size: clamp(0.75rem, calc(0.35rem + 2.31vmin), 0.94rem);
        line-height: 1.6 !important;
        /* font-size: 51%; */
      }
    }
    .commercial-heading {
      h1 {
        /* 37px-44.96 min */
        font-size: clamp(2.31rem, calc(0.92rem + 7.96vmin), 2.81rem) !important;
      }
    }
    .commercial-subheading {
      h3 {
        /* 26px-30 280-380vw */
        font-size: clamp(1.63rem, calc(0.93rem + 4vmin), 1.88rem) !important;
      }
    }
  }

  @media screen and (max-width: 300px) {
    .commercial-heading {
      left: clamp(-265px, 210px - 124vw, -230px) !important;
    }
  }

  /* landscape moobile device  i.e. 575px */
  /* font size in this section is handled by previous sectioon vmin 280-410 viewport units */
  @media only screen and (max-height: 450px) and (min-width: 600px) and (orientation: landscape) {
    .col-1 {
      .col-1-heading {
        margin-block: clamp(3rem, 516px - 100vh, 4.2rem) 0.8rem;
      }
    }
    .col-3 {
      .col-3-heading {
        @media screen and (min-width: 600px) and (max-width: 741px) {
          display: none;
        }
      }
      .col-3-description {
        width: 92% !important;
        .col-3-description-top {
          @media screen and (min-width: 600px) and (max-width: 741px) and (min-height: 300px) {
            margin-bottom: 1rem !important;
          }
        }
      }
    }
    .image-wrapper {
      .commercial-heading {
        left: clamp(-220px, -16vw, -145px);
      }
      .image-container {
        .commercial-image {
          width: clamp(410px, 64vw, 475px);
          height: clamp(274px, 87vh, 317px);
        }
      }
    }
  }
  /* handles galaxy fold landscape at 280px height  */
  @media only screen and (max-height: 359px) and (min-width: 600px) and (orientation: landscape) {
    .image-wrapper {
      .image-container {
        .commercial-image {
          height: clamp(211px, 79vh, 374px);
          width: clamp(317px, 61vw, 450px);
        }
      }
    }
    .col-3 {
      .col-3-description-top {
        margin-bottom: 0.5rem !important;
      }
    }
  }
  @media only screen and (max-height: 359px) and (min-width: 600px) and (orientation: landscape) {
    .image-wrapper {
      .image-container {
        .commercial-image {
          height: clamp(211px, 79vh, 374px);
          width: clamp(317px, 61vw, 450px);
        }
      }
    }
    .col-3 {
      .col-3-description-top {
        margin-bottom: 0.5rem !important;
      }
    }
  }
  /* small 1 : 1 screens */
  @media screen and (max-width: 550px) and (max-height: 399px),
    screen and (max-height: 450px) and (max-width: 350px) {
    .col-3 {
      .col-3-heading {
        display: none;
      }
    }
  }

  @media screen and (max-width: 340px) and (max-height: 340px) {
    .col-1-heading {
      display: none;
    }
  }
`;

const query = graphql`
  {
    image: contentfulUiAssets {
      commercial {
        # gatsbyImageData
        gatsbyImageData(quality: 85)
        title
      }
    }
  }
`;

const Commercial = () => {
  let outerLayer = useRef(null);
  let column1 = useRef(null);
  let column3 = useRef(null);
  let commercialTitle = useRef(null);
  let property = useRef(null);
  let verticalLine = useRef(null);
  // let subheading = useRef(null);
  let heading = useRef(null);
  let description = useRef(null);
  let mask = useRef(null);
  let imageWrapper = useRef(null);
  let distortionImage = useRef(null);

  const {
    image: { commercial }
  } = useStaticQuery(query);
  const emptySpace = getSrc(commercial[0]);
  const storeFront = getSrc(commercial[1]);
  const distortion = getSrc(commercial[2]);

  useEffect(() => {
    distortionImage.current = new hoverEffect({
      parent: distortionImage,
      intensity: 0.2,
      image1: emptySpace,
      image2: storeFront,
      hover: false,
      displacementImage: distortion,
      imagesRatio: 360 / 480
    });
  }, [distortion, emptySpace, storeFront]);

  useEffect(() => {
    if (typeof window !== undefined) {
      gsap.registerPlugin(ScrollTrigger);

      ScrollTrigger.saveStyles(
        `.comm-col-1-description,
         .commercial-heading,
          .commercial-subheading,
           .commercial-image,
            #acquisition`
      );

      ScrollTrigger.matchMedia({
        // all applies to any screen size --> readability, persists no matter what the size of the viewport is
        // '(all)': function() {},
        '(min-width: 600px)': function () {
          // reveal image sliding to right while scaling down
          const contentReveal = gsap.timeline({
            scrollTrigger: {
              trigger: imageWrapper.current,
              start: 'top-=8% bottom',
              once: true,
              fastScrollEnd: true,
              toggleActions: 'play complete none none'
            }
          });
          // reveal image
          contentReveal
            .to(mask, {
              duration: 1.4,
              ease: 'expo.inOut',
              // reveal from right to left with transform for better performance
              // width: '0%'
              scaleX: 0
            })
            .from(
              '.commercial-image',
              {
                scale: 1.4,
                duration: 1.4,
                ease: 'power2.inOut'
                // autoAlpha: 0
              },
              '<.1'
            )
            // slide in and reveal text
            .from(
              commercialTitle,
              {
                // slide in from left
                autoAlpha: 0, // autoAlpha sets opacity to zero and visibility to hidden
                x: -320,
                ease: 'power3.inOut',
                duration: 1.3
              },
              '<.5'
            )
            .from(
              verticalLine,
              {
                scaleY: 0,
                duration: 0.6,
                ease: 'expo.inOut'
              },
              // overlap first .1 second of previous
              '<'
            )
            .from(
              // slide in from left
              heading,
              {
                // skewY: 7,
                x: -285,
                ease: 'expo.out',
                duration: 0.8
              },
              // before the end of the previous tween
              '>-.1'
            )
            .to(
              property.current,
              {
                // slide up
                opacity: 1,
                yPercent: -80,
                duration: 0.45,
                keyframes: {
                  '40%': { opacity: 0 },
                  '100%': { opacity: 1 }
                },
                ease: 'circ.out'
              },
              //overlap last .23 seconds of previous, start .23 before previous ends
              '>-.23'
            )
            .from(
              description,
              {
                autoAlpha: 0,
                duration: 0.6,
                ease: 'circ.out'
              },
              // after the start
              '<'
            )
            .to(
              imageWrapper.current,
              {
                '--commImageShadowOpacity': 1,
                duration: 0.6,
                ease: 'var(--customEase)'
              },
              '<'
            );

          const timeline1 = gsap.timeline({
            // prevents prev tl from overlapping with current if scrolled too fast or page is refreshed/loaded mid timeline1
            // causing the title x position to be out of sync
            // moved to after opacity of col1 fades/before image starts to move in timeline

            // onStart: () => {
            //   // if timeline is in progress when we reach this section, automatically force it to stop
            //   if (contentReveal.isActive()) {
            //     contentReveal.totalProgress(1);
            //   }
            // },

            scrollTrigger: {
              trigger: outerLayer.current,
              start: 'center center',
              end: () => `=+${outerLayer.current.offsetHeight}`,
              // animation will progress as you scroll instead all at once. 0.5 seconds for animation to catch up to scroll
              scrub: 0.5,
              // similar to position: fixed
              pin: true,
              // resets any saved values on scrolltrigger during screen resize and ST.refresh
              invalidateOnRefresh: true
            }
          });
          timeline1
            .to(column1, {
              // fade out
              autoAlpha: 0,
              duration: 0.3
            })
            .to(
              // move image to the left
              imageWrapper.current,
              {
                // length of image in pixels instead of percentage
                x: () => Math.ceil(imageWrapper.current.offsetWidth + 70) * -1,
                duration: 1.4,
                onStart: () => {
                  // if timeline is in progress when we reach this section, automatically force it to stop
                  if (contentReveal.isActive()) {
                    contentReveal.totalProgress(1);
                  }
                }
              },
              '-=.04' //start .08 second before last tween ends
            )
            .fromTo(
              // if we do not slide right, then slide left so that it cannot be seen
              commercialTitle,
              {
                // ***designate a starting position to prevent x from scrolling too far left if page is refreshed during pin****
                x: 0
              },
              {
                x: -140,
                // duration: 0.8,
                duration: 0.6
              },
              '>-1' // start 1 seconds before the end of the previous tween
            )
            .to(
              property.current,
              // slide to right
              {
                // using pixels to animate for better performance and avoid layout & paint triggers
                // use function to calc for dynamic widths
                x: () =>
                  Math.ceil(imageWrapper.current.offsetWidth) -
                  Math.ceil(property.current.offsetWidth) +
                  4,
                duration: 0.8
              },
              '<.1' // start .1 second after the previous tween starts
            )
            .call(
              (tl, dissImage) => {
                // calling a function to trigger image transition distortion effect
                // check scrolltrigger to see which direction we are scrolling
                const direction = tl.scrollTrigger.direction;
                if (direction > 0) {
                  dissImage.current.next();
                } else {
                  dissImage.current.previous();
                }
              },
              [timeline1, distortionImage],
              '>-1.13'
            )
            .to(
              column3,
              // make col-3 visible
              {
                autoAlpha: 1,
                duration: 0.2
              },
              '-=.3' // fire .3 seconds before prevous tween ends
            );
        },
        '(max-width: 599px)': function () {
          gsap.delayedCall(2.2, () => {
            gsap.set(imageWrapper.current, {
              // make visible when page loads since we are not animating
              '--commImageShadowOpacity': 1
            });
          });

          const contentReveal = gsap.timeline({
            scrollTrigger: {
              trigger: imageWrapper.current,
              start: 'top-=15% bottom',
              once: true,
              fastScrollEnd: true,
              // markers: true,
              toggleActions: 'play complete none none'
            }
          });
          // reveal image
          contentReveal
            // slide in and reveal text
            .from(
              verticalLine,
              {
                scaleY: 0,
                duration: 0.8,
                ease: 'expo.inOut'
              },
              // overlap first .1 second of previous
              '<'
            )
            .from(
              commercialTitle,
              {
                // slide in from left
                autoAlpha: 0, // autoAlpha sets opacity to zero and visibility to hidden
                x: -320,
                ease: 'power3.inOut',
                duration: 1.3
              },
              '<.25'
            )
            .from(
              // slide in from left
              heading,
              {
                // skewY: 7,
                x: -250,
                ease: 'power3.inOut',
                duration: 1.3
              },
              // before the end of the previous tween
              '<'
            )
            .to(
              property.current,
              {
                // slide up
                opacity: 1,
                keyframes: {
                  '40%': { opacity: 0 },
                  '100%': { opacity: 1 }
                },
                yPercent: -78,
                duration: 0.45,
                ease: 'circ.out'
              },
              //overlap last .23 seconds of previous, start .23 before previous ends
              '>-.2'
            )
            .from(
              description,
              {
                autoAlpha: 0,
                duration: 0.5,
                ease: 'circ.out'
              },
              // after the start
              '<.1'
            );

          const translatePhoto = gsap.timeline({
            // prevents prev tl from overlapping with current if scrolled too fast or page is refreshed/loaded mid timeline1
            // causing the title x position to be out of sync
            onStart: () => {
              // if timeline is in progress when we reach this section, automatically force it to stop
              if (contentReveal.isActive()) {
                contentReveal.totalProgress(1);
              }
            },
            scrollTrigger: {
              trigger: outerLayer.current,
              // markers: true,
              start: 'center center',
              // end animation 100% from where the start is; slows scrolling
              // end: '100%',
              end: () => `=+${outerLayer.current.offsetHeight}`,
              // end: () => '+=200%', can also fire a function for dynamic values
              // animation will progress as you scroll instead all at once. 0.5 seconds for animation to catch up to scroll
              scrub: 0.5,
              // similar to position: fixed
              pin: true,
              // resets any saved values on scrolltrigger during screen resize and ST.refresh
              invalidateOnRefresh: true
            }
          });
          translatePhoto
            .to(column1, {
              // fade out
              autoAlpha: 0,
              duration: 0.17
            })
            .fromTo(
              // if we do not slide right, then slide left so that it cannot be seen
              commercialTitle,
              {
                // ***designate a starting position to prevent x from scrolling too far left if page is refreshed during pin****
                x: 0
              },
              {
                x: () => Math.floor(imageWrapper.current.offsetWidth / 1.8),
                duration: 0.8,
                ease: 'power1.in'
              },
              '<-.1' // start .1 seconds before the end of the previous tween
            )
            .to(
              property.current,
              // slide to right
              {
                // since left is -67% of image, we slide back in pixels with offset -3
                x: () => Math.floor(imageWrapper.current.offsetWidth * 0.56),
                duration: 0.8,
                ease: 'power1.in'
              },
              '<.1' // start .1 second after the previous tween starts
            )
            .to(
              // move image to the left
              imageWrapper.current,
              {
                // length of image in pixels instead of percentage
                x: () => Math.ceil(imageWrapper.current.offsetWidth * -2.16),
                duration: 1.5
              },
              '<.59' //start .08 second before last tween ends
            )
            .call(
              (tl, dissImage) => {
                // calling a function to trigger image transition distortion effect
                // check scrolltrigger to see which direction we are scrolling
                const direction = tl.scrollTrigger.direction;
                if (direction > 0) {
                  dissImage.current.next();
                } else {
                  dissImage.current.previous();
                }
              },
              [translatePhoto, distortionImage],
              '<.41'
            )
            .to(
              column3,
              // make col-3 visible
              {
                autoAlpha: 1,
                duration: 0.15,
                ease: 'circ.inOut'
              },
              // '-=.75' // fire .75 seconds before entire tl ends
              '>.72'
            );
        }
      });
    }
  }, []);

  return (
    <StyledCommercial ref={outerLayer} id="commercialSection">
      <div className="service-wrapper">
        <div className="col-1" id="commercial-col-1" ref={el => (column1 = el)}>
          <div className="col-1-heading">
            <div
              className="vertical-line"
              ref={el => (verticalLine = el)}
            ></div>
            <h3>
              <div className="animation-box">
                <span ref={el => (heading = el)} id="acquisition">
                  Acquisition, Development, <br />
                  Re-development.
                </span>
              </div>
            </h3>
          </div>
          <article
            className="comm-col-1-description"
            ref={el => (description = el)}
          >
            <p>
              Our Commercial Asset Division focuses on seeking out value-add
              opportunities across all commercial sectors. We operate with the
              same efficiency in the Commercial space seeking to maximize the
              cash-flow on each asset through value-add development.
            </p>
          </article>
        </div>
        <div
          className="row-1-col-2 order-1 order-lg-2 image-wrapper"
          ref={imageWrapper}
        >
          <div
            className="commercial-heading"
            ref={el => (commercialTitle = el)}
          >
            <h1>Commercial</h1>
          </div>
          <div className="commercial-subheading" ref={property}>
            <h3 className="text-gradient-2">Properties</h3>
          </div>
          <div className="commercial-location">
            Astoria / <span>Queens</span>
          </div>
          <div className="image-container">
            <div className="mask" ref={el => (mask = el)}></div>
            <div
              className="commercial-image"
              ref={el => (distortionImage = el)}
            ></div>
          </div>
        </div>
        <div
          className="col-3"
          ref={el => (column3 = el)}
          id="commercialProperties"
        >
          <div className="col-3-content">
            <div className="col-3-heading">
              Benefit from our experience in <span>investment</span> and real
              estate <span>financing</span>.
            </div>
            <div className="col-3-description">
              <p className="col-3-description-top">
                Our clients receive expert guidance from an exclusive advisor
                along with our entire team. We provide the total package, from
                the initial modeling to the signing of the financing.
              </p>
              <p className="col-3-description-bottom">
                Our strategic partnerships with some of the top commercial
                lenders allow us to secure favorable financing eact commercial
                asset as we move them to our long term portfolio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledCommercial>
  );
};

export default Commercial;
