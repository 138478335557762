import React from 'react';
import Commercial from './Commercial';
import Investment from './Investment';
import Residential from './Residential';

const Services = () => (
  <div
    className="services-section"
    style={{
      // important for mix-blend-mode functionality
      // background: 'var(--ghostWhite)',
      backgroundColor: 'var(--white)',
      // transition:
      //   'background-color 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95)',
      overflowX: 'hidden'
    }}
  >
    <Residential />
    <Commercial />
    <Investment />
  </div>
);

export default Services;
